import getSlider from './getSlider';

export default async function transformMedia(mediaObj, $axios) {
    const supportedTypes = [
        'image',
        '360',
        'video',
        'before_after',
        'document',
    ];
    try {
        const {
            id,
            title,
            description = undefined,
            // eslint-disable-next-line camelcase
            updated_at,
            // eslint-disable-next-line camelcase
            file_id,
            files,
            url,
            metadata,
        } = mediaObj;
        const type =
            metadata.has_360 || mediaObj.is_360 ? '360' : mediaObj.type;
        // eslint-disable-next-line camelcase
        const file = (files?.data ?? []).find((file) => file.uuid === file_id);
        // eslint-disable-next-line camelcase
        const size_human = file?.size_human;
        const width = file?.metadata?.width;
        const height = file?.metadata?.height;
        const data = {
            id,
            type,
            title,
            ref: url?.slice(mediaObj.url.length - 6),
            // url,
            description: description ?? undefined,
            // eslint-disable-next-line camelcase
            updated_at,
            // eslint-disable-next-line camelcase
            size_human,
            width,
            height,
            public: mediaObj.public,
        };
        if (!supportedTypes.includes(type)) {
            // skip unsupported media types
            console.error(`Skipping unsupported media type: ${type}`);
            return null;
        }
        if (['image', '360', 'document'].includes(type)) {
            data.url = url;
        } else if (type === 'video') {
            data.url = file?.metadata?.vimeo?.vimeo_id;
        } else if (type === 'before_after') {
            if (!metadata.before_after.before?.media_id) {
                console.error(`before_after missing before`);
                return null;
            }
            if (!metadata.before_after.after?.media_id) {
                console.error(`before_after missing after`);
                return null;
            }
            data.before = {
                label: metadata.before_after.before.label,
                url: await getSlider(
                    metadata.before_after.before.media_id,
                    $axios,
                ),
            };
            data.after = {
                label: metadata.before_after.after.label,
                url: await getSlider(
                    metadata.before_after.after.media_id,
                    $axios,
                ),
            };
        }
        return data;
    } catch (e) {
        console.error(e);
    }
}
