<template>
    <div class="map-component">
        <MapInteract
            v-if="activeMap"
            :component-data="componentData"
            :active-map="activeMap"
            :map-i-d="componentData.props.map_id"
        />
    </div>
</template>

<script>
// import MapDisplay from './map/MapDisplay.vue';
import MapInteract from './map/MapInteract.vue';

export default {
    name: 'MapComponent',
    components: {
        MapInteract,
        // MapDisplay,
    },
    props: {
        componentData: Object,
    },
    data() {
        return {
            activeMap: null,
        };
    },
    async fetch() {
        if (this.componentData.props.map_id) {
            await this.getMap(this.componentData.props.map_id);
        }
    },
    watch: {
        mapID(mapID, prev) {
            if (!mapID) {
                this.activeMap = null;
            } else {
                this.getMap(this.componentData.props.map_id);
            }
        },
    },
    methods: {
        async getMap(mapID) {
            const vm = this;

            await vm.$store.dispatch('api/getContent', mapID).then((res) => {
                vm.activeMap = res.metadata;
            });
        },
    },
    fetchOnServer: true,
};
</script>

<style lang="scss">
div.map-component {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: row;
}
</style>
