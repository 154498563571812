import { isObject } from 'lodash-es';

/**
 * Sort object by keys
 * @param {Object} object
 * @returns {Object}
 */
export default function sortObject(object) {
    if (isObject(object) && !Array.isArray(object)) {
        return Object.keys(object)
            .sort()
            .reduce(function (result, key) {
                result[key] = object[key];
                return result;
            }, {});
    }
    return object;
}
