<template>
    <div class="hero-container">
        <div
            :id="elementID"
            class="jumbotron jumbotron-fluid ca-hero"
            :class="classList"
            :style="style"
            @click="doClickAction"
        >
            <MediaLoader
                v-if="
                    attrs.bg_type === 'media' &&
                    attrs.bg_media &&
                    attrs.bg_media.type === 'video'
                "
                :media="attrs.bg_media"
                :controls="false"
                :autoplay="true"
                :spinner="false"
                class="video-bg"
                v-bind="{ videoDisplay: attrs.video_display }"
            />

            <div
                class="container py-1"
                :class="'align-items-' + attrs.justify_content"
            >
                <draggable
                    v-if="isPreview && !previewMode"
                    v-model="
                        // eslint-disable-next-line vue/no-mutating-props
                        componentData.children
                    "
                    class="draggable"
                    group="hero"
                    handle=".drag-handle"
                    @change="refreshPreviewOrder(componentData.uuid)"
                >
                    <slot></slot>
                </draggable>

                <slot v-else />
            </div>
        </div>

        <Adder
            :component-data="componentData.parentData"
            :child-u-u-i-d="componentData.uuid"
        />
    </div>
</template>

<script>
import helpers from '../mixins/helpers';
import MediaLoader from './MediaLoader';
import Adder from './editor/Adder';
export default {
    name: 'Hero',
    components: {
        Adder,
        MediaLoader,
    },
    mixins: [helpers],
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
        };
    },
    computed: {
        classList() {
            const contentPosition = `content-${this.attrs.content_pos}`;
            const overlay = this.attrs.overlay ? 'overlay-dark' : '';

            return [
                ...this.helperClasses,
                contentPosition,
                overlay,
                this.attrs.element_class || '',
            ];
        },
        style() {
            const styleObject = {
                minHeight:
                    this.attrs.height === 'auto' ? null : this.attrs.height,
                height: this.attrs.height === 'auto' ? 'auto' : null,
            };

            if (this.attrs.bg_type === 'media' && this.attrs.bg_media) {
                if (this.attrs.bg_media.type === 'image') {
                    styleObject.backgroundImage = `url(${this.attrs.bg_media.url}`;
                    styleObject.backgroundPosition =
                        this.attrs.bg_pos || 'center';
                    styleObject.backgroundSize = this.attrs.bg_size;
                }
            } else if (this.attrs.bg_type === 'color') {
                styleObject.backgroundColor = this.getColor(
                    this.attrs.bg_color,
                );
            }

            return styleObject;
        },
        elementID() {
            return this.attrs.element_id || '';
        },
    },
};
</script>

<style lang="scss">
.hero-container {
    display: flex;
    flex-direction: column;
}
.jumbotron {
    position: relative;
    display: flex;
    margin-bottom: unset;

    .video-bg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }

    & > div.container {
        display: flex;
        flex-direction: column;
        position: relative;
    }

    &.jumbotron-lg {
        height: 100vh;
    }

    &.jumbotron-sm {
        min-height: 300px;
    }

    &.jumbotron-md {
        min-height: 400px;
    }

    &.content-bottom {
        align-items: flex-end;
    }

    &.content-center {
        align-items: center;
    }

    &.content-top {
        align-items: flex-start;
    }

    &.overlay-dark {
        &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 60%;
            background: linear-gradient(
                0deg,
                rgba(3, 21, 27, 0.6) 0%,
                rgba(3, 21, 27, 0) 100%
            );
            z-index: 1;
        }
    }

    .container {
        z-index: 2;
    }

    @media screen and (max-width: 575px) {
        .container {
            padding-left: 2rem;
            padding-right: 2rem;
        }
    }
}
</style>
