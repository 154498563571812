<template>
    <MessageCard
        heading="Page Not Found"
        subheading="404"
        description="The specified file was not found on this website. Please check the URL for mistakes and try again."
    >
        <!-- <b-row no-gutters>
            <b-col>
                <b-btn to="/" exact large block color="primary">{{
                    'Home'
                }}</b-btn>
            </b-col>
        </b-row> -->
    </MessageCard>
</template>

<script>
import MessageCard from '~/components/MessageCard.vue';
export default {
    components: {
        MessageCard,
    },
    props: {
        error: {
            type: Object,
            default: () => {},
        },
    },
};
</script>
