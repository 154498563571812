<template>
    <div :id="elementID" class="sidebars-container" :class="classList">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'PageSidebars',
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData ? this.componentData.props || {} : {},
        };
    },
    computed: {
        classList() {
            if (!this.attrs.element_class) return [];
            return [this.attrs.element_class];
        },
        elementID() {
            if (!this.attrs.element_id) return '';
            return this.attrs.element_id || '';
        },
    },
};
</script>

<style lang="scss" scoped>
.sidebars-container {
    width: 440px;
    flex-basis: 440px;
    flex-grow: 0;
    flex-shrink: 0;
    background: $light;
}
</style>
