export default function componentInlineStyle(attrs, getColor) {
    return {
        paddingTop: attrs.padding_top ? attrs.padding_top + 'px' : undefined,
        paddingBottom: attrs.padding_bottom
            ? attrs.padding_bottom + 'px'
            : undefined,
        paddingLeft: attrs.padding_left ? attrs.padding_left + 'px' : undefined,
        paddingRight: attrs.padding_right
            ? attrs.padding_right + 'px'
            : undefined,
        fontSize: attrs.font_size ? attrs.font_size + 'px' : undefined,
        borderColor: attrs.border_color
            ? getColor(attrs.border_color)
            : undefined,
        borderWidth: attrs.border_thickness
            ? attrs.border_thickness + 'px'
            : undefined,
        borderStyle:
            attrs.border_thickness || attrs.border_color ? 'solid' : undefined,
    };
}
