<template>
    <div
        v-if="activeSidebar"
        :id="elementID"
        class="page-sidebar"
        :class="classList"
        @click="doClickAction"
    >
        <div
            class="heading"
            :style="{ backgroundColor: attrs.bg_color || 'darkblue' }"
        >
            <font-awesome-icon
                :icon="['fal', 'arrow-circle-left']"
                size="2x"
                @click="doAction(attrs.go_back_action_id, $event)"
            />
            <h4>{{ attrs.title }}</h4>
        </div>

        <slot></slot>
    </div>
</template>

<script>
// import Accordion from '../components/Accordion';
// import AccordionItem from '../components/AccordionItem';
import actions from '../mixins/actions';

export default {
    name: 'PageSidebar',
    components: {
        // Accordion,
        // AccordionItem,
    },
    mixins: [actions],
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
        };
    },
    computed: {
        activeSidebar() {
            const map = this.$store.getters['maps/active'].find(
                (map) => this.attrs.map_id === map.id,
            );

            return map ? map.sidebar === this.componentData.uuid : null;
        },
        classList() {
            return [...(this.helperClasses || []), this.attrs.element_class];
        },
        elementID() {
            return this.attrs.element_id || '';
        },
    },
};
</script>

<style lang="scss" scoped>
.page-sidebar {
    background: $light;
    padding: 0;
    height: calc(100vh - 86px);
    overflow: scroll;
    width: 380px;
    flex-basis: 380px;
    flex-grow: 0;
    flex-shrink: 0;

    .loading {
        padding: 1rem;
        text-align: center;
    }

    .heading {
        background: $primary;
        padding: 1rem;
        display: flex;
        align-items: center;

        h4 {
            color: #fff;
            margin-bottom: 0;
            font-size: 21px;
        }

        i {
            cursor: pointer;
            flex-basis: 3rem;
            text-align: center;
        }
    }
}
</style>
