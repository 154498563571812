<script>
import componentBlueprints from '../json/blueprints/componentBlueprints';
import Adder from './editor/Adder';
import portalComponents from './index';

export default {
    name: 'RenderComponent',
    components: {
        ...portalComponents,
        Adder,
    },
    props: {
        componentData: Object,
        hidden: {
            type: Boolean,
            default: true,
        },
    },
    render(createElement) {
        const componentData = this.componentData ?? {};
        const { items, highlighted, name, uuid, show } = componentData;
        const { config: { margins } = {} } = name
            ? componentBlueprints[name] ?? {}
            : {};
        const { children = items ?? [] } = componentData;
        const filteredChildren = children
            .filter((child) => child)
            .filter(({ show }) => {
                return show === undefined || show;
            });

        const dataObj = {
            key: uuid,
            props: {
                componentData,
            },
            class: {
                highlighted,
            },
        };

        if (show !== false) {
            return createElement(
                name,
                dataObj,
                filteredChildren.map((child) => {
                    child.parentUUID = uuid;
                    child.parentData = {
                        uuid,
                        name,
                        // check for grandchildren to then use to avoid rendering the adder for components who have children.
                        hasGrandChildren: filteredChildren.some(
                            (item) => item.children && item.children.length > 0,
                        ),
                    };

                    return createElement('render-component', {
                        key: child.uuid,
                        props: {
                            componentData: child,
                        },
                    });
                }),
            );
        }
        return null;
    },
};
</script>
