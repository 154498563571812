<template>
    <div
        :id="elementID"
        class="widget-container"
        :class="classList"
        :style="{ backgroundColor: getColor(attrs.bg_color) }"
    >
        <slot />

        <Adder
            :component-data="componentData.parentData"
            :child-u-u-i-d="componentData.uuid"
        />
    </div>
</template>

<script>
import helpers from '../mixins/helpers';
import Adder from './editor/Adder';

export default {
    name: 'Widget',
    components: {
        Adder,
    },
    mixins: [helpers],
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props,
        };
    },
    computed: {
        classList() {
            return [...(this.helperClasses || []), this.attrs.element_class];
        },
        elementID() {
            return this.attrs.element_id || '';
        },
    },
};
</script>

<style lang="scss" scoped>
div.widget-container {
    display: flex;
    flex-direction: column;
}
</style>
