<template>
    <ul
        class="navbar-nav flex-row ca-language"
        :style="style"
        @click="doClickAction"
    >
        <li class="nav-item navbar-text notranslate">语言</li>
        <li class="nav-item navbar-text notranslate">Linguaggio</li>
        <li class="nav-item navbar-text notranslate">언어</li>
        <li class="nav-item navbar-text notranslate">Ngôn ngữ</li>
        <li class="nav-item navbar-text">
            <div id="google_translate_container">
                <div id="google_translate_element"></div>
            </div>
        </li>
    </ul>
</template>

<script>
export default {
    name: 'Language',
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
        };
    },
    head() {
        return {
            script: [
                {
                    src: 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit',
                    body: true,
                },
            ],
        };
    },
    computed: {
        style() {
            return {
                color: this.getColor(this.attrs.text_color),
            };
        },
    },
    mounted() {
        const includedLanguages = this.attrs.included_languages;
        window.googleTranslateElementInit = function () {
            // eslint-disable-next-line no-new
            new google.translate.TranslateElement(
                {
                    pageLanguage: 'en',
                    includedLanguages: includedLanguages ?? 'ar,vi,zh-CN',
                    layout: google.translate.TranslateElement.InlineLayout
                        .SIMPLE,
                },
                'google_translate_element',
            );

            if (typeof languageModal === 'function') languageModal();
        };
    },
};
</script>

<style>
#google_translate_element {
    margin-top: -5px;
    border-radius: 5px;
    overflow: hidden;
}
</style>
