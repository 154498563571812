<template>
    <div
        :id="elementID"
        :class="classList"
        :style="style"
        class="heading ca-heading"
        @click="doClickAction"
    >
        <template v-if="isPreview && !previewMode">
            <DragHandle />
            <client-only>
                <Wysiwyg
                    v-model="
                        // eslint-disable-next-line vue/no-mutating-props
                        componentData.value
                    "
                    :show-headings="true"
                    :color="attrs.text"
                    @setEditing="setEditing"
                    @setColor="setColor"
                    @setAlign="setAlign"
                />
            </client-only>

            <Adder
                :component-data="componentData.parentData"
                :child-u-u-i-d="componentData.uuid"
            />
        </template>

        <template v-else>
            <span v-html="componentData.value" />
        </template>
    </div>
</template>

<script>
import helpers from '../mixins/helpers';
import Wysiwyg from './editor/Wysiwyg';
import DragHandle from './editor/DragHandle';
import Adder from './editor/Adder';
import componentInlineStyle from '~/utils/componentInlineStyle';

export default {
    name: 'Heading',
    components: {
        Wysiwyg,
        Adder,
        DragHandle,
    },
    mixins: [helpers],
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
            editing: true,
        };
    },
    computed: {
        style() {
            return {
                color: this.getColor(this.attrs.text),
                ...componentInlineStyle(this.attrs, this.getColor),
            };
        },
        classList() {
            return [
                this.attrs.align ? `text-${this.attrs.align}` : '',
                this.attrs.text_shadow ? 'text-shadow' : '',
                this.attrs.element_class || '',
                ...this.helperClasses,
            ];
        },
        elementID() {
            return this.attrs.element_id || '';
        },
    },
    methods: {
        setEditing(val) {
            this.editing = val;
        },
        setAlign(e) {
            this.attrs.align = e;
        },
        setColor(e) {
            this.attrs.text = e;
        },
    },
};
</script>

<style lang="scss" scoped>
.align-items-left {
    div.heading {
        text-align: left;
    }
}

.align-items-center {
    div.heading {
        text-align: center;
    }
}

.align-items-right {
    div.heading {
        text-align: right;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: 0.5rem;
    display: inline-block;
    color: inherit;
}

a {
    margin-left: 1rem;
    display: inline-block;
    cursor: pointer;
    font-size: 11px;
}
</style>

<style lang="scss">
.draggable .heading:hover {
    .drag-handle,
    .adder {
        opacity: 1;
    }
}
</style>
