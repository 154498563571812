<template>
    <div class="form-name">
        <b-form-group
            id="firstName"
            label="First name"
            :label-for="'input_first_name'"
            class="width-half"
        >
            <b-form-input
                id="input_first_name"
                v-model="value"
                type="text"
                placeholder="Enter your first name"
                :required="attrs.required"
            />
        </b-form-group>

        <b-form-group
            id="lastName"
            label="Last name"
            :label-for="'input_last_name'"
            class="width-half"
        >
            <b-form-input
                id="input_last_name"
                v-model="value"
                type="text"
                placeholder="Enter your last name"
                :required="attrs.required"
            />
        </b-form-group>
    </div>
</template>

<script>
export default {
    name: 'FormName',
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
            value: '',
        };
    },
};
</script>

<style lang="scss">
.form-name {
    display: flex;
    padding: 0.25em;
    flex-basis: 100%;

    .width-quarter {
        flex-basis: calc(25%);
    }

    .width-third {
        flex-basis: calc(33%);
    }

    .width-half {
        flex-basis: calc(50%);
    }

    .width-two-thirds {
        flex-basis: calc(66%);
    }

    .width-three-quarters {
        flex-basis: calc(75%);
    }

    .width-full {
        flex-basis: calc(100%);
    }
}
</style>
