<template>
    <span :id="elementID" :class="classList" @click="doClickAction">
        <font-awesome-icon
            :icon="[attrs.weight || 'far', attrs.icon]"
            :size="attrs.size"
            :style="style"
        />
    </span>
</template>

<script>
import helpers from '../mixins/helpers';
export default {
    name: 'Icon',
    mixins: [helpers],
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
        };
    },
    computed: {
        classList() {
            return [this.attrs.element_class, ...this.helperClasses];
        },
        style() {
            return {
                color: this.getColor(this.attrs.color || 'secondary'),
            };
        },
        elementID() {
            return this.attrs.element_id || '';
        },
    },
};
</script>
