<template>
    <div class="pages-container ca-component ca-pages" @click="doClickAction">
        <div v-if="loading" class="text-center">
            <div class="mb-3">Pages Loading...</div>
            <b-spinner
                variant="primary"
                label="Spinner"
                style="width: 150px; height: 150px"
            ></b-spinner>
        </div>
        <div v-else-if="error" class="text-center">Error loading Pages</div>
        <div :id="elementID" :class="classList" class="pages">
            <div
                v-for="(p, i) in filteredPages"
                :key="i"
                class="page-container shadow"
                click.stop="doClick(p)"
            >
                <div v-if="p.published_status === 'draft'" class="draft-banner">
                    <font-awesome-icon
                        :icon="['fas', 'exclamation-triangle']"
                    />
                    <span>
                        Note this draft Page will not appear in published
                        content. Please publish this Page to include it in your
                        content.
                    </span>
                </div>

                <b-card
                    class="page-item ca-contents-content ca-card card"
                    header-class="position-relative p-0"
                    no-body
                >
                    <template #header>
                        <div
                            class="thumbnail-container ca-thumbnail ca-contents-content-thumbnail"
                        >
                            <b-img-lazy
                                :src="
                                    p.metadata.props.thumbnail
                                        ? p.metadata.props.thumbnail.url
                                        : 'https://ca-v2.s3-ap-southeast-2.amazonaws.com/placeholder.jpg'
                                "
                                alt=""
                                class="img-fluid"
                                width="640"
                                height="360"
                                loading="lazy"
                            />
                        </div>
                    </template>

                    <b-card-body
                        class="content ca-contents-content-cardbody ca-cardbody card-body"
                    >
                        <div class="info">
                            <h5
                                :style="{
                                    fontFamily: projectConfig.fontFamily,
                                }"
                                class="ca-heading ca-contents-content-title"
                            >
                                {{ p.metadata.props.title }}
                            </h5>

                            <p
                                v-if="attrs.show_date && p.published_at"
                                :style="{
                                    fontFamily: projectConfig.fontFamily,
                                }"
                                class="ca-contents-content-date"
                            >
                                {{
                                    $moment(new Date(p.published_at)).format(
                                        'D MMMM, YYYY',
                                    )
                                }}
                            </p>
                            <p
                                v-if="attrs.show_excerpt && p.description"
                                :style="{
                                    fontFamily: projectConfig.fontFamily,
                                }"
                                class="description ca-contents-content-excerpt text-truncate-description"
                                style="
                                    overflow: hidden;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 3;
                                    -webkit-box-orient: vertical;
                                "
                            >
                                <span v-html="p.description" />
                            </p>
                        </div>
                    </b-card-body>
                </b-card>

                <b-modal
                    :id="'modal-' + p.id"
                    hide-footer
                    modal-class="custom-modal"
                    size="md"
                    centered
                >
                    <template #modal-header="{ close }">
                        <b-button size="md" @click="close()">
                            <small
                                :style="{
                                    fontFamily: projectConfig.fontFamily,
                                }"
                            >
                                <font-awesome-icon
                                    :icon="['fal', 'times']"
                                />&nbsp; CLOSE
                            </small>
                        </b-button>
                    </template>

                    <div class="-item-content">
                        <div class="heading">
                            <h3
                                :style="{
                                    fontFamily: projectConfig.fontFamily,
                                }"
                            >
                                {{ p.metadata.props.title }}
                            </h3>
                        </div>
                        <RenderComponent :component-data="p.metadata" />
                    </div>
                </b-modal>
            </div>
        </div>
        <DragHandle />
        <Adder
            :component-data="componentData.parentData"
            :child-u-u-i-d="componentData.uuid"
        />
    </div>
</template>

<script>
import helpers from '../mixins/helpers';
import Adder from '~/components/editor/Adder';
import DragHandle from '~/components/editor/DragHandle';
export default {
    name: 'Pages',
    components: {
        Adder,
        DragHandle,
    },
    mixins: [helpers],
    props: {
        componentData: { type: Object, default: () => ({}) },
    },
    data() {
        return {
            attrs: this.componentData?.props || {},
            pages: [],
            modal: false,
            loading: false,
            error: false,
        };
    },
    computed: {
        elementID() {
            return this.attrs.element_id || '';
        },
        classList() {
            return [this.attrs.element_class || '', this.attrs.layout];
        },
        filteredPages() {
            const showDrafts = this.attrs.filter_by_status === 'All';
            const allPublishedIDs = this.pages
                .filter((w) => w.published_status === 'published')
                .map((w) => w.metadata.uuid);

            const filtered = this.pages
                .filter((item) => {
                    if (!item.metadata?.props) {
                        return false;
                    }
                    if (item.published_status.toLowerCase() !== 'published') {
                        if (
                            !showDrafts ||
                            allPublishedIDs.includes(item.metadata.uuid)
                        ) {
                            return false;
                        }
                    }

                    return true;
                })
                .map((p) => {
                    this.pages.find(
                        (unfilteredPage) => unfilteredPage.uuid === p.uuid,
                    );

                    return {
                        ...p,
                        description:
                            p.metadata.props.description ||
                            this.findParagraphText(p.metadata),
                    };
                });

            return filtered.slice(0, this.limit(filtered));
        },
    },
    watch: {
        attrs: {
            deep: true,
            handler() {
                this.getPages();
            },
        },
    },
    mounted() {
        if (!this.attrs.filter_by_status) {
            this.$set(this.attrs, 'filter_by_status', 'All');
        }
        if (!this.attrs.content_status) {
            this.$set(this.attrs, 'content_status', 'Active');
        }

        this.getPages();
    },
    methods: {
        limit(contents = []) {
            const defaultNumber = 3;
            if (this.attrs.show_number === 'custom') {
                const number = Number(this.attrs.show_number_custom);
                if (number && Number.isInteger(number) && number > 0) {
                    return number;
                } else {
                    return defaultNumber;
                }
            }
            if (this.attrs.show_number === 'all') {
                return contents.length || defaultNumber;
            }
            return this.attrs.show_number || defaultNumber;
        },
        async getPages() {
            const vm = this;
            vm.loading = true;
            vm.error = false;
            vm.pages = [];
            try {
                const filter = vm.attrs.content_status;
                if (
                    filter === 'Custom' &&
                    vm.attrs.filter_by_ids &&
                    vm.attrs.filter_by_ids.length
                ) {
                    const unfilterted = await vm.fetchContents(
                        vm.attrs.filter_by_ids,
                    );
                    const published = unfilterted.data.map(
                        // eslint-disable-next-line camelcase
                        ({ published_copy, id }) => published_copy ?? id,
                    );
                    const res = await vm.fetchContents(published);
                    vm.pages = res.data;
                } else {
                    if (vm.$store.state.api.pages) {
                        vm.pages = vm.$store.state.api.pages;
                        return;
                    }
                    const res = await vm.$store.dispatch(
                        'api/getAllContentBasic',
                        { contentType: 'page' },
                    );
                    vm.pages = res;
                }
                return;
            } catch (e) {
                vm.error = true;
                console.error(e);
            } finally {
                vm.loading = false;
            }
        },
        async fetchContents(ids, type = 'page') {
            return await this.$axios.$get('/contents', {
                params: {
                    'filter[type]': type,
                    'filter[project_id]': this.$store.state.api.projectID,
                    limit: '-1',
                    'filter[id]': ids,
                    basic_data: true,
                    for_editor: true,
                    include: 'client',
                },
            });
        },
        findParagraphText(page) {
            let paragraphText;

            page.children?.forEach((child) => {
                if (child.name === 'Paragraph') {
                    paragraphText = child.value;
                } else {
                    this.findParagraphText(child);
                }
            });

            return paragraphText;
        },
        doClick(p) {
            if (this.isPreview && !this.previewMode) {
                this.openEditor(this.componentData.uuid);
            } else {
                this.$bvModal.show('modal-' + p.id);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.pages {
    list-style-type: none;
    display: flex;

    &:hover {
        .drag-handle,
        .adder {
            opacity: 1;
        }
    }

    .page-container {
        margin-bottom: 1em;
        cursor: pointer;
        transition: 0.3s ease;
        &:hover {
            filter: brightness(0.9);
            transform: scale(1.01);
        }
    }

    .draft-banner {
        background: #dae6ec;
        padding: 1.5em;
        font-size: 14px;
        font-weight: 600;
        font-family: Arial, Helvetica, sans-serif;
        display: flex;
        flex-direction: row;
        align-items: center;

        .fa-exclamation-triangle {
            margin-right: 1em;
        }
    }

    &.list {
        flex-direction: column;
        flex-wrap: nowrap;

        .page-item {
            display: flex;
            flex-direction: row;

            .thumbnail-container {
                border-radius: 4px 0 0 4px;
                width: 100%;
                padding-top: 22.5%;
            }

            .content {
                flex-basis: 60%;
            }
            .thumbnail-container {
                flex-basis: 40%;
            }
        }
    }

    &.grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        /* This is better for small screens, once min() is better supported */
        /* grid-template-columns: repeat(auto-fill, minmax(min(200px, 100%), 1fr)); */
        grid-gap: 1rem;

        .page-item {
            display: flex;
            flex-direction: column;
            justify-content: stretch;
            position: relative;
            height: 100%;

            .thumbnail-container {
                width: 100%;
                // border-radius: 4px 0 0 4px;
            }

            .content {
                flex-basis: 100%;
            }
        }
    }

    .page-item {
        background: #fff;

        .thumbnail-container {
            background-size: cover;
            background-position: center center;
        }

        .content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
            height: auto;

            .buttons {
                padding-top: 0.5em;
                color: $light;
            }

            .description {
                display: flex;
                flex-direction: row;
            }

            h5 {
                font-weight: 700;
            }

            svg {
                width: 30px;
                text-align: center;
            }
        }
    }
}
</style>

<style lang="scss">
.page-content {
    .heading {
        background: $primary;
        padding: 1em;
        border-radius: 4px 0 0 0;

        h3 {
            color: $light;
            margin: 0;
        }
    }
}
</style>
