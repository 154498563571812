<template>
    <table
        v-if="cardRows.length > 0"
        cellpadding="0"
        cellspacing="0"
        width="100%"
        class="cards"
        style="borderspacing: 16px; margin: 16px 0; border-collapse: collapse"
    >
        <tbody>
            <tr
                v-for="(row, i) in cardRows"
                :key="i"
                style="display: flex; width: 100%"
            >
                <Card v-for="(item, j) in row" :key="j" :item="item" />
            </tr>
        </tbody>
    </table>
</template>

<script>
import Card from './Card';

export default {
    name: 'Cards',
    components: {
        Card,
    },
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
            cardRows: [],
        };
    },
    created() {
        const cardItems = this.componentData.children.filter(
            (gi) => gi.props.media && gi.show !== false,
        );
        this.cardRows = this.chunkArray(cardItems, 2);
    },
    methods: {
        chunkArray(arr, chunkSize) {
            let index = 0;
            const arrayLength = arr.length;
            const tempArray = [];

            for (index = 0; index < arrayLength; index += chunkSize) {
                const chunk = arr.slice(index, index + chunkSize);

                tempArray.push(chunk);
            }

            return tempArray;
        },
    },
};
</script>
