<template>
    <div
        class="topic mb-2"
        :style="{ borderLeft: '3px solid ' + getColor(attrs.color) }"
        @click.prevent="goToCategory"
    >
        <h6 :style="{ fontFamily: projectConfig.fontFamily }">
            {{ attrs.title }}
        </h6>
        <p :style="{ fontFamily: projectConfig.fontFamily }">
            {{ attrs.subtitle }}
        </p>
    </div>
</template>

<script>
export default {
    name: 'Topic',
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
        };
    },
    methods: {
        goToCategory() {
            this.$store.commit('maps/setActiveCategory', this.attrs.category);
        },
    },
};
</script>

<style lang="scss" scoped>
div.topic {
    padding: 0.5rem 1rem;
    display: block;
    cursor: pointer;
    background: #f6f6f6;
    border: 1px solid #eee;
    cursor: pointer;

    h6 {
        font-size: 14px;
        margin-bottom: 0.25em;
    }

    p {
        font-size: 12px;
        margin-bottom: 0;
        line-height: 14px;
        font-weight: 400;
    }
}
</style>
