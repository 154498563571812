<template>
    <SceneLoader
        v-if="componentData.props && componentData.props.scene_id"
        :scene-i-d="componentData.props.scene_id"
    />
</template>

<script>
import SceneLoader from './SceneLoader';

export default {
    name: 'Scene',
    components: {
        SceneLoader,
    },
    props: {
        componentData: { type: [Object, null] },
    },
    data() {
        return {
            activeScene: null,
        };
    },
};
</script>
