export const state = () => ({
    pan: false,
    addingHotspot: false,
    hotspots: [],
});

export const getters = {
    addingHotspot(state) {
        return state.addingHotspot;
    },
    pan(state) {
        return state.pan;
    },
};

export const mutations = {
    addingHotspot(state, val) {
        state.addingHotspot = val;
    },
    setPan(state, val) {
        state.pan = val;
    },
};
