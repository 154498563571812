var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-section-container"},[_c('div',{staticClass:"page-section",class:_vm.classList,style:(_vm.style),attrs:{"id":_vm.elementID},on:{"click":_vm.doClickAction}},[_c('DragHandle'),_vm._v(" "),_c('div',{class:_vm.attrs.width === 'fluid' ? 'container-fluid' : 'container'},[(_vm.isPreview && !_vm.previewMode)?_c('draggable',{attrs:{"handle":".drag-handle","group":"pageSection"},on:{"change":function($event){return _vm.refreshPreviewOrder(_vm.componentData.uuid)}},model:{value:(
                    // eslint-disable-next-line vue/no-mutating-props
                    _vm.componentData.children
                ),callback:function ($$v) {_vm.$set(// eslint-disable-next-line vue/no-mutating-props
                    _vm.componentData, "children", $$v)},expression:"\n                    // eslint-disable-next-line vue/no-mutating-props\n                    componentData.children\n                "}},[_vm._t("default")],2):_vm._t("default"),_vm._v(" "),(
                    _vm.componentData.children &&
                    _vm.componentData.children.length === 0
                )?_c('Adder',{attrs:{"component-data":_vm.componentData}}):_vm._e()],2)],1),_vm._v(" "),_c('Adder',{attrs:{"component-data":_vm.componentData.parentData,"child-u-u-i-d":_vm.componentData.uuid}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }