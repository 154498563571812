<template>
    <b-form-group
        id="email"
        label="Email"
        :label-for="'input_email'"
        class="width-full"
    >
        <b-form-input
            id="input_email"
            v-model="value"
            type="email"
            placeholder="Enter your email address"
            :required="attrs.required"
        />
    </b-form-group>
</template>

<script>
export default {
    name: 'FormEmail',
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
            value: '',
        };
    },
};
</script>

<style lang="scss">
.form-input {
    margin: 0.25em;

    .red {
        color: red;
    }
}
</style>
