import moment from 'moment';

/**
 * Format time periods
 * @param {*} tp
 * @returns time period text
 */
export default function workActivityTimePeriodText(tp) {
    let description = tp.text;
    if (tp.start) {
        description += ' ' + moment(tp.start, 'H:mm').format('h:mm a');
    }
    if (tp.start && tp.end) {
        description += ' -';
    }
    if (tp.end) {
        description += ' ' + moment(tp.end, 'H:mm').format('h:mm a');
    }
    return description;
}
