import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2d119fbe = () => interopDefault(import('../pages/campaign-template/index.vue' /* webpackChunkName: "pages/campaign-template/index" */))
const _33473550 = () => interopDefault(import('../pages/form/index.vue' /* webpackChunkName: "pages/form/index" */))
const _3bd368ce = () => interopDefault(import('../pages/header/index.vue' /* webpackChunkName: "pages/header/index" */))
const _43fc44b6 = () => interopDefault(import('../pages/healthcheck/index.vue' /* webpackChunkName: "pages/healthcheck/index" */))
const _1ead10e6 = () => interopDefault(import('../pages/map/index.vue' /* webpackChunkName: "pages/map/index" */))
const _3d119537 = () => interopDefault(import('../pages/modal/index.vue' /* webpackChunkName: "pages/modal/index" */))
const _9a9e1bb8 = () => interopDefault(import('../pages/news-post/index.vue' /* webpackChunkName: "pages/news-post/index" */))
const _0201ed4a = () => interopDefault(import('../pages/page/index.vue' /* webpackChunkName: "pages/page/index" */))
const _7ed177c2 = () => interopDefault(import('../pages/page-template/index.vue' /* webpackChunkName: "pages/page-template/index" */))
const _593ae8d2 = () => interopDefault(import('../pages/preview/index.vue' /* webpackChunkName: "pages/preview/index" */))
const _02182856 = () => interopDefault(import('../pages/scene/index.vue' /* webpackChunkName: "pages/scene/index" */))
const _7888291b = () => interopDefault(import('../pages/unsubscribe/index.vue' /* webpackChunkName: "pages/unsubscribe/index" */))
const _1bfbca90 = () => interopDefault(import('../pages/widget/index.vue' /* webpackChunkName: "pages/widget/index" */))
const _40502055 = () => interopDefault(import('../pages/work-activity/index.vue' /* webpackChunkName: "pages/work-activity/index" */))
const _779c67f4 = () => interopDefault(import('../pages/news-post/view/index.vue' /* webpackChunkName: "pages/news-post/view/index" */))
const _c1c6cc3c = () => interopDefault(import('../pages/work-activity/map/index.vue' /* webpackChunkName: "pages/work-activity/map/index" */))
const _97065716 = () => interopDefault(import('../pages/work-activity/view/index.vue' /* webpackChunkName: "pages/work-activity/view/index" */))
const _f5f6fdc0 = () => interopDefault(import('../pages/campaigns/news-post/_id.vue' /* webpackChunkName: "pages/campaigns/news-post/_id" */))
const _c928a66c = () => interopDefault(import('../pages/work-activity/map/_id.vue' /* webpackChunkName: "pages/work-activity/map/_id" */))
const _7b863a95 = () => interopDefault(import('../pages/campaigns/_id.vue' /* webpackChunkName: "pages/campaigns/_id" */))
const _18f8238e = () => interopDefault(import('../pages/contents/_id.vue' /* webpackChunkName: "pages/contents/_id" */))
const _4cc68d4e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/campaign-template",
    component: _2d119fbe,
    name: "campaign-template"
  }, {
    path: "/form",
    component: _33473550,
    name: "form"
  }, {
    path: "/header",
    component: _3bd368ce,
    name: "header"
  }, {
    path: "/healthcheck",
    component: _43fc44b6,
    name: "healthcheck"
  }, {
    path: "/map",
    component: _1ead10e6,
    name: "map"
  }, {
    path: "/modal",
    component: _3d119537,
    name: "modal"
  }, {
    path: "/news-post",
    component: _9a9e1bb8,
    name: "news-post"
  }, {
    path: "/page",
    component: _0201ed4a,
    name: "page"
  }, {
    path: "/page-template",
    component: _7ed177c2,
    name: "page-template"
  }, {
    path: "/preview",
    component: _593ae8d2,
    name: "preview"
  }, {
    path: "/scene",
    component: _02182856,
    name: "scene"
  }, {
    path: "/unsubscribe",
    component: _7888291b,
    name: "unsubscribe"
  }, {
    path: "/widget",
    component: _1bfbca90,
    name: "widget"
  }, {
    path: "/work-activity",
    component: _40502055,
    name: "work-activity"
  }, {
    path: "/news-post/view",
    component: _779c67f4,
    name: "news-post-view"
  }, {
    path: "/work-activity/map",
    component: _c1c6cc3c,
    name: "work-activity-map"
  }, {
    path: "/work-activity/view",
    component: _97065716,
    name: "work-activity-view"
  }, {
    path: "/campaigns/news-post/:id?",
    component: _f5f6fdc0,
    name: "campaigns-news-post-id"
  }, {
    path: "/work-activity/map/:id",
    component: _c928a66c,
    name: "work-activity-map-id"
  }, {
    path: "/campaigns/:id?",
    component: _7b863a95,
    name: "campaigns-id"
  }, {
    path: "/contents/:id?",
    component: _18f8238e,
    name: "contents-id"
  }, {
    path: "/",
    component: _4cc68d4e,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
