<template>
    <td
        :style="{
            padding: margin,
            width: attrs.block ? '700px' : undefined,
        }"
        :colspan="attrs.block ? 10 : undefined"
        align="center"
    >
        <table
            cellpadding="0"
            cellspacing="0"
            border="0"
            :width="attrs.block ? '100%' : undefined"
        >
            <tbody>
                <tr>
                    <td
                        :width="attrs.block ? '100%' : undefined"
                        :style="{
                            color: getColor(attrs.text),
                            backgroundColor: getColor(attrs.bg_color),
                            padding,
                            width: attrs.block ? '100%' : undefined,
                        }"
                        align="center"
                    >
                        <a
                            :href="href || attrs.href"
                            target="_blank"
                            :style="{
                                textAlign: 'center',
                                color: getColor(attrs.text),
                                borderRadius: '4px',
                                fontFamily: projectConfig.fontFamily,
                                width: attrs.block ? '100%' : undefined,
                                display: attrs.block ? 'block' : undefined,
                                textDecoration: 'none',
                            }"
                        >
                            <span
                                :style="{
                                    display: attrs.block ? 'block' : undefined,
                                }"
                                >{{ componentData.value }}</span
                            >
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
    </td>
</template>

<script>
import onClickActions from '~/mixins/onClickActions';
export default {
    name: 'ButtonLink',
    mixins: [onClickActions],
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
        };
    },
    computed: {
        padding() {
            const sizes = { lg: '12px 24px', md: '8px 16px', sm: '4px 8px' };
            return sizes[this.attrs.size] ?? sizes.md;
        },
        margin() {
            const sizes = {
                lg: '0 16px 16px 0',
                md: '0 8px 8px 0',
                sm: '0 4px 4px 0',
            };
            return sizes[this.attrs.size] ?? sizes.md;
        },
    },
};
</script>
