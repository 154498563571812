<template>
    <div
        :style="{
            backgroundColor: getColor(attrs.bg_color),
            color: getColor(attrs.text_color),
            fontSize: '12px!important',
        }"
        class="campaign-footer"
    >
        <RenderEmailComponent
            v-for="(component, index) in componentData.children"
            :key="index"
            :component-data="component"
        />

        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'CampaignFooter',
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
        };
    },
    computed: {
        classList() {
            return [this.attrs.element_class];
        },
        elementID() {
            return this.attrs.element_id || '';
        },
    },
    mounted() {
        console.log('CampaignFooter loaded');
    },
};
</script>
