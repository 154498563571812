<template>
    <div v-if="isPreview || attrs.media" class="gallery-item-container">
        <b-card
            :id="elementID"
            class="gallery-item"
            :class="classList"
            no-body
            @click.stop="doClick"
        >
            <b-card-header>
                <MediaLoader
                    v-if="attrs.media"
                    :media="attrs.media"
                    :thumb="true"
                />
                <img
                    v-else
                    class="placeholder-img"
                    src="https://dummyimage.com/640x360/eee/aaa"
                />
            </b-card-header>

            <b-card-text v-if="attrs.caption">
                <span :style="{ fontFamily: projectConfig.fontFamily }">{{
                    attrs.caption
                }}</span>
            </b-card-text>
        </b-card>

        <Adder
            alignment="row"
            :component-data="componentData.parentData"
            :child-u-u-i-d="componentData.uuid"
        />

        <b-modal
            v-if="attrs.media"
            v-model="showPopup"
            hide-footer
            modal-class="custom-modal"
            size="md"
            centered
        >
            <template #modal-header="{ close }">
                <b-button size="md" @click="close()">
                    <small>
                        <font-awesome-icon :icon="['fal', 'times']" />&nbsp;
                        CLOSE
                    </small>
                </b-button>
            </template>

            <MediaLoader :media="attrs.media" />

            <p v-if="attrs.caption" class="p-3 m-0">
                <span
                    class="text-dark"
                    :style="{ fontFamily: projectConfig.fontFamily }"
                >
                    {{ attrs.caption }}
                </span>
            </p>
        </b-modal>
    </div>
</template>

<script>
import MediaLoader from './MediaLoader';
import Adder from './editor/Adder';
export default {
    name: 'GalleryItem',
    components: {
        MediaLoader,
        Adder,
    },
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
            showPopup: false,
        };
    },
    computed: {
        classList() {
            return this.attrs.element_class;
        },
        elementID() {
            return this.attrs.element_id || '';
        },
    },
    methods: {
        doClick() {
            if (!this.attrs.media || (this.isPreview && !this.previewMode)) {
                this.openEditor(this.componentData.uuid);
            } else {
                this.showPopup = true;
            }
        },
    },
};
</script>

<style lang="scss">
.gallery-item-container {
    display: flex;
    flex-direction: row;
    div.card.gallery-item {
        width: 100%;
        .card-header {
            padding: 0;
        }
        .card-text {
            padding: 0.5rem 0.75rem;
            margin-bottom: 0 !important;
        }
    }
}
</style>
