var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('table',{staticClass:"translation-services",style:({
            margin: '0',
            borderSpacing: '16px',
            borderCollapse: 'separate',
        }),attrs:{"cellpadding":"0","cellspacing":"0","border":"0"}},[_c('tbody',[_c('tr',[_c('td',{style:({ textAlign: 'center' })},[_c('img',{attrs:{"src":"https://ca-v2.s3-ap-southeast-2.amazonaws.com/Interpreter-Symbol-with-text.png","width":"80","height":"80","alt":"Translation services"}})]),_vm._v(" "),_c('td',{style:({
                        textAlign: 'left',
                        borderTopWidth: '1px',
                        borderTopStyle: 'solid',
                        borderTopColor: 'inherit',
                        borderBottomWidth: '1px',
                        borderBottomStyle: 'solid',
                        borderBottomColor: 'inherit',
                    })},[_c('p',{style:({
                            fontFamily: _vm.projectConfig.fontFamily,
                            color: 'inherit',
                            borderLeft: '16px solid rgba(0,0,0,0)',
                        }),domProps:{"innerHTML":_vm._s(_vm.attrs.text)}})])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }