var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{staticStyle:{"width":"100%"},attrs:{"cellpadding":"0","cellspacing":"0","border":"0","width":"100%"}},[_c('tbody',{staticStyle:{"width":"100%"},attrs:{"width":"100%"}},[_c('tr',[_c('td',{attrs:{"align":"center"}},[_c('img',{staticClass:"media-item-img-full-width",staticStyle:{"width":"100%","max-width":"720px","display":"block","border":"0px"},attrs:{"src":_vm.thumbnail,"border":"0","height":"auto","width":"720","alt":"Title here"}})])]),_vm._v(" "),_c('tr',{staticStyle:{"width":"100%"},attrs:{"width":"100%"}},[_c('td',{style:({
                    backgroundColor: _vm.getColor('brand_secondary'),
                    height: '32px',
                    textAlign: 'center',
                    width: '100%',
                }),attrs:{"width":"100%"}},[_c('a',{style:({
                        color: '#fff',
                        textAlign: 'center',
                        textDecoration: 'none',
                        verticalAlign: 'middle',
                        lineHeight: '32px',
                        fontFamily: _vm.projectConfig.fontFamily,
                        width: '100%',
                    }),attrs:{"href":_vm.attrs.src,"target":"_blank","rel":"noopener"}},[_vm._v("\n                    Play Video\n                ")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }