<template>
    <a
        :id="elementID"
        :href="feedbackLink"
        :style="style"
        class="btn scroll"
        :class="classList"
        @click.stop="onClick"
    >
        <font-awesome-icon :icon="['fas', 'comment']" />

        {{ componentData.value || 'How to provide feedback' }}
    </a>
</template>

<script>
export default {
    name: 'FeedbackBtn',
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
            feedbackLink: '/' + this.componentData.parentUUID,
        };
    },
    computed: {
        classList() {
            return [
                `btn-${this.attrs.size || 'md'}`,
                `text-${this.attrs.text || 'dark'}`,
                this.attrs.element_class || '',
                this.attrs.block ? 'btn-block' : '',
            ];
        },
        style() {
            return {
                backgroundColor: this.getColor(this.attrs.bg_color),
            };
        },
        elementID() {
            return this.attrs.element_id || '';
        },
    },
    methods: {
        onClick(event) {
            event.preventDefault();
            this.openEditor(this.componentData.uuid);
        },
    },
};
</script>

<style scoped>
a {
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
}
</style>
