<template comments>
    <table
        border="0"
        cellpadding="0"
        cellspacing="0"
        style="width: 100%; max-width: 700px !important"
    >
        <tr>
            <td>
                <span v-html="outerTableStart"></span>
                <div style="margin: 0px auto; max-width: 700px">
                    <table
                        align="center"
                        border="0"
                        cellpadding="0"
                        cellspacing="0"
                        role="presentation"
                        style="width: 100%"
                    >
                        <tbody>
                            <tr>
                                <td
                                    style="
                                        direction: ltr;
                                        font-size: 0px;
                                        padding: 0;
                                        padding-bottom: 0;
                                        text-align: center;
                                    "
                                >
                                    <span v-html="innerTableStart"></span>
                                    <slot></slot>
                                    <span v-html="innerTableEnd"></span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <span v-html="outerTableEnd"></span>
            </td>
        </tr>
    </table>
</template>

<script>
/**
 * https://templates.mailchimp.com/development/responsive-email/responsive-column-layouts/
 */
export default {
    name: 'NewsPostColumns',
    data: () => ({
        outerTableStart: `<!--[if mso | IE]>
                        <table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:700px;" width="700">
                            <tr>
                                <td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->`,
        innerTableStart: `<!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><![endif]-->`,
        innerTableEnd: `<!--[if mso | IE]></tr></tbody></table><![endif]-->`,
        outerTableEnd: `<!--[if mso | IE]></td></tr></table><![endif]-->`,
    }),
};
</script>
