<template>
    <div style="display: flex; align-items: center; justify-content: left">
        <span v-if="['Polygon', 'Point'].includes(type)">
            <div
                :style="{
                    backgroundColor: _color && !pattern ? _color : undefined,
                    backgroundImage: pattern
                        ? `url(${getPattern(pattern)})`
                        : undefined,
                    backgroundSize: '64px',
                    opacity,
                    borderRadius: type === 'Point' ? '50%' : 0,
                    borderStyle:
                        borderWidth || type === 'Point' ? 'solid' : undefined,
                    borderWidth: `${borderWidth}px`,
                    borderColor,
                    width: size,
                    height: size,
                }"
                style="margin: 0 auto; position: relative"
            >
                <template v-if="iconText">
                    {{ iconText }}
                </template>
                <font-awesome-icon
                    v-else-if="iconValue"
                    :icon="['fas', iconValue]"
                    size="lg"
                    :style="{
                        color: getColor(iconTextColor || 'light'),
                    }"
                    style="
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        height: 16px;
                    "
                />
                <template v-else>&#8203;</template>
            </div>
        </span>
        <span v-else>
            <div
                :style="{
                    opacity,
                    borderTopColor: _color,
                    borderTopWidth: `${lineWidth}px`,
                    borderTopStyle: lineStyle,
                    width: size,
                }"
                style="display: inline-block; margin: auto"
            ></div>
        </span>
        <p
            v-if="label"
            style="display: inline-block; margin-bottom: 0; margin-left: 8px"
        >
            {{ label }}
        </p>
    </div>
</template>

<script>
import universalBtoa from '@/utils/universalBtoa';
import stringToHtml from '@/utils/stringToHtml';
import getColor from '~/utils/getColor';
export default {
    props: {
        type: {
            type: String,
            required: true,
        },
        opacity: {
            type: Number,
            required: false,
            default: 1,
        },
        pattern: {
            type: String,
            required: false,
        },
        color: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            default: null,
        },
        lineStyle: {
            type: String,
            default: 'solid',
        },
        lineWidth: {
            type: Number,
            default: 2,
        },
        iconText: {
            type: String,
            default: null,
        },
        borderColor: {
            type: String,
            default: null,
        },
        borderWidth: {
            type: Number,
        },
        iconValue: {
            type: String,
            default: null,
        },
        iconTextColor: {
            type: String,
            default: null,
        },
        iconColor: {
            type: String,
            default: null,
        },
        lineIconFilename: {
            type: String,
            default: null,
        },
    },
    computed: {
        _color() {
            return getColor(this.color);
        },
        size() {
            return '32px';
        },
    },
    methods: {
        getPattern(pattern) {
            const str =
                require(`!!raw-loader!~/assets/svg/patterns/${pattern}`).default;
            const svg = stringToHtml(str)('svg').first();
            svg.css('color', this._color);
            const serializedSVG = svg.prop('outerHTML');
            const base64Data = universalBtoa(serializedSVG);
            const imgsrc = `data:image/svg+xml;base64,${base64Data}`;
            return imgsrc;
        },
    },
};
</script>
