<template>
    <div
        :id="elementID"
        :class="classList"
        :style="style"
        class="paragraph ca-component ca-paragraph"
        @click="doClickAction"
    >
        <template v-if="isPreview && !previewMode">
            <client-only>
                <Wysiwyg
                    v-model="
                        // eslint-disable-next-line vue/no-mutating-props
                        componentData.value
                    "
                    :color="attrs.text"
                    @setEditing="setEditing"
                    @setColor="setColor"
                    @setAlign="setAlign"
                />
            </client-only>

            <DragHandle />

            <Adder
                :component-data="componentData.parentData"
                :child-u-u-i-d="componentData.uuid"
            />
        </template>

        <template v-else>
            <span
                :style="{
                    fontFamily: projectConfig.fontFamily,
                }"
                v-html="componentData.value"
            />
        </template>
    </div>
</template>

<script>
import helpers from '../mixins/helpers';
import Wysiwyg from './editor/Wysiwyg';
import Adder from './editor/Adder';
import DragHandle from './editor/DragHandle';
import componentInlineStyle from '~/utils/componentInlineStyle';

export default {
    name: 'Paragraph',
    components: {
        Adder,
        DragHandle,
        Wysiwyg,
    },
    mixins: [helpers],
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
            editing: true,
        };
    },
    computed: {
        style() {
            return {
                color: this.getColor(this.attrs.text || 'dark'),
                ...componentInlineStyle(this.attrs, this.getColor),
            };
        },
        classList() {
            const align = this.attrs.align;
            const shadow = this.attrs.text_shadow;
            const truncate = this.attrs.text_truncate;

            return [
                align ? `text-${align}` : '',
                shadow ? 'text-shadow' : '',
                truncate ? 'text-truncate-description' : '',
                this.attrs.element_class || '',
                ...this.helperClasses,
            ];
        },
        elementID() {
            return this.attrs.element_id ?? undefined;
        },
    },
    methods: {
        setEditing(val) {
            this.editing = val;
        },
        setAlign(e) {
            this.$set(this.attrs, 'align', e);
        },
        setColor(e) {
            this.$set(this.attrs, 'text', e);
        },
    },
};
</script>

<style lang="scss" scoped>
.paragraph {
    position: relative;

    &:hover {
        .drag-handle,
        .adder {
            opacity: 1;
        }
    }

    p {
        color: inherit;
        font-size: 1em;
        line-height: 1.5;
    }

    a {
        font-size: 1em;
        display: block;
        cursor: pointer;
    }

    .clickable {
        z-index: 5;
    }
}
</style>
