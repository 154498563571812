<template>
    <td
        :style="{
            borderSpacing: '16px',
            verticalAlign: 'top',
            border: '1px solid #f3f6f8',
        }"
    >
        <EmailMedia
            :component-data="{
                parentData: {
                    name: 'GalleryItem',
                },
                props: {
                    media: item.props.media,
                },
            }"
        ></EmailMedia>

        <div v-if="item.props.caption">
            <div style="margin: 12px">
                <p :style="{ fontFamily: projectConfig.fontFamily }">
                    {{ item.props.caption }}
                </p>
            </div>
        </div>
    </td>
</template>

<script>
import EmailMedia from './Media';
export default {
    name: 'GalleryItem',
    components: {
        EmailMedia,
    },
    props: {
        item: Object,
    },
};
</script>
