import Vue from 'vue';
import RenderComponent from '../components/RenderComponent.vue';
import RenderEmailComponent from '../components/email/RenderEmailComponent.vue';
import Adder from '../components/editor/Adder.vue';
import DragHandle from '../components/editor/DragHandle.vue';

Vue.component('RenderComponent', RenderComponent);
Vue.component('RenderEmailComponent', RenderEmailComponent);
Vue.component('Adder', Adder);
Vue.component('DragHandle', DragHandle);
