var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isPreview || _vm.attrs.media)?_c('div',{staticClass:"media-item-container"},[_c(_vm.href ? 'a' : 'div',{tag:"Component",staticClass:"media-item",class:_vm.classList,attrs:{"id":_vm.elementID,"href":_vm.href,"target":_vm.attrs.open_new_tab ? '_blank' : undefined,"download":_vm.attrs.on_click === 'download'},on:{"click":_vm.doClick}},[_c('DragHandle'),_vm._v(" "),(_vm.showMedia)?_c('MediaLoader',_vm._b({},'MediaLoader',{
                height: _vm.height,
                width: _vm.width,
                alt: _vm.alt,
                quality: _vm.quality,
                media: _vm.media,
                thumb: _vm.thumb,
                lazy: _vm.lazy,
                videoDisplay: _vm.attrs.video_display,
            },false)):_c('PlaceholderImage'),_vm._v(" "),_vm._t("default")],2),_vm._v(" "),_c('Adder',{attrs:{"component-data":_vm.componentData.parentData,"child-u-u-i-d":_vm.componentData.uuid}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }