export const state = () => ({
    editing: null,
    active: null,
});

export const getters = {
    editing(state) {
        return state.editing;
    },
    active(state) {
        return state.active;
    },
};

export const mutations = {
    setEditing(state, modalID) {
        state.editing = modalID;
    },
    setActive(state, modalID) {
        console.log('inside modals store, setting active: ', modalID);
        state.active = modalID;
    },
};
