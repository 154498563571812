<template>
    <div :id="elementID" :class="classList" class="card-body">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'CardBody',
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
            expanded: false,
        };
    },
    computed: {
        classList() {
            return [
                this.attrs ? this.attrs.element_class : '',
                this.expanded ? 'expanded' : '',
                // this.helperClasses
            ];
        },
        elementID() {
            return this.attrs ? this.attrs.element_id : '';
        },
    },
};
</script>
