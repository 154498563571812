<template>
    <div class="tabs-container">
        <b-tabs
            :id="elementID"
            class="tabs"
            content-class="mt-3"
            :class="classList"
        >
            <slot></slot>
        </b-tabs>

        <Adder
            :component-data="componentData.parentData"
            :child-u-u-i-d="componentData.uuid"
        />
    </div>
</template>

<script>
import Adder from './editor/Adder';
export default {
    name: 'Tabs',
    components: {
        Adder,
    },
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
        };
    },
    computed: {
        classList() {
            if (!this.attrs) return [];
            return [this.attrs.element_class || ''];
        },
        elementID() {
            if (!this.attrs) return;
            return this.attrs.element_id || '';
        },
    },
};
</script>

<style lang="scss" scoped>
.tabs-container {
    display: flex;
    flex-direction: column;
}
</style>
