<template>
    <div slot="marker" class="marker">
        <a
            class="icon-container"
            v-bind="{ href, target, download }"
            :style="style"
            :class="[
                attrs.pulse ? 'pulse' : '',
                hotspotSize,
                surrounding ? 'surrounding' : '',
            ]"
            draggable="false"
            @click="doClickAction"
            @dragstart.prevent="() => false"
        >
            <span>
                <template v-if="['icon', 'favourite'].includes(iconType)">
                    <img
                        v-if="
                            iconType === 'favourite' && iconImg && iconImg.url
                        "
                        :src="iconImg.url"
                        width="18"
                        height="18"
                        style="pointer-event: none"
                        draggable="false"
                        @dragstart.prevent="() => false"
                    />

                    <font-awesome-icon
                        v-else-if="attrs.icon_value"
                        :icon="['fas', attrs.icon_value]"
                        size="lg"
                        :style="{
                            color: getColor(attrs.icon_inner_color || 'light'),
                        }"
                    />
                </template>

                <span
                    v-else-if="iconType === 'text'"
                    :style="{
                        color: getColor(attrs.icon_inner_color || 'light'),
                    }"
                >
                    {{ attrs.icon_text }}
                </span>

                <span v-else-if="iconType === 'image' && iconImg">
                    <img
                        v-if="
                            customIcons
                                .map((ci) => ci.title)
                                .includes(iconImg.title)
                        "
                        :src="iconImg.url"
                        height="16"
                        width="16"
                        style="pointer-event: none"
                        draggable="false"
                        @dragstart.prevent="() => false"
                    />

                    <IconImage
                        v-else-if="iconImg && iconImg.id"
                        :media-i-d="iconImg.id"
                        style="pointer-event: none"
                        draggable="false"
                        @dragstart.prevent="() => false"
                    />

                    <img
                        v-else-if="iconImg && iconImg.url"
                        :src="iconImg.url"
                        width="18"
                        height="18"
                        style="pointer-event: none"
                        draggable="false"
                        @dragstart.prevent="() => false"
                    />
                </span>
            </span>
        </a>

        <div
            v-if="label && label.length > 0"
            class="label-container"
            :class="attrs.label_pos"
        >
            <div
                class="label"
                :class="[attrs.label_pos, attrs.label_display]"
                v-html="label"
            ></div>
        </div>
    </div>
</template>

<script>
import IconImage from '../IconImage';
import actions from '../../mixins/actions';
import onClickActions from '@/mixins/onClickActions';

export default {
    name: 'MarkerContent',
    components: {
        IconImage,
    },
    mixins: [actions, onClickActions],
    props: {
        uuid: { type: String },
        marker: { type: Object, default: null },
        mapDisplay: {
            type: Boolean,
            default: false,
        },
        surrounding: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            resetting: false,
        };
    },
    computed: {
        customIcons() {
            return this.$store.getters['api/customIcons'];
        },
        label() {
            if (this.attrs.label) {
                return this.attrs.label;
            } else {
                return this.attrs.title
                    ? `<h6 class='mb-1'><strong>
                        ${this.attrs.title}
                        </strong></h6><p>${this.attrs.description}</p>`
                    : '';
            }
        },
        attrs() {
            return this.marker?.properties ?? this.marker?.props ?? {};
        },
        style() {
            return {
                backgroundColor: this.getColor(this.attrs.color),
                borderColor: this.getColor(
                    this.attrs.icon_inner_color || 'light',
                ),
            };
        },
        icon() {
            return this.attrs.icon_value;
        },
        iconType() {
            return this.attrs.icon_type;
        },
        iconImg() {
            return this.attrs.icon_img;
        },
        /* eslint-disable vue/no-side-effects-in-computed-properties */
        labelDisplay() {
            this.resetting = true;
            // eslint-disable-next-line vue/no-async-in-computed-properties
            setTimeout(() => {
                this.resetting = false;
            }, 1000);
            return this.attrs.label_display;
        },
        /* eslint-enable vue/no-side-effects-in-computed-properties */
        hotspotSize() {
            return this.attrs.hotspot_size;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~/assets/scss/animations.scss';

img {
    max-width: 100%;
}

.marker {
    -webkit-animation: fadein 0.6s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 0.6s; /* Firefox < 16 */
    -ms-animation: fadein 0.6s; /* Internet Explorer */
    -o-animation: fadein 0.6s; /* Opera < 12.1 */
    animation: fadein 0.6s;
    padding: 0;
    margin: 0;
    cursor: pointer;
    opacity: 1;
    pointer-events: all;

    &:hover {
        div.label-container > div.label.hover {
            opacity: 1;
        }
    }

    &.hide-marker {
        opacity: 0;
        pointer-events: none;
    }

    &.show-on-hover {
        .label-container {
            opacity: 0;
            transition: 0.5s ease;
        }

        &:hover {
            .label-container {
                opacity: 1;
            }
        }
    }

    &.hide-label {
        opacity: 0;
        transition: 0.5s ease;
    }

    .label-container {
        position: absolute;
        top: 0;
        left: 40px;
        display: flex;
        min-height: 100px;
        width: 250px;
        align-items: center;
        pointer-events: none;

        &.right {
            .label {
                align-self: flex-start;
            }
        }

        &.left {
            left: -256px;
            justify-content: flex-end;
            .label {
                align-self: flex-start;
            }
        }

        &.top {
            top: -108px;
            justify-content: center;
            left: -110px;
            .label {
                align-self: flex-end;
            }
        }

        &.bottom {
            top: 36px;
            justify-content: center;
            left: -110px;
            .label {
                align-self: flex-start;
                text-align: center;
            }
        }

        .label {
            background: rgb(5, 18, 39);
            color: #fff;
            font-size: 12px;
            line-height: 14px;
            padding: 6px 8px;
            display: inline-block;
            border-radius: 5px;
            z-index: 2;
            transition: 0.3s ease;
            opacity: 0;

            &.never {
                opacity: 0;
            }

            &.always {
                opacity: 1;
            }

            &::after {
                font-family: FontAwesome;
                position: absolute;
                font-size: 20px;
                top: 8px;
                color: rgb(5, 18, 39);
            }
        }
    }

    a.icon-container {
        text-decoration: none;
        z-index: 2;
        border-radius: 50%;
        text-align: center;
        border-width: 2px;
        border-style: solid;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 32px;
        width: 32px;

        .svg-inline--fa {
            font-size: 16px;
        }

        &.xs {
            height: 20px;
            width: 20px;

            .svg-inline--fa {
                font-size: 8px;
            }
        }

        &.sm {
            height: 28px;
            width: 28px;

            .svg-inline--fa {
                font-size: 12px;
            }
        }

        &.lg {
            height: 36px;
            width: 36px;

            .svg-inline--fa {
                font-size: 20px;
            }
        }

        span {
            height: 100%;
            width: 100%;
            display: block;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &.pulse:after {
            content: '';
            position: absolute;
            border-radius: 50%;
            color: transparent;
            border: 3px solid #fff;
            animation: pulse 2s infinite;
        }

        &.surrounding {
            opacity: 0.5;
        }
    }
}
</style>

<style lang="scss">
div.mapboxgl-popup div.mapboxgl-popup-content {
    h6,
    p {
        margin-bottom: 0.25em !important;
    }
}
</style>
