<template>
    <div :id="elementID" :class="classList" class="campaingn-pre-footer">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'CampaignPreFooter',
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props ?? {},
        };
    },
    computed: {
        classList() {
            return [this.attrs.element_class];
        },
        elementID() {
            return this.attrs.element_id ?? '';
        },
    },
};
</script>

<style scoped>
.campaingn-pre-footer {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    margin-top: auto;
    background: #fff;
}
</style>
