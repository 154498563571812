<template>
    <div
        :id="elementID"
        :class="classList"
        :style="{
            backgroundColor: getColor(attrs.bg_color),
            color: getColor(attrs.text_color),
            borderColor: getColor(attrs.text_color),
            textAlign: 'center',
        }"
        class="campaign-footer p-3"
    >
        <slot></slot>
        <table
            cellpadding="8px"
            cellspacing="8px"
            :style="{
                border: 'none',
                width: '100%',
                margin: '0',
                borderSpacing: '0',
                backgroundColor: getColor(
                    campaignFooter ? campaignFooter.props.bg_color : 'dark',
                ),
                borderCollapse: 'separate',
            }"
        >
            <tr
                :style="{
                    backgroundColor: getColor(
                        campaignFooter ? campaignFooter.props.bg_color : 'dark',
                    ),
                }"
            >
                <td
                    :style="{
                        backgroundColor: getColor(
                            campaignFooter
                                ? campaignFooter.props.bg_color
                                : 'dark',
                        ),
                        color: getColor(
                            campaignFooter
                                ? campaignFooter.props.text_color
                                : 'light',
                        ),
                        fontSize: '12px',
                    }"
                    class="footer"
                >
                    <EmailCampaignFooter
                        v-if="campaignFooter"
                        :component-data="campaignFooter"
                    />

                    <p
                        :style="{
                            margin: '0 0 4px 0',
                            fontSize: '12px',
                            color: getColor(
                                campaignFooter
                                    ? campaignFooter.props.text_color
                                    : 'light',
                            ),
                        }"
                    >
                        {{ client.name }} - Information accurate as of date
                        published:
                        {{
                            $moment(content.custom_date || new Date())
                                .tz('Australia/Sydney')
                                .format('D MMM YYYY')
                        }}
                    </p>
                    <p
                        :style="{
                            margin: '0 0 4px 0',
                            fontSize: '12px',
                            color: getColor(
                                campaignFooter
                                    ? campaignFooter.props.text_color
                                    : 'light',
                            ),
                        }"
                    >
                        Date first published:
                        {{
                            $moment(content.first_published_at || new Date())
                                .tz('Australia/Sydney')
                                .format('D MMM YYYY')
                        }}
                    </p>
                    <p
                        :style="{
                            margin: '0 0 4px 0',
                            fontSize: '12px',
                            color: getColor(
                                campaignFooter
                                    ? campaignFooter.props.text_color
                                    : 'light',
                            ),
                        }"
                        class="unsubscribe"
                    >
                        <a
                            href="::unsubscribeUrl::"
                            :style="{
                                color: getColor(
                                    campaignFooter
                                        ? campaignFooter.props.text_color
                                        : 'light',
                                ),
                                'font-size': '12px',
                                'text-decoration': 'underline',
                            }"
                        >
                            Unsubscribe</a
                        >
                        from {{ project.name }} -
                        <a
                            href="https://communityanalytics.com.au/"
                            style=""
                            :style="{
                                color: getColor(
                                    campaignFooter
                                        ? campaignFooter.props.text_color
                                        : 'light',
                                ),
                                'font-size': '12px',
                                'text-decoration': 'underline',
                            }"
                        >
                            Powered by CA
                        </a>
                    </p>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
export default {
    name: 'CampaignFooter',
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
            campaignFooter: this.componentData,
        };
    },
    computed: {
        classList() {
            return [this.attrs.element_class];
        },
        elementID() {
            return this.attrs.element_id || '';
        },
        content() {
            return this.$store.state.content.active ?? {};
        },
        client() {
            return this.$store.state.api.client ?? {};
        },
        project() {
            return this.$store.state.api.project ?? {};
        },
    },
};
</script>

<style lang="scss">
.campaign-footer {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: center;

    p {
        margin-bottom: 0 !important;
        font-size: 12px !important;
    }
}
</style>
