<script>
import emailComponents from './index';

export default {
    name: 'RenderEmailComponent',
    components: {
        ...emailComponents,
    },
    props: {
        componentData: Object,
        workActivities: Array,
    },
    render(createElement) {
        const allChildren = this.componentData.children || [];
        const children = allChildren.filter((c) => {
            return c.show !== false;
        });

        const dataObj = {
            props: {
                componentData: this.componentData,
                workActivities:
                    this.componentData.name === 'WorkActivities'
                        ? this.workActivities
                        : null,
            },
        };

        if (this.componentData.show !== false) {
            return createElement(
                'Email-' + this.componentData.name,
                dataObj,
                children.map((child) => {
                    return createElement('render-email-component', {
                        props: {
                            componentData: child,
                        },
                    });
                }),
            );
        }
        return null;
    },
};
</script>
