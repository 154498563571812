<template>
    <span v-if="mediaURL" class="icon-image">
        <img class="card-img-top" :src="mediaURL" />
    </span>
</template>

<script>
export default {
    name: 'IconImage',
    props: ['mediaID'],
    data() {
        return {
            mediaURL: null,
        };
    },
    mounted() {
        this.fetchMedia();
    },
    methods: {
        async fetchMedia() {
            this.mediaURL = await this.$store
                .dispatch('api/getMedia', this.mediaID)
                .then((res) => res.data.url)
                .catch((err) => this.$sentry.captureException(err));
        },
    },
};
</script>

<style lang="scss" scoped>
.icon-image {
    max-width: 20px;
    max-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
