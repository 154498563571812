export default [
    {
        id: 'd8fc64da-d51a-46be-a5ff-487502a018e4',
        label: 'Day work',
        icon: 'sun',
    },
    {
        id: 'a54be388-288c-4b58-b89a-e00f61aad883',
        label: 'Day and night work',
        icon: 'eclipse',
    },
    {
        id: '141b3fe8-ba44-4f79-b9ac-e6bf73a101a1',
        label: 'Night work',
        icon: 'moon',
    },
    {
        id: '071bb86c-96cd-448e-9601-ed2e30efa5ab',
        label: 'Low noise',
        icon: 'volume-down',
    },
    {
        id: '9dbbfec0-fefc-4b3b-91a2-fcf29d426dbb',
        label: 'Moderate noise',
        icon: 'volume',
    },
    {
        id: '7afbcab0-86a6-46f3-b999-13eac5bd8d52',
        label: 'High noise',
        icon: 'volume-up',
    },
    {
        id: 'b497f2ba-6f94-446c-b923-51c1ed4aebc9',
        label: 'Pedestrian changes',
        icon: 'walking',
    },
    {
        id: '049c9cca-fbfb-48ed-b5b4-32a6cf8c02b0',
        label: 'Cyclist changes',
        icon: 'bicycle',
    },
    {
        id: 'c135c7f1-7665-4369-8faf-801fe586ae66',
        label: 'Traffic changes',
        icon: 'car',
    },
    {
        id: 'ede06a09-ff0f-4d4e-8d2c-f980e1834533',
        label: 'Tunnelling',
        icon: 'construction',
    },
];
