<template>
    <table
        cellpadding="0"
        cellspacing="0"
        border="0"
        width="100%"
        class="ca-heading"
        :align="attrs.align"
    >
        <tbody>
            <tr>
                <td
                    :id="attrs.element_id"
                    :style="{
                        paddingBottom: '16px',
                        color: getColor(attrs.text || 'dark'),
                        fontFamily: projectConfig.fontFamily,
                        textAlign: attrs.align,
                    }"
                    :class="attrs.element_class"
                    v-html="removeSpecialChars(componentData.value)"
                ></td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import removeSpecialChars from '~/utils/removeSpecialChars';
export default {
    name: 'Heading',
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props,
        };
    },
    methods: {
        removeSpecialChars,
    },
};
</script>
