var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{class:_vm.classList,style:(_vm.style),attrs:{"border":"0"}},[_c('tbody',[_c('tr',[_c('td',{style:({
                    height: ((_vm.attrs.height || 1) + "px "),
                    width: _vm.attrs.width + '%',
                    margin: 0,
                    'mso-line-height-rule': 'exactly',
                    'line-height': ((_vm.attrs.height || 1) + "px "),
                    'font-size': ((_vm.attrs.height || 1) + "px "),
                })},[_vm._v("\n                 \n            ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }