<template>
    <div
        :id="elementID"
        :class="classList"
        :style="{ backgroundColor: getColor(attrs.bg_color) }"
        class="campaign-header"
    >
        <template v-if="logoUrl">
            <Media
                v-if="attrs.full_width_logo"
                :component-data="{ props: { media: attrs.logo } }"
            ></Media>
            <img v-else :src="logoUrl" height="50px" width="auto" />
        </template>

        <div v-if="!attrs.full_width_logo">
            <h3
                v-if="attrs.heading"
                style="margin: 0; padding: 0; font-weight: 700; font-size: 24px"
                :style="{ color: getColor(attrs.text_color) }"
            >
                {{ headerData ? headerData.props.heading : attrs.heading }}
            </h3>

            <h6
                v-if="attrs.byline"
                :style="{ color: getColor(attrs.text_color) }"
                style="margin: 0; padding: 0; font-weight: 400; font-size: 14px"
            >
                <template v-if="attrs.uppercase_byline === false">
                    {{ headerData ? headerData.props.byline : attrs.byline }}
                </template>
                <template v-else>
                    {{
                        headerData
                            ? headerData.props.byline.toUpperCase()
                            : attrs.byline.toUpperCase()
                    }}
                </template>
            </h6>
        </div>
        <slot></slot>
    </div>
</template>

<script>
import Media from '~/components/Media';
export default {
    name: 'CampaignHeader',
    components: {
        Media,
    },
    props: {
        componentData: Object,
        headerData: Object,
    },
    computed: {
        attrs() {
            return this.componentData.props ?? {};
        },
        classList() {
            const classes = [this.attrs.element_class];
            if (!this.attrs.full_width_logo) {
                classes.push('p-3');
            }
            return classes;
        },
        elementID() {
            return this.attrs.element_id || '';
        },
        logoUrl() {
            return this.attrs.logo?.url;
        },
    },
};
</script>

<style lang="scss" scoped>
.campaign-header {
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    min-height: 80px;

    img {
        margin-right: 1rem;
    }

    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0 !important;
        font-weight: 900;
    }
}
</style>
