import props from './props';

export default {
    Scene: {
        title: props.title,
        media: props.media,
        logo: props.logo,
        pitch: props.pitch,
        yaw: props.yaw,
        hotspots: props.hotspots,
        pan_hotspots: props.hotspots,
    },
    hotspot: {
        label: 'Hotspot',
        options: {
            color: {
                label: 'Color',
                default: 'primary',
                type: 'Colorpicker',
            },
            title: {
                label: 'Title',
                default: '',
                type: 'String',
            },
            description: {
                label: 'Description',
                default: 'Description',
                type: 'Textarea',
            },
            icon_type: props.icon_type,
            icon_value: {
                ...props.icon,
                conditions: [['icon_type', '==', 'icon']],
            },
            icon_img: {
                ...props.icon_img,
                conditions: [['icon_type', '==', 'image']],
            },
            icon_text: {
                ...props.icon_text,
                conditions: [['icon_type', '==', 'text']],
            },
            label_pos: props.label_pos,
            label_display: props.label_display,
            hotspot_size: props.hotspot_size,
            pulse: props.pulse,
        },
    },
    panHotspot: {
        label: 'Hotspot',
        options: {
            title: props.title,
            description: {
                label: 'Description',
                default: 'Description',
                type: 'Textarea',
            },
            board_image: {
                label: 'Board image',
                ...props.media,
            },
            board_image_coords: {
                label: 'Board image coords',
                ...props.image_coords,
            },
            icon_type: props.icon_type,
            icon_value: {
                ...props.icon,
                conditions: [['icon_type', '==', 'icon']],
            },
            icon_img: {
                ...props.icon_img,
                conditions: [['icon_type', '==', 'image']],
            },
            icon_text: {
                ...props.icon_text,
                conditions: [['icon_type', '==', 'text']],
            },
            color: props.color,
            label_pos: props.label_pos,
            label_display: props.label_display,
            pulse: props.pulse,
            on_click: props.on_click,
            href: {
                ...props.href,
                conditions: [['on_click', '==', 'url']],
            },
            open_new_tab: {
                ...props.open_new_tab,
                conditions: [['on_click', '==', 'url']],
            },
            click_modal: {
                ...props.click_modal,
                conditions: [['on_click', '==', 'modal']],
            },
            click_download: {
                ...props.click_download,
                conditions: [['on_click', '==', 'download']],
            },
        },
    },
};
