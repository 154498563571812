<template>
    <div
        v-if="
            isPreview &&
            !previewMode &&
            !(
                componentData &&
                componentData.children &&
                componentData.children.length
            )
            // grandchildren are set in the render component
            // we don't need the adder since the children have adders
            // && !componentData.hasGrandChildren
        "
        v-click-outside="close"
        class="adder"
        :class="classList"
    >
        <!-- If it is a big adder (ie not small/inline)
        then render horizontal lines either side of the adder icon -->
        <div v-if="!small" class="line text-shadow" />

        <tippy arrow>
            <template #trigger>
                <div
                    :id="id"
                    class="icon-container text-shadow"
                    @click.stop="addComponent"
                >
                    <font-awesome-icon :icon="['far', 'plus']" />
                </div>
            </template>
            Add component after this
        </tippy>

        <div v-if="!small" class="line text-shadow" />
    </div>
</template>

<script>
/**
 * This Adder component is added to certain UI components in
 * ../components/... to instantly insert another component of
 * the same type
 */
export default {
    name: 'Adder',
    props: {
        uuid: { type: String, default: null },
        componentData: Object,
        firstIndex: Boolean, // whether to add before or after the component where this adder appears
        childUUID: { type: [String, null] },
        small: {
            // small is when it's inline (e.g. cards, gallery items)
            type: Boolean,
            default: true,
        },
        alignment: {
            // whether it should appear in a row/column, e.g. below or next to
            type: String,
            default: 'col',
        },
    },
    data() {
        return {
            id: 'adder-' + this.genUUID(),
            open: false,
        };
    },
    computed: {
        classList() {
            return [
                this.small ? 'small' : '',
                this.alignment === 'row' ? 'row' : 'column',
                this.open ? 'open' : '',
            ];
        },
    },
    mounted() {
        const vm = this;

        // Listen to root events
        this.$root.$on('componentAdded', () => {
            if (!this.small) {
                setTimeout(() => vm.close(), 10);
            }
        });
    },
    methods: {
        close() {
            // Small adders only have one option
            // e.g. accordion item, card, etc
            // therefore don't need to "open"
            // and show component options - will always
            // default to the only option available
            if (!this.small) {
                this.open = false;
            }
        },
        addComponent() {
            if (!this.small) {
                this.open = true;
            }

            // Emit event to Tree, so it can
            // add the component to the activeContent
            // json/metadata and refresh the preview
            this.$root.$emit(
                'editor:add',
                this.uuid ?? this.componentData?.uuid,
                this.id,
                this.childUUID,
                this.firstIndex,
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.adder {
    position: absolute;
    transition: 0.3s ease;
    opacity: 0.15;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    outline: none;

    bottom: 2px;
    right: 2px;

    z-index: 2;

    &:hover,
    &.open {
        opacity: 1;
    }

    .icon-container {
        width: 25px;
        height: 25px;
        // margin: 0.5rem;
        cursor: pointer;
        z-index: 999;
    }

    &.column {
        padding: 5px;
        // width: 100%;
        height: 10px;
        margin-top: -10px;

        height: 30px;
        margin-top: 0;
        margin-bottom: 0;
        padding: 0;
    }

    &.row {
        margin: 0 -25px;
        min-width: 50px;
        height: 100%;
        padding: 0;

        .line {
            display: none;
        }

        margin: 0 -0.5rem 0 0;
        width: 50px;
    }

    .line {
        transition: 0.3s ease;
        display: inline-block;
        flex-basis: 40%;
        flex-grow: 1;
        flex-shrink: 1;
        background: $primary;
        height: 1px;
    }

    .icon-container {
        outline: none;
        flex-grow: 0;
        flex-shrink: 0;
        text-align: center;
        align-self: center;
        cursor: pointer;
        background: $primary;
        border: 1px solid #cecece;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        .svg-inline--fa {
            color: $light;
        }
    }
}
</style>
