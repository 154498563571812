<template>
    <div>
        <b-form v-if="isPreview && !previewMode && componentData">
            <draggable
                v-model="
                    // eslint-disable-next-line vue/no-mutating-props
                    componentData.children
                "
                class="draggable"
                group="formSection"
                @change="refreshPreviewOrder(componentData.uuid)"
            >
                <h5>{{ attrs.title }}</h5>

                <slot></slot>
            </draggable>
        </b-form>

        <b-form v-else>
            <h5>{{ attrs.title }}</h5>

            <template v-for="(field, i) in filteredFields">
                <FormInput
                    v-if="field.name === 'FormInput'"
                    :key="i"
                    v-model="formData[field.id]"
                />
            </template>
        </b-form>
    </div>
</template>

<script>
import FormInput from './FormInput';

export default {
    name: 'FormSection',
    components: {
        FormInput,
    },
    props: {
        componentData: Object,
    },
    data() {
        const formData = {};
        this.componentData.children.forEach((field) => {
            formData[field.id] = null;
        });
        return {
            attrs: this.componentData.props || {},
            formData,
        };
    },
    computed: {
        filteredFields() {
            const vm = this;

            return vm.componentData.children;

            // return this.componentData.children.filter(field => {
            //     if (!field.props.conditions) {
            //         return true;
            //     } else {
            //         field.props.conditions.forEach(condition => {
            //             if (condition.type === 'AND') {
            //                 return condition.expressions.every(exp => {
            //                     const otherField = vm.componentData.children.find(
            //                         c => c.props.input_id === exp.input_id,
            //                     );

            //                     return `"${otherField.value}" ${exp.operator} "${exp.value}"`;
            //                 });
            //             }
            //         });
            //     }
            // });
        },
    },
};
</script>

<style lang="scss" scoped>
form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    h5 {
        margin: 0;
        font-weight: 700;
    }

    hr {
        width: 50px;
        margin: 1rem auto;
    }
}
</style>

<style lang="scss">
.form-group {
    padding: 0.25em;
    flex-basis: 100%;
    // flex-grow: 1;

    &.width-quarter {
        flex-basis: calc(25%);
    }

    &.width-third {
        flex-basis: calc(33%);
    }

    &.width-half {
        flex-basis: calc(50%);
    }

    &.width-two-thirds {
        flex-basis: calc(66%);
    }

    &.width-three-quarters {
        flex-basis: calc(75%);
    }

    &.width-full {
        flex-basis: calc(100%);
    }
}
</style>
