<template>
    <div>
        <table
            cellpadding="0"
            cellspacing="0"
            border="0"
            class="translation-services"
            :style="{
                margin: '0',
                borderSpacing: '16px',
                borderCollapse: 'separate',
            }"
        >
            <tbody>
                <tr>
                    <td :style="{ textAlign: 'center' }">
                        <img
                            src="https://ca-v2.s3-ap-southeast-2.amazonaws.com/Interpreter-Symbol-with-text.png"
                            width="80"
                            height="80"
                            alt="Translation services"
                        />
                    </td>

                    <td
                        :style="{
                            textAlign: 'left',
                            borderTopWidth: '1px',
                            borderTopStyle: 'solid',
                            borderTopColor: 'inherit',
                            borderBottomWidth: '1px',
                            borderBottomStyle: 'solid',
                            borderBottomColor: 'inherit',
                        }"
                    >
                        <p
                            :style="{
                                fontFamily: projectConfig.fontFamily,
                                color: 'inherit',
                                borderLeft: '16px solid rgba(0,0,0,0)',
                            }"
                            v-html="attrs.text"
                        />
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: 'Translation',
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
        };
    },
};
</script>

<style lang="scss" scoped>
.translation-services p {
    margin-bottom: 0 !important;
}
</style>
