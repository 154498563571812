<template>
    <div class="accordion-container">
        <div>
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Accordion',
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props,
        };
    },
};
</script>
