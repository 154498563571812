import { isString, isEmpty } from 'lodash-es';

// Backwards compatibility for old formats for category filters (string, array of objects)
export default function convertCategoryFilters(filters) {
    // Null or empty string should return an empty array
    if (isEmpty(filters)) {
        return [];
    }
    // If format of Filters are string, split by ', ' and return array (e.g. 'filter1, filter2' => ['filter1', 'filter2'])
    if (isString(filters)) {
        return filters.split(', ');
    }

    // If format of Filters are array of objects, filter out any null values and return array of codes
    // eg: [{ code: 'filter1', label: 'filter1'}, { code: 'filter2', label: 'filter2' }] => ['filter1', 'filter2']
    if (Array.isArray(filters)) {
        return filters
            .filter((val) => typeof val === 'string' || val.code !== null)
            .map((val) => {
                if (typeof val === 'string') {
                    return val;
                }
                return val.code;
            });
    }
    // If format of Filters are not string or array of objects all previous checks failed, throw error
    throw new TypeError('Input cannot be converted to category filters.');
}
