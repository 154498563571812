<template>
    <div
        :id="elementID"
        :class="classList"
        class="card-body"
        @click="doClickAction"
    >
        <draggable
            v-if="isPreview && !previewMode"
            v-model="
                // eslint-disable-next-line vue/no-mutating-props
                componentData.children
            "
            handle=".drag-handle"
            group="cardBody"
            @change="refreshPreviewOrder(componentData.uuid)"
        >
            <slot></slot>
        </draggable>

        <slot v-else></slot>
    </div>
</template>

<script>
// import helpers from '../mixins/helpers';
export default {
    name: 'CardBody',
    // mixins: [helpers],
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
            expanded: false,
        };
    },
    computed: {
        classList() {
            return [
                this.attrs ? this.attrs.element_class : '',
                this.expanded ? 'expanded' : '',
                // this.helperClasses
            ];
        },
        elementID() {
            return this.attrs ? this.attrs.element_id : '';
        },
    },
};
</script>

<style lang="scss" scoped>
.card-body {
    padding: 0.5rem 1rem;
    cursor: auto;
    transition: 0.3s ease;
    // overflow-y: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 100%;

    & > * {
        width: 100%;
    }
}
</style>
