<template>
    <div :id="elementID" class="columns row" :class="classList" :style="style">
        <slot></slot>
        <Adder :component-data="componentData" />
    </div>
</template>

<script>
import helpers from '../mixins/helpers';
import Adder from './editor/Adder';
import componentInlineStyle from '~/utils/componentInlineStyle';

export default {
    name: 'Columns',
    components: {
        Adder,
    },
    mixins: [helpers],
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
        };
    },
    computed: {
        style() {
            return {
                ...componentInlineStyle(this.attrs, this.getColor),
            };
        },
        classList() {
            return [
                ...this.helperClasses,
                'cols-' + this.attrs.col_span,
                this.attrs.element_class,
            ];
        },
        elementID() {
            return this.attrs.element_id || '';
        },
    },
};
</script>

<style lang="scss">
.columns {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    position: relative;
    justify-content: stretch;
    width: 100%;
    margin: 0;

    & > .column {
        flex-grow: 0 !important;
        min-width: 250px;
        margin: 0;
    }

    &.cols-2 > *:not([class*='col-']) {
        flex-basis: 50%;
    }

    &.cols-3 > *:not([class*='col-']) {
        flex-basis: 33%;
    }

    &.cols-4 > *:not([class*='col-']) {
        flex-basis: 25%;
    }
}
</style>
