<template>
    <div class="widget-container">
        <RenderContent
            v-if="attrs.content_id && !resetting"
            :content-i-d="attrs.content_id"
        />
    </div>
</template>

<script>
import RenderContent from '../components/RenderContent';
export default {
    name: 'WidgetLoader',
    components: { RenderContent },
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props,
            resetting: false,
        };
    },
    computed: {
        contentID() {
            return this.attrs.content_id;
        },
    },
    watch: {
        contentID(next, prev) {
            this.resetting = true;

            setTimeout(() => {
                this.resetting = false;
            }, 100);
        },
    },
};
</script>

<style lang="scss" scoped>
div.widget-container {
    display: flex;
    flex-direction: column;
}
</style>
