// CURRENTLY NOT IN USE

const actions = {
    computed: {
        actions() {
            return this.$store.state.api.actions;
        },
    },
    methods: {
        doPageAction(action) {
            const vm = this;

            if (action.type === 'showModal') {
                // CUSTOM MODAL
                if (action.value && action.value !== '') {
                    vm.$store.commit('modals/setActive', action.value);

                    // GENERIC MODAL
                } else {
                    const data = {
                        title: action.options.title,
                        media_id: action.options.media_id,
                    };
                    vm.$store.commit('setGenericModal', data);
                }
            } else if (action.type === 'externalURL') {
                window.location.href = action.value;
            } else if (action.type === 'download') {
                window.location.href = action.value;
            } else if (action.type === 'scrollTo') {
                const el = document.querySelector(action.value);
                if (el) {
                    el.scrollIntoView({ behavior: 'smooth' });
                }
            }
        },
        async doMapAction(action, mapComponent = null) {
            const vm = this;

            if (!mapComponent) {
                mapComponent = vm.$root.$refs[action.target.id];
            }

            if (!mapComponent) {
                // If map action, wait for the map to load
                const mapLoaded = new Promise((resolve, reject) => {
                    const waitForMapToLoad = setInterval(() => {
                        const map = vm.$root.$refs[action.target.id];
                        if (map) {
                            // Clear the interval
                            window.clearInterval(waitForMapToLoad);
                            resolve(map);
                        }
                    }, 500);
                });

                mapComponent = await mapLoaded;
            }

            if (action.type === 'groupActions') {
                action.actions.forEach((a) => vm.doMapAction(a, mapComponent));
            } else if (
                [
                    'flyTo',
                    'flyToFit',
                    'changeFilter',
                    'showLayers',
                    'resetMap',
                ].includes(action.type)
            ) {
                mapComponent.$emit('handleAction', action);
            } else if (action.type === 'showMarkerPopup') {
                const marker = mapComponent.$children.find(
                    (c) => c.$attrs.id === action.value,
                );
                if (marker) marker.togglePopup();
            } else if (action.type === 'setMapSidebar') {
                const data = {
                    map_id: action.target.id,
                    sidebar_id: action.value,
                };

                vm.$store.commit('maps/setSidebar', data);
            } else if (action.type === 'setMapKeys') {
                const data = {
                    map_id: action.target.id,
                    keyIDs: action.value,
                };

                vm.$store.commit('maps/setKeys', data);
            }
        },
        doAction(id, e = null) {
            const vm = this;

            if (e && id) {
                e.preventDefault();
            }

            const action = vm.getActionFromID(id);

            if (action) {
                // If on the right page, do secondary actions
                if (
                    !action.primary.value ||
                    [this.$route.path, this.$store.state.editorPage].includes(
                        action.primary.value,
                    ) ||
                    this.$route.path === '/work-activity'
                ) {
                    for (let i = 0; i < action.secondary.length; i++) {
                        action.secondary[i].target.type === 'map'
                            ? vm.doMapAction(action.secondary[i])
                            : vm.doPageAction(action.secondary[i]);
                    }

                    // Otherwise, go the right page
                } else {
                    vm.$router.push({
                        path: action.primary.value,
                        query: {
                            a: action.id || null,
                        },
                    });
                }
            }
        },
        getActionFromID(id) {
            return this.$store.state.api.actions.find(
                (action) => action.id === id,
            );
        },
        getTopicFromID(id) {
            return this.$store.state.api.topics.find(
                (topic) => topic.id === id,
            );
        },
    },
};

export default actions;
