var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"marker",attrs:{"slot":"marker"},slot:"marker"},[_c('a',_vm._b({staticClass:"icon-container",class:[
            _vm.attrs.pulse ? 'pulse' : '',
            _vm.hotspotSize,
            _vm.surrounding ? 'surrounding' : '' ],style:(_vm.style),attrs:{"draggable":"false"},on:{"click":_vm.doClickAction,"dragstart":function($event){$event.preventDefault();return (function () { return false; }).apply(null, arguments)}}},'a',{ href: _vm.href, target: _vm.target, download: _vm.download },false),[_c('span',[(['icon', 'favourite'].includes(_vm.iconType))?[(
                        _vm.iconType === 'favourite' && _vm.iconImg && _vm.iconImg.url
                    )?_c('img',{staticStyle:{"pointer-event":"none"},attrs:{"src":_vm.iconImg.url,"width":"18","height":"18","draggable":"false"},on:{"dragstart":function($event){$event.preventDefault();return (function () { return false; }).apply(null, arguments)}}}):(_vm.attrs.icon_value)?_c('font-awesome-icon',{style:({
                        color: _vm.getColor(_vm.attrs.icon_inner_color || 'light'),
                    }),attrs:{"icon":['fas', _vm.attrs.icon_value],"size":"lg"}}):_vm._e()]:(_vm.iconType === 'text')?_c('span',{style:({
                    color: _vm.getColor(_vm.attrs.icon_inner_color || 'light'),
                })},[_vm._v("\n                "+_vm._s(_vm.attrs.icon_text)+"\n            ")]):(_vm.iconType === 'image' && _vm.iconImg)?_c('span',[(
                        _vm.customIcons
                            .map(function (ci) { return ci.title; })
                            .includes(_vm.iconImg.title)
                    )?_c('img',{staticStyle:{"pointer-event":"none"},attrs:{"src":_vm.iconImg.url,"height":"16","width":"16","draggable":"false"},on:{"dragstart":function($event){$event.preventDefault();return (function () { return false; }).apply(null, arguments)}}}):(_vm.iconImg && _vm.iconImg.id)?_c('IconImage',{staticStyle:{"pointer-event":"none"},attrs:{"media-i-d":_vm.iconImg.id,"draggable":"false"},on:{"dragstart":function($event){$event.preventDefault();return (function () { return false; }).apply(null, arguments)}}}):(_vm.iconImg && _vm.iconImg.url)?_c('img',{staticStyle:{"pointer-event":"none"},attrs:{"src":_vm.iconImg.url,"width":"18","height":"18","draggable":"false"},on:{"dragstart":function($event){$event.preventDefault();return (function () { return false; }).apply(null, arguments)}}}):_vm._e()],1):_vm._e()],2)]),_vm._v(" "),(_vm.label && _vm.label.length > 0)?_c('div',{staticClass:"label-container",class:_vm.attrs.label_pos},[_c('div',{staticClass:"label",class:[_vm.attrs.label_pos, _vm.attrs.label_display],domProps:{"innerHTML":_vm._s(_vm.label)}})]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }