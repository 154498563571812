<template>
    <div class="block-quote-container">
        <DragHandle />

        <p
            :id="elementID"
            class="block-quote ca-component ca-blockquote"
            :style="style"
            :class="classList"
            @click="doClickAction"
        >
            {{ componentData.value }}
        </p>

        <Adder
            :component-data="componentData.parentData"
            :child-u-u-i-d="componentData.uuid"
        />
    </div>
</template>

<script>
import helpers from '../mixins/helpers';
import Adder from './editor/Adder';
import DragHandle from './editor/DragHandle';
export default {
    name: 'BlockQuote',
    components: {
        Adder,
        DragHandle,
    },
    mixins: [helpers],
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
        };
    },
    computed: {
        classList() {
            return [
                'text-' + this.attrs.align,
                this.attrs.element_class || '',
                ...this.helperClasses,
            ];
        },
        style() {
            return {
                borderLeft:
                    '4px solid ' +
                    (this.getColor(this.attrs.border_color) || '#cecece'),
                fontFamily: this.projectConfig.fontFamily,
            };
        },
        elementID() {
            return this.attrs.element_id || '';
        },
    },
};
</script>

<style lang="scss" scoped>
.block-quote-container {
    display: flex;
    flex-direction: column;
    position: relative;

    &:hover {
        .drag-handle {
            opacity: 1;
        }
    }
}
.block-quote {
    padding-left: 1rem;
}
</style>
