<template>
    <b-form-group v-slot="{ ariaDescribedby }" :label="label">
        <b-form-radio-group
            :stacked="!attrs.inline"
            :aria-describedby="ariaDescribedby"
            :name="attrs.id"
        >
            <b-form-radio
                v-for="(option, i) in attrs.options"
                :key="i"
                v-model="value"
                :value="option.value"
            >
                {{ option.label }}
            </b-form-radio>
        </b-form-radio-group>
    </b-form-group>
</template>

<script>
export default {
    name: 'FormRadio',
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
            value: '',
        };
    },
    computed: {
        label() {
            return this.attrs.label + (this.attrs.required ? ' *' : '');
        },
    },
    mounted() {
        if (!this.attrs.id) {
            this.attrs.id = 'input_' + this.genUUID();
        }
    },
};
</script>

<style lang="scss">
.form-input {
    .red {
        color: red;
    }
}
</style>
