var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{attrs:{"border":"0","width":"100%","cellpadding":"0","cellspacing":"0"}},[_c('tr',[_c('td',{style:(_vm.spacingStyle)},[_vm._v(" ")])]),_vm._v(" "),_c('tr',[_c('td',{style:({
                background: 'none',
                borderBottom: ((_vm.attrs.thickness || 1) + "px solid " + (_vm.getColor(
                    _vm.attrs.color
                ))),
                height: ((_vm.attrs.thickness || 1) + "px "),
                width: '100%',
                margin: 0,
                'mso-line-height-rule': 'exactly',
                'line-height': ((_vm.attrs.thickness || 1) + "px "),
                'font-size': ((_vm.attrs.thickness || 1) + "px "),
            })},[_vm._v("\n            ​\n        ")])]),_vm._v(" "),_c('tr',[_c('td',{style:(_vm.spacingStyle)},[_vm._v(" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }