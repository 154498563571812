import _ from 'lodash';
import { Model } from 'vue-api-query';
import { PAGES_WITHOUT_AUTH } from '~/utils/constants/auth';

export default function ({ req, $axios, route }) {
    // Initial setup (start of the server)
    if (_.isNil(Model.$http)) {
        Model.$http = $axios;
        console.log(`vue-api-query - Setting $http to $axios`);
    }
    // Check if we need to set the authToken in the axios client (that is shared between all the models)
    try {
        // If the page is generated (process.server === true) => the current page path is retrieve by route?.path
        // If the page is dynamically rendered (process.server === false) => the current page path is retrieve by req?.url
        if (
            (process.server && !PAGES_WITHOUT_AUTH.includes(route?.path)) ||
            (!process.server && !PAGES_WITHOUT_AUTH.includes(req?.url))
        ) {
            // Check if the token expired (custome rule here, we want to expire the token after 24h, even if the token is still valid for the API)
            if (
                _.isNil(Model.HTTPClientBearerTokenExpirity) ||
                new Date() > Model.HTTPClientBearerTokenExpirity
            ) {
                // Set the expiration date to 24 hours
                Model.HTTPClientBearerTokenExpirity = new Date(
                    new Date().getTime() + 24 * 60 * 60 * 1000,
                );
                console.log(
                    `vue-api-query - Setting Model.HTTPClientBearerTokenExpirity`,
                );
            }
        }
    } catch (err) {
        const sentryErr = Error(
            'vue-api-query - Failed setting authToken - ',
            err.message,
        );
        console.error(
            'vue-api-query  - Failed setting authToken - ',
            err.message,
        );
        this?.$sentry.captureException(sentryErr);
    }
}
