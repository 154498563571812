<template>
    <MessageCard
        heading="Something went wrong"
        :subheading="error.statusCode"
        :description="error.message"
    >
        <b-row no-gutters>
            <b-col>
                <b-btn
                    href="javascript:location.reload();"
                    large
                    block
                    color="primary"
                    >Try again</b-btn
                >
            </b-col>
        </b-row>
        <!-- <b-row>
            <b-col>
                <b-btn to="/" exact large block color="primary">{{
                    'Home'
                }}</b-btn>
            </b-col>
        </b-row> -->
    </MessageCard>
</template>

<script>
import MessageCard from '~/components/MessageCard.vue';
export default {
    components: {
        MessageCard,
    },
    props: {
        error: {
            type: Object,
            default: () => {},
        },
    },
};
</script>
