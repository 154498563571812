<template>
    <div class="block-quote-container">
        <p
            :id="elementID"
            class="block-quote"
            :style="{
                borderLeft:
                    '4px solid ' + (getColor(attrs.border_color) || '#cecece'),
            }"
            :class="classList"
        >
            {{ componentData.value }}
        </p>
    </div>
</template>

<script>
export default {
    name: 'BlockQuote',
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
        };
    },
};
</script>
