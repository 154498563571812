<template>
    <span>
        <ModalGeneric
            v-if="genericModal"
            :title="genericModal.title"
            :media-i-d="genericModal.media_id"
        />

        <render-component v-if="activeModal" :component-data="activeModal" />
    </span>
</template>

<script>
import ModalGeneric from '../components/ModalGeneric';

export default {
    name: 'RenderModal',
    components: {
        ModalGeneric,
    },
    data() {
        return {
            activeModal: null,
        };
    },
    computed: {
        genericModal() {
            return this.$store.state.genericModal;
        },
        activeModalID() {
            return this.$store.getters['modals/active'];
        },
    },
    watch: {
        activeModalID(modalID, prev) {
            if (!this.activeModalID) {
                this.activeModal = null;
            } else {
                const vm = this;

                this.$store.dispatch('api/getContent', modalID).then((res) => {
                    vm.activeModal = res.metadata;
                });
            }
        },
    },
    mounted() {
        console.log('render modal mounted');
    },
};
</script>
