import Model from './Model';
import Content from './Content';

export default class Project extends Model {
    resource() {
        return 'projects';
    }

    content() {
        this.hasMany(Content);
    }
}
