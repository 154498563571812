<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        :viewBox="`0 0 ${width} ${height}`"
        class="placeholder-img"
        style="cursor: pointer"
    >
        <rect v-bind="{ width, height }" :fill="backgroundColor"></rect>
        <slot>
            <text
                x="50%"
                y="50%"
                dominant-baseline="middle"
                text-anchor="middle"
                font-family="monospace"
                font-size="91px"
                fill="#aaaaaa"
            >
                {{ width }}x{{ height }}
            </text>
            <text
                x="50%"
                y="65%"
                dominant-baseline="middle"
                text-anchor="middle"
                font-family="monospace"
                font-size="51px"
                fill="#eb4324"
            >
                {{ text }}
            </text>
        </slot>
    </svg>
</template>

<script>
export default {
    props: {
        width: { type: Number, default: 1080, required: false },
        height: { type: Number, default: 720, required: false },
        backgroundColor: { type: String, default: '#eeeeee' },
        text: { type: String, default: 'Click to add content' },
    },
};
</script>
