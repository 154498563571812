<template>
    <b-modal v-model="show" :title="title" size="lg" @hidden="hide()">
        <!-- MEDIA -->

        <template v-if="media">
            <div v-if="media.type === 'image'" class="card-thumb">
                <img class="card-img-top" :src="media.url + '?p=thumb'" />
            </div>

            <!-- <compare-slider v-if='media.type === "slider"'
                :componentData="{ props: media.props }">
            </compare-slider> -->

            <div
                v-if="media.type === 'video'"
                class="embed-responsive embed-responsive-16by9"
            >
                <iframe
                    class="embed-responsive-item"
                    :src="media.url"
                    :autoplay="media.props ? media.props.autoplay : false"
                    width="1920"
                    height="1080"
                    frameborder="0"
                    webkitallowfullscreen
                    mozallowfullscreen
                    allowfullscreen
                ></iframe>
            </div>
        </template>
    </b-modal>
</template>

<script>
// import CompareSlider from './CompareSlider';

export default {
    name: 'ModalGeneric',
    components: {
        // CompareSlider,
    },
    props: {
        mediaID: String,
        title: String,
    },
    data() {
        return {
            media: null,
            show: true,
        };
    },
    mounted() {
        this.fetchMedia();
    },
    methods: {
        hide() {
            this.$store.commit('setGenericModal', null);
        },
        async fetchMedia() {
            this.media = await this.$store
                .dispatch('api/getMedia', this.mediaID)
                .then((res) => res.data);
        },
    },
};
</script>
