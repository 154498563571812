<template>
    <b-form-group
        :id="attrs.id"
        :label="label"
        :label-for="'input_' + attrs.id"
        :class="'width-' + attrs.width"
    >
        <b-form-input
            :id="'input_' + attrs.id"
            v-model="value"
            :type="attrs.type"
            :min="attrs.min_range || null"
            :max="attrs.max_range || null"
            :step="attrs.step || null"
            :placeholder="attrs.placeholder"
            :required="attrs.required"
        />
    </b-form-group>
</template>

<script>
export default {
    name: 'FormInput',
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
            value: '',
        };
    },
    computed: {
        label() {
            return this.attrs.label + (this.attrs.required ? ' *' : '');
        },
    },
    mounted() {
        if (!this.attrs.id) {
            this.attrs.id = 'field_' + this.genUUID();
        }
    },
};
</script>

<style lang="scss">
.form-input {
    margin: 0.25em;
}
</style>
