<template>
    <b-dropdown-item v-bind="{ href, target, download }" @click="doClickAction">
        <font-awesome-icon
            v-if="attrs.icon && attrs.icon_pos === 'left'"
            class="mr-1"
            :icon="['far', attrs.icon]"
        />
        {{ attrs.label }}
        <font-awesome-icon
            v-if="attrs.icon && attrs.icon_pos === 'right'"
            class="ml-1"
            :icon="['far', attrs.icon]"
        />
    </b-dropdown-item>
</template>

<script>
import onClickActions from '~/mixins/onClickActions';
export default {
    name: 'NavDropdownItem',
    mixins: [onClickActions],
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
        };
    },
};
</script>
