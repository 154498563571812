var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{style:({
        borderSpacing: '16px',
        verticalAlign: 'top',
        border: '1px solid #f3f6f8',
    })},[_c('EmailMedia',{attrs:{"component-data":{
            parentData: {
                name: 'GalleryItem',
            },
            props: {
                media: _vm.item.props.media,
            },
        }}}),_vm._v(" "),(_vm.item.props.caption)?_c('div',[_c('div',{staticStyle:{"margin":"12px"}},[_c('p',{style:({ fontFamily: _vm.projectConfig.fontFamily })},[_vm._v("\n                "+_vm._s(_vm.item.props.caption)+"\n            ")])])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }