<template>
    <div class="page-section-container">
        <div
            :id="elementID"
            class="page-section"
            :class="classList"
            :style="style"
            @click="doClickAction"
        >
            <DragHandle />
            <div
                :class="
                    attrs.width === 'fluid' ? 'container-fluid' : 'container'
                "
            >
                <draggable
                    v-if="isPreview && !previewMode"
                    v-model="
                        // eslint-disable-next-line vue/no-mutating-props
                        componentData.children
                    "
                    handle=".drag-handle"
                    group="pageSection"
                    @change="refreshPreviewOrder(componentData.uuid)"
                >
                    <slot></slot>
                </draggable>

                <slot v-else></slot>

                <Adder
                    v-if="
                        componentData.children &&
                        componentData.children.length === 0
                    "
                    :component-data="componentData"
                />
            </div>
        </div>

        <Adder
            :component-data="componentData.parentData"
            :child-u-u-i-d="componentData.uuid"
        />
    </div>
</template>

<script>
import helpers from '../mixins/helpers';
import Adder from './editor/Adder';
import DragHandle from './editor/DragHandle';

export default {
    name: 'PageSection',
    components: {
        Adder,
        DragHandle,
    },
    mixins: [helpers],
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
        };
    },
    computed: {
        style() {
            const styleObject = {
                minHeight:
                    this.attrs.height === 'auto' ? null : this.attrs.height,
                height: this.attrs.height === 'auto' ? 'auto' : null,
            };

            if (this.attrs.bg_type === 'media' && this.attrs.bg_media) {
                styleObject.backgroundImage = this.attrs.bg_media
                    ? 'url(' + this.attrs.bg_media.url + ')'
                    : null;
                styleObject.backgroundPosition = this.attrs.bg_pos || 'center';
            } else {
                styleObject.backgroundColor = this.getColor(
                    this.attrs.bg_color,
                );
            }

            return styleObject;
        },
        classList() {
            return [...(this.helperClasses || []), this.attrs.element_class];
        },
        elementID() {
            return this.attrs.element_id || '';
        },
    },
};
</script>

<style lang="scss" scoped>
div.page-section-container {
    display: flex;
    flex-direction: column;
}
.page-section {
    background-repeat: no-repeat;
    position: relative;
    max-width: 100%;
    min-height: 100px;

    .container-fluid,
    .container {
        padding-top: 2rem;
        padding-bottom: 2rem;
        position: relative;
    }

    @media screen and (max-width: 575px) {
        &.container {
            padding-left: 2rem;
            padding-right: 2rem;
        }
    }
}
</style>
