<template>
    <b-form-group v-slot="{ ariaDescribedby }" :label="label">
        <span class="form-drag-handle">
            <font-awesome-icon :icon="['far', 'expand-arrows']" />
        </span>

        <b-form-checkbox-group
            :stacked="!attrs.inline"
            :aria-describedby="ariaDescribedby"
            :name="attrs.id"
        >
            <b-form-checkbox
                v-for="(option, i) in attrs.options"
                :key="i"
                v-model="value"
                :value="option.value"
            >
                {{ option.label }}
            </b-form-checkbox>
        </b-form-checkbox-group>
    </b-form-group>
</template>

<script>
export default {
    name: 'FormCheckbox',
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
            value: '',
        };
    },
    computed: {
        label() {
            return this.attrs.label + (this.attrs.required ? ' *' : '');
        },
    },
    mounted() {
        if (!this.attrs.id) {
            this.attrs.id = 'input_' + this.genUUID();
        }
    },
};
</script>

<style lang="scss">
.form-group {
    position: relative;

    .form-drag-handle {
        // position:absolute;
        left: -20px;
        top: -30px;
        opacity: 0;
        transition: 0.3s ease;
        cursor: pointer;
    }

    &:hover {
        .form-drag-handle {
            opacity: 1;
        }
    }
}
</style>
