import Vue from 'vue';
import colorPicker from '@caohenghu/vue-colorpicker';

function testLocalStorage() {
    const test = 'test';
    try {
        localStorage.setItem(test, test);
        localStorage.removeItem(test);
        return true;
    } catch {
        return false;
    }
}

if (testLocalStorage()) {
    Vue.component('ColorPicker', colorPicker);
}
