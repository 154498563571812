<template>
    <a
        :id="elementID"
        :href="calendarLink"
        :style="style"
        class="btn scroll"
        :class="classList"
        @click="doClickAction"
    >
        <font-awesome-icon :icon="['fas', 'calendar']" />
        {{ componentData.value || 'Add to Calendar' }}
    </a>
</template>

<script>
export default {
    name: 'CalendarBtn',
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
            calendarLink: '/' + this.componentData.parentUUID,
        };
    },
    computed: {
        classList() {
            return [
                `btn-${this.attrs.size || 'md'}`,
                `text-${this.attrs.text || 'dark'}`,
                this.attrs.element_class || '',
                this.attrs.block ? 'btn-block' : '',
            ];
        },
        style() {
            return {
                backgroundColor: this.getColor(this.attrs.bg_color),
                fontFamily: this.projectConfig.fontFamily,
            };
        },
        elementID() {
            return this.attrs.element_id || '';
        },
    },
};
</script>

<style scoped>
a {
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
}
</style>
