export default async function getSlider(mediaId, $axios) {
    try {
        const {
            data: {
                data: { url },
            },
        } = await $axios.get(`/media/${mediaId}`);
        return url;
    } catch (e) {
        console.error(e);
    }
}
