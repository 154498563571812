import Vue from 'vue';
import { v4 as uuidv4 } from 'uuid';
import workActivityTopics from '../json/data/workActivityTopics';
import isUrlValid from '~/utils/isUrlValid';

export const state = () => ({
    projectID: null,
    apiClient: null,
    clientID: null,
    client: null,
    project: null,
    contentID: null,
    user: null,
    workActivities: null,
    pages: null,
    projectConfig: {
        workActivityTopics,
        // TODO this hardcoded config should be updated from penpal
        fontFamily: undefined,
        colors: {
            brand: '#143980',
            brand_secondary: '#007EBE',
            primary: '#1CACE4',
            tertiary: '#173B7E',
            danger: '#C60C30',
            orange: '#FD7F3D',
            pink: '#e83e8c',
            warning: '#F8BD2D',
            success: '#6CB238',
            purple: '#743388',
            dark: '#343a40',
            secondary: '#7F8388',
            light: '#F5F7F8',
        },
        brand: {
            alt: 'rozelle logo',
            src: 'https://ca-v2.s3-ap-southeast-2.amazonaws.com/tfnsw/wcxri-udlp/LogoLockUp-RI_WestConnex_JH_CPB.jpg',
        },
        favouriteIcons: [
            {
                icon: 'moon',
                color: 'brand',
            },
            {
                icon: 'sun',
                color: 'success',
            },
            {
                icon: 'car',
                color: 'orange',
            },
            {
                icon: 'water',
                color: 'primary',
            },
            {
                icon: 'bolt',
                color: 'danger',
            },
            {
                icon: 'bolt',
                color: 'orange',
            },
            {
                icon: 'question',
                color: 'pink',
            },
        ],
        icons: [
            'digging',
            'exclamation-triangle',
            'volume',
            'car',
            'bicycle',
            'walking',
            'search-plus',
            'ear-muffs',
            'bolt',
            'water',
            'traffic-cone',
        ],
        customIcons: [
            {
                title: 'icon-barrier',
                url: 'https://editor.caapp.com.au/icons/icon-barrier.png',
            },
            {
                title: 'icon-demolition',
                url: 'https://editor.caapp.com.au/icons/icon-demolition.png',
            },
            {
                title: 'icon-drill',
                url: 'https://editor.caapp.com.au/icons/icon-drill.png',
            },
            {
                title: 'icon-TBM',
                url: 'https://editor.caapp.com.au/icons/icon-TBM.png',
            },
            {
                title: 'telstra',
                url: 'https://editor.caapp.com.au/icons/telstra.png',
            },
        ],
    },
});

export const getters = {
    colors(state) {
        return state.projectConfig.colors;
    },
    projectConfig(state) {
        return state.projectConfig;
    },
    icons(state) {
        return state.projectConfig.icons;
    },
    customIcons(state) {
        return state.projectConfig.customIcons;
    },
    favouriteIcons(state) {
        return state.projectConfig.favouriteIcons;
    },
    clientID(state) {
        return state.clientID;
    },
    projectID(state) {
        return state.projectID;
    },
    clientName(state) {
        return state.client ? state.client.name : null;
    },
    apiClient(state) {
        return state.apiClient;
    },
    contentID(state) {
        return state.contentID;
    },
};

export const mutations = {
    setProject(state, data) {
        state.project = data;
    },
    setProjectID: (state, data) => {
        state.projectID = data;
    },
    setClientID: (state, data) => {
        state.clientID = data;
    },
    setClient: (state, data) => {
        state.client = data;
    },
    setApiClient: (state, config) => {
        state.apiClient = new Vue.prototype.$ApiClient(config);
    },
    updateConfig: (state, configData) => {
        state.projectConfig = { ...state.projectConfig, ...configData };
        globalThis.colours = state.projectConfig.colors;
    },
    setContentID: (state, data) => {
        state.contentID = data;
    },
    setUser(state, data) {
        state.user = data;
    },
    setWorkActivities(state, data) {
        state.workActivities = data;
    },
    setPages(state, data) {
        state.pages = data;
    },
};

export const actions = {
    async getConfig({ commit, state }, { projectId, clientId }) {
        const config = {};
        try {
            // Warringah Freeway
            if (projectId === '2089eadb-aaa0-4d7e-905d-638b80841253') {
                config.locations = ['Cammeray', 'North Sydney', 'Waverton'];
            } else {
                config.locations = [
                    'Iron Cove',
                    'Rozelle',
                    'Leichhardt',
                    'Lilyfield',
                ];
            }
            const { data: clientConfig } = await this.$axios.$get(
                `/clients/${clientId}/config`,
            );
            const { data } = await this.$axios.$get(
                `/projects/${projectId}/config`,
            );
            if (data?.locations?.value) {
                config.locations = data.locations.value;
            }
            if (data?.news_post_categories?.value) {
                config.news_post_categories = data.news_post_categories.value;
            }
            if (data?.topics?.value) {
                config.topics = Object.entries(data.topics.value).map(
                    ([label, icon]) => ({ label, icon, id: uuidv4() }),
                );
            }
            if (clientConfig?.colours?.value) {
                config.colors = clientConfig.colours.value;
            }
            if (data?.colours?.value) {
                config.colors = {
                    ...state.projectConfig.colors,
                    ...(config.colors ?? {}),
                    ...data.colours.value,
                };
            }

            if (data?.work_activity_default_accordion_titles?.value) {
                config.work_activity_default_accordion_titles =
                    data?.work_activity_default_accordion_titles?.value;
            }

            if (projectId === '2089eadb-aaa0-4d7e-905d-638b80841253') {
                config.customIcons = [
                    ...state.projectConfig.customIcons,
                    {
                        title: 'icon-cyclists-and-pedestrians',
                        url: 'https://editor.caapp.com.au/icons/icon-cyclists-and-pedestrians.png',
                    },
                ];
            }

            commit('updateConfig', config);
            return data;
        } catch (e) {
            console.error(e);
            if (e.response?.status === 404) {
                // don't do anything when we have no config swallow 404 error
            } else {
                throw e;
            }
        }
    },
    setProjectID: ({ commit }, projectID) => {
        return new Promise((resolve) => {
            console.log('setting projectID', projectID);
            commit('setProjectID', projectID);
            resolve(projectID);
        });
    },
    unsubscribe(_, data) {
        let unsubscribeURL = null;
        let postData = null;

        if (data.token) {
            unsubscribeURL = '/subscribers/unsubscribe/token';
            postData = data;
        } else {
            unsubscribeURL = '/subscribers/unsubscribe';
            postData = {
                stakeholders: [data.stakeholderID],
                distribution_lists: [data.listID],
                create_engagement: false,
            };
        }

        return this.$axios.post(unsubscribeURL, postData);
    },
    async getSurroundingMarkers({ rootState }, projectID) {
        if (!projectID) {
            return;
        }

        const r = await this.$axios.get('/contents', {
            params: {
                'filter[type]': 'work_activity',
                'filter[project_id]': projectID,
            },
        });
        const markers = r.data.data
            .filter((w) => {
                return (
                    w.metadata.props &&
                    w.metadata.props.center &&
                    w.project_id === projectID
                );
            })
            .map((wa) => {
                return {
                    center: wa.metadata.props.center,
                    zoom: wa.metadata.props.zoom,
                    max_bounds: wa.metadata.props.max_bounds,
                    color: wa.metadata.props.icon_color,
                    icon_type: wa.metadata.props.icon_type,
                    icon: wa.metadata.props.icon,
                    icon_text: wa.metadata.props.icon_text,
                    icon_img: wa.metadata.props.icon_img,
                };
            });
        return markers;
    },
    async getMedia({ state }, mediaID) {
        const res = await this.$axios.get(`/media/${mediaID}`);
        return res.data;
    },
    async postCampaign({ state }, data) {
        const postData = data;
        postData.project_id = state.projectID;
        postData.type =
            data.type === 'workActivity' ? 'work_activity' : 'news_post';
        postData.scheduled_at = data.scheduled_at
            ? new Date(data.scheduled_at).toISOString()
            : null;
        const res = await this.$axios.$post('/campaigns', postData);
        return res.data;
    },
    async sendTestEmail(_, data) {
        const res = await this.$axios.$post('/campaigns/test-send', data);
        return res.data;
    },
    async getDistributionLists({ state }, projectID) {
        if (!(projectID || state.projectID)) {
            return;
        }
        const res = await this.$axios.$get('/distributionlists', {
            params: {
                'filter[type]': 'email',
                'filter[project_id]': projectID || state.projectID,
                limit: '-1',
                sort: 'name',
            },
        });
        return res.data;
    },
    checkSlugIsValid({ state: { projectID } }, data) {
        if (data.slug) {
            return this.$axios
                .$get(`/projects/${projectID}/contents/${data.slug}`)
                .catch((_err) => true)
                .then(
                    (res) =>
                        !res ||
                        !res.data ||
                        !res.data.metadata ||
                        res.data.type === 'project' ||
                        data.contentID === res.data.metadata.uuid,
                );
        }
        return false;
    },
    async getAllContent({ state }, contentType) {
        if (!state.projectID) {
            throw new Error(`getAllContent projectId: ${state.projectID}`);
        }

        const res = await this.$axios.$get('/contents', {
            params: {
                'filter[type]': contentType,
                'filter[project_id]': state.projectID,
                limit: '-1',
            },
        });
        const filtered = res.data.filter((content) => content.metadata.uuid);

        if (contentType === 'page') {
            commit('setPages', filtered);
        }
    },
    async getAllContentBasic(
        { state, commit },
        { contentType, category, sort, forEditor, includeChildProjects },
    ) {
        if (!state.projectID) {
            throw new Error(`getAllContent projectId: ${state.projectID}`);
        }
        const projectIdFilter = includeChildProjects
            ? 'filter[project_id_with_children]'
            : 'filter[project_id]';
        const res = await this.$axios.$get('/contents', {
            params: {
                'filter[type]': contentType,
                [projectIdFilter]: state.projectID,
                limit: '-1',
                basic_data: true,
                for_editor: forEditor === false ? undefined : true,
                'filter[category]': category,
                include: 'client',
                sort,
            },
        });
        const filtered = res.data.filter((content) => content.metadata.uuid);
        if (contentType === 'work_activity') {
            commit('setWorkActivities', filtered);
        }
        if (contentType === 'page') {
            commit('setPages', filtered);
        }
        return filtered;
    },
    async getContent(_, id) {
        if (!id) {
            throw new Error('cannot get content: id is undefined');
        }
        const { data } = await this.$axios.$get(`/contents/${id}`);
        return data;
    },
    async getProject(_, id) {
        const res = await this.$axios.$get(`/projects/${id}`);
        return res.data;
    },
    async getClient(_, id) {
        const res = await this.$axios.$get(`/clients/${id}`);
        return res.data;
    },
    async publishModal({ state }, metadata) {
        const contentData = {
            type: 'modal',
            name: metadata.props.title,
            project_id: state.projectID,
            metadata,
            published_status: 'published',
        };
        try {
            const {
                data: { id: contentID },
            } = await this.$axios.$post('/contents', contentData);
            const r = await this.$axios.patch(`/contents/${contentID}`, {
                metadata: {
                    ...metadata,
                    uuid: contentID,
                },
            });
            return {
                status: r.status,
                message: 'success',
                contentID,
            };
        } catch (err) {
            return {
                status: err,
                message: 'failure',
                contentID: metadata.uuid,
            };
        }
    },
    async createContent({ state }, { type, ...metadata }) {
        const contentData = {
            metadata,
            name: metadata.props.title,
            project_id: state.projectID,
            published_status: 'draft',
            type,
        };

        try {
            const res = await this.$axios.post('/contents/', contentData);
            return {
                status: res.status,
                message: 'success',
                contentID: res?.data?.data?.id,
            };
        } catch (err) {
            return {
                status: err,
                message: 'failure',
                contentID: metadata.uuid,
            };
        }
    },
    async publishContent({ commit, dispatch, state }, data) {
        const metadata = data.metadata;

        // Set portal preview url
        if (!isUrlValid(metadata.props.preview_url)) {
            const project = await dispatch('getProject', state.projectID);
            metadata.props.preview_url = project.default_portal_url ?? '';
        }
        if (!metadata.props.preview_url.includes('?mapView')) {
            metadata.props.preview_url += `?mapView=notifCard_${metadata.uuid}`;
        }

        // Set icon type from favourite to icon for portal display
        if (metadata.name === 'WorkActivity') {
            if (metadata.props.icon_type === 'favourite') {
                metadata.props.icon_type =
                    metadata.props.icon_img && metadata.props.icon_img.url
                        ? 'image'
                        : 'icon';
            }
        }
        if (metadata.features) {
            metadata.features.forEach((f) => {
                if (
                    f.source_type === 'Point' &&
                    f.properties.icon_type === 'favourite'
                ) {
                    f.properties.icon_type =
                        f.properties.icon_img && f.properties.icon_img.url
                            ? 'image'
                            : 'icon';
                }
            });
        }

        const contentData = {
            name: metadata.props ? metadata.props.title : 'Untitled',
            slug:
                metadata.props && metadata.props.slug
                    ? metadata.props.slug
                    : null,
            parent_id:
                metadata.props && metadata.props.parent_id
                    ? metadata.props.parent_id
                    : null,
            metadata,
            published_status: !data.scheduled_at ? 'published' : undefined,
            scheduled_at: data.scheduled_at
                ? new Date(data.scheduled_at).toISOString()
                : null,
            unscheduled_at: data.unscheduled_at
                ? new Date(data.unscheduled_at).toISOString()
                : null,
            custom_date: data.custom_date
                ? new Date(data.custom_date).toISOString()
                : null,
            distribution_lists: {
                sync: metadata.props.distribution_lists,
            },
        };

        const postURL = `/contents/${metadata.uuid}`;

        try {
            const res = await this.$axios.patch(postURL, contentData);
            commit('content/sendContentUpdates', res.data.data, {
                root: true,
            });
            // eslint-disable-next-line camelcase
            const { updated_at, published_at, scheduled_at } = res.data.data;
            const activeContent = {
                ...metadata,
                // eslint-disable-next-line camelcase
                updated_at,
                // eslint-disable-next-line camelcase
                published_at,
                // eslint-disable-next-line camelcase
                scheduled_at,
            };
            commit('content/setActive', activeContent, { root: true });
            try {
                await this.$localForage.content.removeItem(metadata.uuid);
            } catch (e) {
                console.error(e);
            }
            return {
                status: res.status,
                message: 'success',
                contentID: metadata.uuid,
            };
        } catch (err) {
            commit('content/setServerError', err, { root: true });
            commit('content/setPublishState', 'error', { root: true });
            console.log('publish err: ', err);
            return {
                status: err,
                message: 'failure',
                contentID: metadata.uuid,
            };
        }
    },
};
