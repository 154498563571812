<template>
    <div
        :id="elementID"
        :class="classList"
        :style="style"
        class="footer-subnav"
    >
        <div class="container">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'FooterSubnav',
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
        };
    },
    computed: {
        style() {
            return {
                textAlign: this.attrs.align,
                backgroundColor: this.getColor(this.attrs.bg_color),
                fontFamily: this.projectConfig.fontFamily,
            };
        },
        classList() {
            return [this.attrs.element_class];
        },
        elementID() {
            return this.attrs.element_id || '';
        },
    },
};
</script>

<style lang="scss">
div.footer-subnav {
    margin: 0;
    padding: 0.5em;

    .container {
        display: flex;
        flex-direction: column;

        p {
            margin-bottom: 0 !important;
            font-size: 0.75em !important;
        }
    }
}
</style>
