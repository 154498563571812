<template>
    <div :id="elementID" :class="classList" class="footer-columns row">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'FooterColumns',
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
        };
    },
    computed: {
        elementID() {
            return this.attrs.element_id || '';
        },
        classList() {
            return this.attrs.element_class || '';
        },
    },
};
</script>

<style lang="scss" scoped>
.footer-columns {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    position: relative;
    justify-content: stretch;
    align-items: stretch;
    width: 100%;
    margin: 0;
}
</style>
