<template>
    <table border="0" width="100%" cellpadding="0" cellspacing="0">
        <tr>
            <td :style="spacingStyle">&nbsp;</td>
        </tr>
        <tr>
            <td
                :style="{
                    background: 'none',
                    borderBottom: `${attrs.thickness || 1}px solid ${getColor(
                        attrs.color,
                    )}`,
                    height: `${attrs.thickness || 1}px `,
                    width: '100%',
                    margin: 0,
                    'mso-line-height-rule': 'exactly',
                    'line-height': `${attrs.thickness || 1}px `,
                    'font-size': `${attrs.thickness || 1}px `,
                }"
            >
                &#8203;
            </td>
        </tr>
        <tr>
            <td :style="spacingStyle">&nbsp;</td>
        </tr>
    </table>
</template>

<script>
export default {
    name: 'Hr',
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData?.props ?? {
                color: '#555555',
                thickness: 2,
            },
        };
    },
    computed: {
        spacingStyle() {
            const height = '24px';
            return {
                height,
                width: '100%',
                margin: 0,
                'mso-line-height-rule': 'exactly',
                'line-height': height,
                'font-size': height,
            };
        },
    },
};
</script>
