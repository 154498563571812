<template>
    <div class="cards-container" :style="style" @click="doClickAction">
        <DragHandle />

        <draggable
            v-if="isPreview && !previewMode"
            v-model="
                // eslint-disable-next-line vue/no-mutating-props
                componentData.children
            "
            class="draggable cards ca-cards"
            :class="classList"
            group="cards"
            :data-ca-cards-count="cardCount"
            @change="refreshPreviewOrder(componentData.uuid)"
        >
            <slot></slot>
        </draggable>

        <div
            v-else
            :id="elementID"
            class="cards ca-cards"
            :class="classList"
            :data-ca-cards-count="cardCount"
        >
            <slot />
        </div>

        <Adder
            :component-data="componentData.parentData"
            :child-u-u-i-d="componentData.uuid"
        />
    </div>
</template>

<script>
import helpers from '../mixins/helpers';
import Adder from './editor/Adder';
import DragHandle from './editor/DragHandle';
import componentInlineStyle from '~/utils/componentInlineStyle';

export default {
    name: 'Cards',
    components: {
        Adder,
        DragHandle,
    },
    mixins: [helpers],
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
        };
    },
    computed: {
        style() {
            return {
                ...componentInlineStyle(this.attrs, this.getColor),
            };
        },
        cardCount() {
            const cardCount =
                this.componentData.children.filter(
                    (item) => item.show !== false,
                )?.length ?? 1;
            return cardCount > 4 ? 3 : cardCount;
        },
        classList() {
            return [
                `cards-${this.attrs.card_size || 'md'}`,
                'justify-content-' + this.attrs.justify_content,
                ...this.helperClasses,
                this.attrs.element_class,
            ];
        },
        elementID() {
            return this.attrs.element_id || '';
        },
    },
};
</script>

<style lang="scss">
.cards-container {
    display: flex;
    flex-direction: column;
    position: relative;

    &:hover {
        & > .drag-handle {
            opacity: 1;
        }
    }
}
</style>
