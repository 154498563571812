<template>
    <b-form-group
        id="address"
        label="Address"
        :label-for="'input_address'"
        class="width-full"
    >
        <b-form-input
            id="input_address"
            ref="autocomplete"
            v-model="value"
            placeholder="Search"
            type="text"
            :required="attrs.required"
            @focus="value = ''"
        />
    </b-form-group>
</template>

<script>
export default {
    name: 'FormAddress',
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
            value: '',
            autocomplete: null,
        };
    },
    async mounted() {
        const project = await this.$store
            .dispatch('api/getProject', this.$store.getters['api/projectID'])
            .then((res) => res);

        // eslint-disable-next-line no-undef
        const bounds = new google.maps.LatLngBounds(
            // eslint-disable-next-line no-undef
            new google.maps.LatLng(
                project.latitude - 0.5,
                project.longitude - 0.5,
            ),
            // eslint-disable-next-line no-undef
            new google.maps.LatLng(
                project.latitude + 0.5,
                project.longitude + 0.5,
            ),
        );

        const options = {
            locationBias: bounds,
            types: ['geocode'],
        };

        // eslint-disable-next-line no-undef
        this.autocomplete = new google.maps.places.Autocomplete(
            document.getElementById('input_address'),
            options,
        );

        this.autocomplete.addListener('place_changed', () => {
            const place = this.autocomplete.getPlace();
            const ac = place.address_components;

            this.$emit('input', place);

            console.log('place', place);
            console.log('ac', ac);
        });
    },
};
</script>

<style lang="scss">
.form-input {
    margin: 0.25em;
}
</style>
