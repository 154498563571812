var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{style:({
        padding: _vm.margin,
        width: _vm.attrs.block ? '700px' : undefined,
    }),attrs:{"colspan":_vm.attrs.block ? 10 : undefined,"align":"center"}},[_c('table',{attrs:{"cellpadding":"0","cellspacing":"0","border":"0","width":_vm.attrs.block ? '100%' : undefined}},[_c('tbody',[_c('tr',[_c('td',{style:({
                        color: _vm.getColor(_vm.attrs.text),
                        backgroundColor: _vm.getColor(_vm.attrs.bg_color),
                        padding: _vm.padding,
                        width: _vm.attrs.block ? '100%' : undefined,
                    }),attrs:{"width":_vm.attrs.block ? '100%' : undefined,"align":"center"}},[_c('a',{style:({
                            textAlign: 'center',
                            color: _vm.getColor(_vm.attrs.text),
                            borderRadius: '4px',
                            fontFamily: _vm.projectConfig.fontFamily,
                            width: _vm.attrs.block ? '100%' : undefined,
                            display: _vm.attrs.block ? 'block' : undefined,
                            textDecoration: 'none',
                        }),attrs:{"href":_vm.href || _vm.attrs.href,"target":"_blank"}},[_c('span',{style:({
                                display: _vm.attrs.block ? 'block' : undefined,
                            })},[_vm._v(_vm._s(_vm.componentData.value))])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }