import { isObject, isEmpty, isNil } from 'lodash-es';
import sortObject from './sortObject';

/**
 * Normalize content for string diff
 * Remove keys that the user doesn't change
 *
 * @param {Object} data
 * @returns {Object}
 */
export default function filterContent({
    obj,
    keys = [
        'helper_classes',

        'portal_preview_url',
        'highlighted',
        '$collapsed',
        'parentData',
        'parentUUID',
        'static_generation_message',
        'static_generation_status',
        'updated_at',
    ],
}) {
    const result = sortObject(JSON.parse(JSON.stringify(obj)));
    for (const i in result) {
        if (!Object.prototype.hasOwnProperty.call(result, i)) continue;
        if (i === 'layout' && Array.isArray(result[i])) {
            // set layout to empty object, some times is an array.
            result[i] = {};
        } else if (keys.includes(i)) {
            delete result[i];
        } else if (isObject(result[i])) {
            result[i] = filterContent({
                obj: result[i],
                keys,
            });
        }

        // if (isNil(result[i]) || (isObject(result[i]) && isEmpty(result[i]))) {
        //     delete result[i];
        // }
    }
    return result;
}
