<template>
    <b-nav-item
        v-bind="{ href, target, download }"
        class="ca-component ca-navlink"
        :link-classes="classList"
        @click="doClickAction"
    >
        <font-awesome-icon
            v-if="attrs.icon && attrs.icon_pos === 'left'"
            class="mr-1"
            :icon="[attrs.icon_prefix || 'far', attrs.icon]"
        />
        <span :class="{ 'sr-only': attrs.show_title === false }">{{
            attrs.label
        }}</span>
        <font-awesome-icon
            v-if="attrs.icon && attrs.icon_pos === 'right'"
            class="ml-1"
            :icon="[attrs.icon_prefix || 'far', attrs.icon]"
        />
    </b-nav-item>
</template>

<script>
import helpers from '../mixins/helpers';
import onClickActions from '~/mixins/onClickActions';
export default {
    name: 'NavLink',
    mixins: [helpers, onClickActions],
    props: {
        componentData: Object,
    }, // For components that need editable paddings/margins
    computed: {
        attrs() {
            return this.componentData.props || {};
        },
        // All UI components have computed classlists & element IDs
        // based on the componentData.props.element_class & element_id
        classList() {
            return [this.attrs.element_class || '', ...this.helperClasses];
        },
        elementID() {
            return this.attrs.element_id || '';
        },
    },
};
</script>

<style lang="scss">
.nav-links {
    .nav-item {
        display: flex;
        justify-content: center;
        align-items: center;

        a.nav-link {
            color: inherit;
        }
    }
}
</style>
