var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
        _vm.isPreview &&
        !_vm.previewMode &&
        !(
            _vm.componentData &&
            _vm.componentData.children &&
            _vm.componentData.children.length
        )
        // grandchildren are set in the render component
        // we don't need the adder since the children have adders
        // && !componentData.hasGrandChildren
    )?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.close),expression:"close"}],staticClass:"adder",class:_vm.classList},[(!_vm.small)?_c('div',{staticClass:"line text-shadow"}):_vm._e(),_vm._v(" "),_c('tippy',{attrs:{"arrow":""},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('div',{staticClass:"icon-container text-shadow",attrs:{"id":_vm.id},on:{"click":function($event){$event.stopPropagation();return _vm.addComponent.apply(null, arguments)}}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'plus']}})],1)]},proxy:true}],null,false,3855340549)},[_vm._v("\n        Add component after this\n    ")]),_vm._v(" "),(!_vm.small)?_c('div',{staticClass:"line text-shadow"}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }