<template>
    <div class="map-interact">
        <RenderComponent :component-data="activeMap.children[0]" />

        <client-only>
            <MglMap
                :id="mapID"
                :access-token="accessToken"
                :map-style="activeMap.props.map_style_url"
                :center="activeMap.props.center"
                :zoom="activeMap.props.zoom"
                :max-bounds="activeMap.props.max_bounds"
                @load="onMapLoaded"
                @zoomend="zoomHandler"
            >
                <div id="left-controls">
                    <div class="btns">
                        <b-dropdown
                            v-if="legendFeatures && legendFeatures.length > 0"
                            id="mapKey"
                            class="text-dark shadow"
                            variant="light"
                        >
                            <template #button-content>
                                <span class="fa fa-list" />
                                Map key
                            </template>

                            <b-dropdown-item>
                                <Legend
                                    :features="activeMap.features"
                                    :value="activeMap.legend"
                                />
                            </b-dropdown-item>
                        </b-dropdown>

                        <b-dropdown
                            id="mapCategory"
                            class="text-dark shadow"
                            variant="light"
                        >
                            <template #button-content>
                                <span class="fa fa-list" />
                                Category
                            </template>

                            <b-dropdown-item @click="setCategory('Default')">
                                Default
                            </b-dropdown-item>
                            <b-dropdown-item @click="setCategory('Noise')">
                                Noise
                            </b-dropdown-item>
                            <b-dropdown-item
                                @click="setCategory('Construction')"
                            >
                                Construction
                            </b-dropdown-item>
                            <b-dropdown-item
                                @click="setCategory('Air Quality')"
                            >
                                Air Quality
                            </b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>

                <MglMarker
                    v-for="(marker, i) in state.hotspots"
                    :id="marker.id"
                    :key="i"
                    :offset="[0, 0]"
                    :coordinates="marker.geometry.coordinates"
                >
                    <MarkerContent
                        v-show="
                            state.zoom > marker.properties.min_zoom &&
                            state.zoom < marker.properties.max_zoom
                        "
                        slot="marker"
                        :marker="marker"
                    ></MarkerContent>
                </MglMarker>
            </MglMap>
        </client-only>
    </div>
</template>

<script>
import Legend from './Legend';
import MarkerContent from './MarkerContent';

export default {
    name: 'MapInteract',
    components: {
        MarkerContent,
        Legend,
    },
    props: {
        componentData: Object,
        activeMap: Object,
        mapID: String,
    },
    data() {
        return {
            accessToken:
                'pk.eyJ1Ijoic3BhdGlhbG1lZGlhIiwiYSI6IjZHZmMxMHMifQ.69lI_Pb8buVP3fJ4xjk5iA',
            GlMap: null,
            state: {
                hotspots: [],
                layers: [],
                zoom: null,
                pitch: null,
                bearing: null,
                center: [],
                style: null,
            },
        };
    },
    computed: {
        legendFeatures() {
            return this.activeMap.features.filter((f) => {
                return f.properties && f.properties.legend;
            });
        },
        activeCategory() {
            return this.$store.getters['maps/activeCategory'];
        },
    },
    watch: {
        activeCategory(next, prev) {
            if (next !== prev && this.GlMap) {
                this.generateHotspots();
                this.filterFeatures();
            }
        },
    },
    mounted() {
        this.setMapData(this.activeMap);
        this.setCategory('Default');
        console.log('map interact mounted');
    },
    methods: {
        zoomHandler(e) {
            // var vm = this;
            // setTimeout(() => {
            //     vm.generateHotspots()
            // }, 100)
        },
        setCategory(cat) {
            this.$store.commit('maps/setActiveCategory', cat);
        },
        onMapLoaded(event) {
            this.GlMap = event.component;
            this.generateHotspots();
            this.activeMap.features.forEach((feat) => {
                if (feat.source_type !== 'Point') {
                    this.addFeature(feat);
                }
            });
        },
        filterFeatures() {
            const vm = this;
            const map = vm.GlMap.map;

            vm.activeMap.features.forEach((feat) => {
                const showFeature =
                    (feat.properties.is_visible !== false ||
                        feat.properties.is_visible) &&
                    (!vm.activeCategory ||
                        vm.activeCategory === 'Default' ||
                        feat.properties.category === vm.activeCategory);

                if (showFeature) {
                    if (map.getLayer(feat.id)) {
                        map.setLayoutProperty(feat.id, 'visibility', 'visible');
                    }

                    // If feature is not in current mode
                } else if (map.getLayer(feat.id)) {
                    // Hide layer
                    map.setLayoutProperty(feat.id, 'visibility', 'none');
                }
            });
        },
        addFeature(feature) {
            const map = this.GlMap.map;

            const source = {
                id: feature.id,
                geometry: feature.metadata
                    ? feature.metadata.geometry
                    : feature.geometry,
                properties: {},
                type: 'Feature',
            };

            map.addSource(feature.id, {
                type: 'geojson',
                data: source,
            });

            ['line-color', 'fill-color'].forEach((key) => {
                const color =
                    this.$store.getters['api/colors'][feature.paint[key]];
                if (color) feature.paint[key] = color;
            });

            map.addLayer(feature);
            this.addLayerPopup(feature);
        },
        addLayerPopup(layer) {
            const vm = this;
            const map = vm.GlMap.map;

            const popup = new vm.$mapbox.Popup({
                closeButton: true,
                closeOnClick: true,
                className: 'feature-popup',
            });

            map.on('mouseenter', layer.id, function (e) {
                if (layer.properties.display_popup === 'Hover') {
                    const description = `<div>
                            <h6>${layer.properties.title || ''}</h6>
                            ${layer.properties.description || ''}
                        </div>`;
                    map.getCanvas().style.cursor = 'pointer';
                    popup
                        .setLngLat(e.lngLat.wrap())
                        .setHTML(description)
                        .addTo(map);

                    map.on('mousemove', function (e) {
                        if (layer.properties.display_popup === 'Hover') {
                            popup.setLngLat(e.lngLat.wrap());
                        }
                    });
                }
            });

            map.on('click', layer.id, function (e) {
                if (layer.properties.display_popup === 'Click') {
                    const coords = JSON.parse(JSON.stringify(e.lngLat.wrap()));
                    const description = `<div>
                            <h6>${layer.properties.title || ''}</h6>
                            ${layer.properties.description || ''}
                        </div>`;
                    map.getCanvas().style.cursor = 'pointer';
                    popup.setLngLat(coords).setHTML(description).addTo(map);
                }
            });

            map.on('mouseleave', layer.id, function () {
                if (layer.properties.display_popup === 'Hover') {
                    map.getCanvas().style.cursor = '';
                    popup.remove();
                }
            });
        },
        generateHotspots() {
            const vm = this;
            vm.state.hotspots = [];

            setTimeout(() => {
                vm.state.hotspots = vm.activeMap.features.filter((feature) => {
                    return (
                        feature.geometry &&
                        feature.geometry.type === 'Point' &&
                        (feature.properties.is_visible !== false ||
                            feature.properties.is_visible) &&
                        (!this.activeCategory ||
                            this.activeCategory === 'Default' ||
                            feature.properties.category === this.activeCategory)
                    );
                });
            }, 100);
        },
        setMapData(activeMap) {
            this.state.zoom = activeMap.props.zoom;
            this.state.center = activeMap.props.center;
            this.state.style = activeMap.props.map_style_url;
        },
    },
};
</script>

<style lang="scss">
.mapboxgl-popup-content {
    padding: 1rem;

    ul.mapbox-popup-layer-list {
        list-style-type: none;
        padding-inline-start: 0px !important;
    }
}

button#mapKey__BV_toggle_,
button#mapCategory__BV_toggle_ {
    height: 36px !important;
    font-size: 15px !important;
    border: none;
}

.map-interact {
    .adder,
    .drag-handle,
    .adder {
        display: none;
    }
}
</style>

<style lang="scss" scoped>
.map-interact {
    height: calc(100vh - 44px);
    width: 100%;
    flex-grow: 1;
    max-height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: stretch;

    #left-controls {
        margin: 10px;

        .btns {
            z-index: 1;
            position: absolute;
            margin-left: -5px;
            margin-top: 10px;

            button.btn {
                height: 36px;
                font-size: 15px;
                border: none;
                margin-left: 5px;
            }
        }
    }
}
</style>
