<template>
    <div class="html-block-container ca-component ca-htmlblock">
        <div :id="elementID" class="html-block">
            <style scoped>
                {{ css }}
            </style>

            <div v-html="componentData.value" />

            <!-- <script type="text/javascript">
                {
                    {
                        script;
                    }
                }
            </script> -->
        </div>

        <Adder
            :component-data="componentData.parentData"
            :child-u-u-i-d="componentData.uuid"
        />
    </div>
</template>

<script>
import helpers from '../mixins/helpers';
import Adder from './editor/Adder';
import generateComponentElementId from '~/utils/generateComponentElementId';

export default {
    name: 'HtmlBlock',
    components: {
        Adder,
    },
    mixins: [helpers],
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
        };
    },
    computed: {
        elementID() {
            return generateComponentElementId(this.componentData);
        },
        css() {
            return this.attrs.css;
        },
        script() {
            return this.attrs.js;
        },
    },
};
</script>

<style lang="scss" scoped>
.html-block-container {
    display: flex;
    flex-direction: column;
}
</style>
