<template>
    <div :id="elementID" :class="classList" :style="style" class="card-title">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'CardTitle',
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
        };
    },
    computed: {
        style() {
            return {
                backgroundColor: this.getColor(this.attrs.bg_color),
                fontFamily: this.projectConfig.fontFamily,
            };
        },
        classList() {
            return [
                this.attrs ? this.attrs.element_class : '',
                // this.helperClasses
            ];
        },
        elementID() {
            return this.attrs ? this.attrs.element_id : '';
        },
    },
};
</script>
