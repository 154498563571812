import { config } from '@fortawesome/fontawesome-svg-core';

function query({
    store: { state, commit },
    route: {
        query: { context },
    },
}) {
    // query string param ?context=invoked
    commit('setContext', context);
}

export default function (ctx, inject) {
    config.autoReplaceSvg = 'nest';
    query(ctx);
}
