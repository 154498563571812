var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"display":"flex","align-items":"center","justify-content":"left"}},[(['Polygon', 'Point'].includes(_vm.type))?_c('span',[_c('div',{staticStyle:{"margin":"0 auto","position":"relative"},style:({
                backgroundColor: _vm._color && !_vm.pattern ? _vm._color : undefined,
                backgroundImage: _vm.pattern
                    ? ("url(" + (_vm.getPattern(_vm.pattern)) + ")")
                    : undefined,
                backgroundSize: '64px',
                opacity: _vm.opacity,
                borderRadius: _vm.type === 'Point' ? '50%' : 0,
                borderStyle:
                    _vm.borderWidth || _vm.type === 'Point' ? 'solid' : undefined,
                borderWidth: (_vm.borderWidth + "px"),
                borderColor: _vm.borderColor,
                width: _vm.size,
                height: _vm.size,
            })},[(_vm.iconText)?[_vm._v("\n                "+_vm._s(_vm.iconText)+"\n            ")]:(_vm.iconValue)?_c('font-awesome-icon',{staticStyle:{"position":"absolute","top":"50%","left":"50%","transform":"translate(-50%, -50%)","height":"16px"},style:({
                    color: _vm.getColor(_vm.iconTextColor || 'light'),
                }),attrs:{"icon":['fas', _vm.iconValue],"size":"lg"}}):[_vm._v("​")]],2)]):_c('span',[_c('div',{staticStyle:{"display":"inline-block","margin":"auto"},style:({
                opacity: _vm.opacity,
                borderTopColor: _vm._color,
                borderTopWidth: (_vm.lineWidth + "px"),
                borderTopStyle: _vm.lineStyle,
                width: _vm.size,
            })})]),_vm._v(" "),(_vm.label)?_c('p',{staticStyle:{"display":"inline-block","margin-bottom":"0","margin-left":"8px"}},[_vm._v("\n        "+_vm._s(_vm.label)+"\n    ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }