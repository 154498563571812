<template>
    <div class="submission">
        <font-awesome-icon :icon="['fal', 'comment']" />

        <div class="countdown">
            <span class="days">{{ submissionDays }}</span>
            days left to
        </div>
        <a class="make-a-submission" :href="url" target="_blank"
            >Make a submission</a
        >
        <div class="project-text">
            <font-awesome-icon :icon="['fal', 'plane']" />

            MDP
        </div>
    </div>
</template>

<script>
export default {
    name: 'MakeSubmission',
    props: ['deadline', 'url'],
    computed: {
        submissionDays() {
            const now = this.$moment();
            const deadline = this.$moment(this.deadline, 'DD/MM/YYYY');
            const duration = this.$moment.duration(deadline.diff(now));
            return Math.ceil(duration.asDays());
        },
    },
};
</script>

<style lang="scss" scoped>
.submission {
    position: absolute;
    bottom: 2rem;
    right: 2rem;
    z-index: 1;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;

    & > i.fa-comment {
        color: white;
        background: $primary;
        border: 1px solid white;
        padding: 0.5rem;
        border-radius: 50%;
        margin-bottom: -1rem;
        z-index: 2;
    }

    .countdown {
        background: white;
        border: 1px solid $primary;
        color: $primary;
        border-radius: 50%;
        height: 90px;
        width: 90px;
        text-align: center;
        font-size: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-bottom: 0.25rem;

        .days {
            font-size: 32px;
            padding: 0;
            display: block;
            line-height: 32px;
            font-weight: 600;
        }
    }

    .make-a-submission {
        background: $primary;
        border-radius: 4px;
        color: white;
        font-size: 10px;
        text-align: center;
        padding: 0.5rem;
        border: 1px solid white;
        margin-top: -1rem;
        width: 120px;
        z-index: 3;
        cursor: pointer;
        transition: 0.5s ease;

        &:hover {
            filter: brightness(1.2);
        }
    }

    .project-text {
        background: $secondary;
        color: white;
        border-radius: 4px;
        font-size: 10px;
        font-weight: 600;
        text-align: left;
        padding: 0.5rem;
        border: 1px solid white;
        width: 120px;
        margin-top: -2px;

        i {
            margin-right: 0.5rem;
        }
    }
}
</style>
