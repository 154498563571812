<template>
    <div class="carousel" style="max-width: 720px; position: relative">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'Carousel',
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props,
        };
    },
};
</script>
