<template>
    <b-card
        :id="elementID"
        :class="classList"
        no-body
        class="ca-component ca-accordionitem card"
    >
        <b-card-header
            header-tag="header"
            class="p-0 accordion-item-header"
            role="tab"
            @keyup.space.prevent
        >
            <div
                class="btn btn-link ca-accordion-btn ca-accordion-card-header-title"
                :class="btnClass"
                :style="headerStyle"
                @click="toggle"
                @keyup.space.prevent
            >
                <template v-if="isPreview && !previewMode">
                    <div
                        class="mr-3"
                        style="color: inherit"
                        @click="doClickAction"
                    >
                        <client-only>
                            <Wysiwyg
                                :value="title"
                                :mini="true"
                                @input="updateTitle"
                                @setEditing="setEditing"
                            />
                        </client-only>
                    </div>
                </template>

                <template v-else>
                    <h6 :style="textStyle" @click="doClickAction">
                        {{ attrs.title | stripHTML }}
                    </h6>
                </template>

                <span class="pl-3">
                    <font-awesome-icon
                        :class="{ rotate: open }"
                        :style="textStyle"
                        :icon="['fa', 'caret-right']"
                    ></font-awesome-icon>
                </span>
            </div>
        </b-card-header>

        <b-collapse
            :id="id"
            :ref="accordionID"
            v-model="open"
            :visible="visible"
            role="tabpanel"
            :accordion="accordionID"
        >
            <b-card-body>
                <draggable
                    v-if="isPreview && !previewMode"
                    v-model="
                        // eslint-disable-next-line vue/no-mutating-props
                        componentData.children
                    "
                    handle=".drag-handle"
                    group="accordionItem"
                >
                    <slot></slot>
                </draggable>

                <slot v-else></slot>
            </b-card-body>
        </b-collapse>

        <Adder
            v-if="editable"
            :component-data="componentData.parentData"
            :child-u-u-i-d="componentData.uuid"
        />
    </b-card>
</template>

<script>
import Adder from './editor/Adder';
import Wysiwyg from './editor/Wysiwyg';

export default {
    name: 'AccordionItem',
    components: {
        Adder,
        Wysiwyg,
    },
    props: {
        componentData: Object,
        visible: {
            type: Boolean,
            default: false,
        },
        editable: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            attrs: this.componentData.props || {},
            id: 'accordion-' + this.componentData.uuid,
            editing: false,
            title: this.componentData.props.title,
            open: this.componentData.open,
        };
    },
    computed: {
        classList() {
            return this.attrs.element_class || '';
        },
        elementID() {
            return this.attrs.element_id || '';
        },
        accordionID() {
            return this.componentData.parentUUID || '0';
        },
        btnClass() {
            return this.attrs.header_size === 'sm' ? ['py-2', 'px-3'] : 'p-3';
        },
        textStyle() {
            return {
                color: this.open
                    ? this.getColor(this.attrs.header_text)
                    : this.getColor('dark'),
                fontSize: this.attrs.header_size === 'sm' ? '12px' : 'inherit',
                fontFamily: this.projectConfig.fontFamily,
            };
        },
        headerStyle() {
            const style = {
                borderLeft:
                    '5px solid ' + this.getColor(this.attrs.header_color),
            };
            if (!this.open) {
                // return {
                //     borderLeft:
                //         '5px solid ' + this.getColor(this.attrs.header_color),
                //     // color: this.getColor('dark'),
                //     // backgroundColor: this.getColor('light'),
                // };
            }
            if (this.attrs.enable_background_color) {
                style.backgroundColor = this.getColor(this.attrs.header_color);
                style.color = this.getColor(this.attrs.header_text);
            }

            return style;
        },
    },
    methods: {
        setEditing(val) {
            this.editing = val;
        },
        updateTitle(e) {
            const title = e.replace(/(<([^>]+)>)/gi, '');
            this.attrs.title = title;
            this.attrs.label = title;
        },
        toggle(event) {
            if (!this.editing) {
                this.$root.$emit('bv::toggle::collapse', this.id);
                if (this.componentData) {
                    this.doClickAction(event);
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.card-header,
.card {
    border: none;
}

.accordion-item-header {
    transition: 0.3s ease;
}

.btn {
    text-align: left;
    margin: 0;
    border-radius: 0.25rem 0.25rem 0 0;
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;

    // &.active {
    //     background: $primary;
    // }

    h6 {
        margin-bottom: 0;
    }

    span .fa-caret-right {
        transition: 0.1s ease;

        &.rotate {
            transform: rotate(90deg);
        }
    }
}

.card-body {
    background: rgba(0, 0, 0, 0.03);
    font-size: 0.875em;
    line-height: 1.25em;
    position: relative;

    p,
    input,
    .fa {
        font-size: 0.875em;
        line-height: 1.25em;
    }
}

.collapse {
    cursor: default;
    border-radius: 0 0 0.25rem 0.25rem;
}
</style>

<style lang="scss">
div.accordion .card .card-header {
    p,
    h6 {
        margin-bottom: 0 !important;
    }
}
</style>
