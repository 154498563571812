<template>
    <div class="campaign-container">
        <div :id="elementID" class="campaign shadow" :class="classList">
            <div class="placeholder-content">
                <p>Campaign content will be inserted here.</p>
            </div>

            <slot></slot>
        </div>
    </div>
</template>

<script>
import helpers from '../mixins/helpers';

export default {
    name: 'Campaign',
    mixins: [helpers],
    props: {
        componentData: Object,
        editable: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            attrs: this.componentData ? this.componentData.props || {} : {},
        };
    },
    computed: {
        classList() {
            return [this.attrs.element_class || '', ...this.helperClasses];
        },
        elementID() {
            return this.attrs.element_id || '';
        },
    },
};
</script>

<style lang="scss">
.campaign-container {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: relative;
    width: 100%;
    background: $light;

    .campaign {
        background: #fff;
        position: relative;
        max-width: 720px;
        width: 100%;
        height: 100%;
        flex-basis: 100%;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .placeholder-content {
            position: absolute;
            height: 200px;
            width: 300px;
            display: flex;
            align-items: center;
            justify-content: center;
            top: calc(50% - 150px);
            left: calc(50% - 150px);
            text-align: center;
            font-style: italic;
        }
    }
}
</style>
