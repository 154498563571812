import Accordion from './Accordion.vue';
import AccordionItem from './AccordionItem.vue';
import BlockQuote from './BlockQuote.vue';
import NewsPost from './NewsPost.vue';
import NewsPosts from './NewsPosts.vue';
import ButtonLink from './ButtonLink.vue';
import ButtonLinks from './ButtonLinks.vue';
import CalendarBtn from './CalendarBtn.vue';
import Campaign from './Campaign.vue';
import ExternalCampaign from './ExternalCampaign.vue';
import CampaignPreFooter from './CampaignPreFooter.vue';
import CampaignFooter from './CampaignFooter.vue';
import CampaignHeader from './CampaignHeader.vue';
import Card from './Card.vue';
import CardBody from './CardBody.vue';
import CardTitle from './CardTitle.vue';
import Cards from './Cards.vue';
import Carousel from './Carousel.vue';
import CompareSlider from './CompareSlider.vue';
import Columns from './Columns.vue';
import Column from './Column.vue';
import CopyrightText from './CopyrightText.vue';
import DownloadBtn from './DownloadBtn.vue';
import FeedbackBtn from './FeedbackBtn.vue';
import FooterColumn from './FooterColumn.vue';
import FooterColumns from './FooterColumns.vue';
import FooterLink from './FooterLink.vue';
import FooterLinks from './FooterLinks.vue';
import FooterNav from './FooterNav.vue';
import FooterSubnav from './FooterSubnav.vue';
import Form from './form/Form.vue';
import FormSection from './form/FormSection.vue';
import FormInput from './form/FormInput.vue';
import FormCheckbox from './form/FormCheckbox.vue';
import FormAddressSearch from './form/FormAddressSearch.vue';
import FormEmail from './form/FormEmail.vue';
import FormName from './form/FormName.vue';
import FormRadio from './form/FormRadio.vue';
import FormSelect from './form/FormSelect.vue';
import Gallery from './Gallery.vue';
import GalleryItem from './GalleryItem.vue';
import Heading from './Heading.vue';
import HeaderColumns from './HeaderColumns.vue';
import HeaderColumn from './HeaderColumn.vue';
import HeaderSubnav from './HeaderSubnav.vue';
import HeaderNav from './HeaderNav.vue';
import Hero from './Hero.vue';
import Hr from './Hr.vue';
import HtmlBlock from './HtmlBlock.vue';
import Icon from './Icon.vue';
import Map from './Map.vue';
import MapSidebar from './MapSidebar.vue';
import MapSidebars from './MapSidebars.vue';
import Media from './Media.vue';
import InlineText from './InlineText.vue';
import MapInteract from './map/MapInteract';
import MessageBanner from './MessageBanner';
import Modals from './Modals.vue';
import Modal from './Modal.vue';
import NavDropdown from './NavDropdown.vue';
import NavDropdownItem from './NavDropdownItem.vue';
import NavInfo from './NavInfo.vue';
import NavLink from './NavLink.vue';
import NavLinks from './NavLinks.vue';
import NavLogo from './NavLogo.vue';
import Page from './Page.vue';
import Pages from './Pages.vue';
import PageFooter from './PageFooter.vue';
import PageHeader from './PageHeader.vue';
import PageSection from './PageSection.vue';
import PageSidebars from './PageSidebars.vue';
import PageSidebar from './PageSidebar.vue';
import Paragraph from './Paragraph.vue';
import Scene from './Scene.vue';
import Scenes from './Scenes.vue';
import Slide from './Slide.vue';
import SocialIcons from './SocialIcons.vue';
import Tab from './Tab.vue';
import Tabs from './Tabs.vue';
import Topic from './Topic.vue';
import Topics from './Topics.vue';
import Thumbnail from './Thumbnail.vue';
import Translation from './Translation.vue';
import ExternalFile from './ExternalFile.vue';
import VideoIframe from './VideoIframe.vue';
import Youtube from './Youtube.vue';
import Widget from './Widget.vue';
import WidgetLoader from './WidgetLoader.vue';
import WorkActivity from './WorkActivity.vue';
import WorkActivities from './WorkActivities.vue';
import Adder from './editor/Adder.vue';
import Hotspots from './Hotspots';
import MarkerContent from './map/MarkerContent';
import Percent from './Percent';
import NewsPostColumns from './NewsPostColumns';
import NewsPostColumn from './NewsPostColumn';
import MediaCollection from './MediaCollection';
import Dropdown from './Dropdown.vue';
import Spacer from './Spacer.vue';
import Language from './Language.vue';
import NewsPostSection from './NewsPostSection.vue';

const portalComponents = {
    NewsPostSection,
    Language,
    MediaCollection,
    CampaignPreFooter,
    NewsPostColumns,
    NewsPostColumn,
    Percent,
    Hotspots,
    Adder,
    Accordion,
    AccordionItem,
    BlockQuote,
    NewsPost,
    ButtonLink,
    ButtonLinks,
    CalendarBtn,
    Campaign,
    CampaignFooter,
    CampaignHeader,
    Card,
    CardBody,
    CardTitle,
    Cards,
    Carousel,
    Column,
    Columns,
    CompareSlider,
    CopyrightText,
    DownloadBtn,
    FeedbackBtn,
    FooterColumn,
    FooterColumns,
    FooterLink,
    FooterLinks,
    FooterNav,
    FooterSubnav,
    Form,
    FormCheckbox,
    FormAddressSearch,
    FormSection,
    FormInput,
    FormEmail,
    FormName,
    FormRadio,
    FormSelect,
    Gallery,
    GalleryItem,
    Heading,
    HeaderColumn,
    HeaderColumns,
    HeaderSubnav,
    HeaderNav,
    Hero,
    Hr,
    HtmlBlock,
    Icon,
    Map,
    MapSidebar,
    MapSidebars,
    Media,
    NavDropdown,
    NavDropdownItem,
    NavInfo,
    NavLink,
    NavLinks,
    NavLogo,
    NewsPosts,
    InlineText,
    MapInteract,
    MessageBanner,
    Modal,
    Modals,
    Page,
    Pages,
    PageFooter,
    PageHeader,
    PageSection,
    PageSidebar,
    PageSidebars,
    Paragraph,
    Scene,
    Scenes,
    Slide,
    SocialIcons,
    Tab,
    Tabs,
    Topic,
    Topics,
    Thumbnail,
    Translation,
    ExternalFile,
    VideoIframe,
    Youtube,
    Widget,
    WidgetLoader,
    WorkActivity,
    WorkActivities,
    Hotspot: MarkerContent,
    Dropdown,
    Spacer,
    ExternalCampaign,
};

export default portalComponents;
