<template>
    <div :style="style" :class="classList" @click="doClickAction"></div>
</template>

<script>
export default {
    name: 'Spacer',
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
        };
    },
    computed: {
        style() {
            return {
                width: this.attrs.width + '%',
                height: this.attrs.height + 'px',
                padding: this.attrs.padding + 'px',
            };
        },
        classList() {
            if (!this.attrs) return [];
            return [this.attrs.element_class || ''];
        },
    },
};
</script>
