<template>
    <div :id="elementID" :class="classList" class="page-footer shadow">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'PageFooter',
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
        };
    },
    computed: {
        classList() {
            return [this.attrs.element_class];
        },
        elementID() {
            return this.attrs.element_id || '';
        },
    },
};
</script>

<style lang="scss" scoped>
div.page-footer {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    justify-self: flex-end !important;
}
</style>
