var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"media-container"},[(!_vm.media || !_vm.media.type)?_c('div',{staticClass:"text-center"},[(_vm.spinner)?_c('font-awesome-icon',{attrs:{"icon":['far', 'spinner']}}):_vm._e()],1):[(
                ['image', 'document'].includes(_vm.media.type) ||
                (['before_after', 'video', '360'].includes(_vm.media.type) &&
                    _vm.thumb)
            )?[_c('b-img-lazy',{staticStyle:{"width":"100%","height":"auto"},attrs:{"src":_vm.imgURL,"width":_vm._width,"height":_vm._height,"alt":_vm.alt,"fluid":"","center":"","blank-color":"#eeeeee","loading":"lazy","decoding":"async","fetchpriority":"low"}})]:(_vm.media.type === '360')?_c('div',{ref:_vm.media.id,staticClass:"panorama"}):(_vm.media.type === 'video')?_c('div',{staticClass:"vimeo"},[(_vm.videoDisplay === 'thumbnail')?[_c('div',{staticStyle:{"position":"relative"}},[_c('b-img',{staticStyle:{"background":"#eeeeee","width":"100%","height":"auto"},attrs:{"src":_vm.videoThumbUrl,"width":"1200","height":"500","alt":_vm.alt,"fluid":"","center":"","blank-color":"#eeeeee","title":"Click to enlarge"}}),_vm._v(" "),_c('svg',{staticClass:"svg-inline--fa fa-play-circle fa-w-16 fa-9x",staticStyle:{"position":"absolute","width":"25%","height":"25%","top":"37%","left":"37%"},attrs:{"aria-hidden":"true","focusable":"false","data-prefix":"fad","data-icon":"play-circle","role":"img","xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 512 512"}},[_c('g',{staticClass:"fa-group"},[_c('path',{attrs:{"fill":"white","d":"M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm115.7 272l-176 101c-15.8 8.8-35.7-2.5-35.7-21V152c0-18.4 19.8-29.8 35.7-21l176 107c16.4 9.2 16.4 32.9 0 42z"}}),_vm._v(" "),_c('path',{attrs:{"fill":"grey","d":"M371.7 280l-176 101c-15.8 8.8-35.7-2.5-35.7-21V152c0-18.4 19.8-29.8 35.7-21l176 107c16.4 9.2 16.4 32.9 0 42z"}})])])],1)]:_c('vimeo-player',{ref:"player",attrs:{"player-height":"500","player-width":"1200","options":{
                    responsive: true,
                    muted: _vm._muted,
                    background: _vm._background,
                },"muted":true,"loop":true,"autoplay":_vm._autoplay,"controls":_vm._controls,"video-id":_vm.media.url}})],2):(_vm.media.type === 'before_after')?_c('CompareSlider',{attrs:{"component-data":_vm.compare}}):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }