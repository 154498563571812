<template>
    <a
        :id="elementID"
        class="footer-link"
        :href="attrs.href"
        target="_blank"
        :style="style"
        :class="classList"
        @click.stop="onClick"
    >
        {{ componentData.value }}
    </a>
</template>

<script>
export default {
    name: 'FooterLink',
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
        };
    },
    computed: {
        classList() {
            return [this.attrs.element_class || ''];
        },
        style() {
            return {
                color: this.getColor(this.attrs.text),
                fontFamily: this.projectConfig.fontFamily,
            };
        },
        elementID() {
            return this.attrs.element_id || '';
        },
    },
    methods: {
        onClick(e) {
            this.isPreview
                ? this.openEditor(this.componentData.uuid)
                : this.doAction(this.attrs.action_id, e);
        },
    },
};
</script>

<style lang="scss" scoped>
a.footer-link {
    transition: 0.3s ease;
    padding: 0.25em 0.5em 0 0.5em;
    font-size: 0.75em;
    cursor: pointer;

    &:hover {
        filter: brightness(0.8);
    }
}
</style>
