<template>
    <div class="external-file p-3">
        <h5>
            The content for this {{ attrs.title }} component is rendered by
            application/views/{{ clientSlug }}/partials/{{ projectSlug }}/{{
                attrs.external_file
            }}
            and must be edited by the Spatial Media team. Please contact your
            Spatial Media project manager to arrange changes to this component.
        </h5>
    </div>
</template>

<script>
export default {
    name: 'ExternalFile',
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props,
        };
    },
    computed: {
        projectSlug() {
            return this.$store.state.api.project?.slug;
        },
        clientSlug() {
            return this.$store.state.api.client?.base_url;
        },
    },
};
</script>

<style lang="scss" scoped>
.external-file {
    display: flex;
    justify-content: center;
    align-items: center;

    h5 {
        margin: 0;
        padding: 0;
        text-align: center;
    }
}
</style>
