var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{style:({
        width: '100%',
    }),attrs:{"cellpadding":"0","cellspacing":"0","width":"100%","valign":"middle"}},[_c('tbody',{style:({
            width: '100%',
            backgroundColor: '#fff',
        })},[(
                _vm.attrs.layout === 'grid' ||
                (_vm.componentData &&
                    _vm.componentData.parentData &&
                    _vm.componentData.parentData.name === 'NewsPostColumn')
            )?_vm._l((_vm.chunk(
                    _vm.filteredNewsPosts,
                    _vm.componentData &&
                        _vm.componentData.parentData &&
                        _vm.componentData.parentData.name === 'NewsPostColumn'
                        ? 1
                        : 2
                )),function(row,i){return _c('tr',{key:i,style:({
                    width: '100%',
                    borderBottom: '16px solid #fff',
                })},_vm._l((row),function(np,npi){return _c('td',{key:npi,staticClass:"news-post-column-container",staticStyle:{"width":"300px"},attrs:{"align":"center","valign":"top"}},[_c('Spacer'),_vm._v(" "),_c('table',{staticStyle:{"width":"300px"},attrs:{"border":"0","cellpadding":"0","cellspacing":"0"}},[_c('tr',[_c('td',[_c('img',{attrs:{"src":np.metadata.props.thumbnail
                                            ? np.metadata.props.thumbnail
                                                  .url
                                            : 'https://ca-v2.s3-ap-southeast-2.amazonaws.com/placeholder.jpg',"alt":"","width":"300","height":"175"}}),_vm._v(" "),_c('p',{style:({
                                        fontFamily:
                                            _vm.projectConfig.fontFamily,
                                    })},[_vm._v("\n                                    "+_vm._s(np.metadata.props.title)+"\n                                ")]),_vm._v(" "),(
                                        _vm.attrs.show_date &&
                                        np.custom_date &&
                                        np.published_at
                                    )?_c('p',{staticClass:"ca-contents-content-date",style:({
                                        fontFamily:
                                            _vm.projectConfig.fontFamily,
                                    })},[_vm._v("\n                                    "+_vm._s(_vm.$moment(
                                            new Date(np.custom_date) ||
                                                new Date(np.published_at)
                                        ).format('D MMMM, YYYY'))+"\n                                ")]):_vm._e(),_vm._v(" "),(np.description)?_c('p',{staticClass:"description ca-contents-content-excerpt text-truncate-description",style:({
                                        fontFamily:
                                            _vm.projectConfig.fontFamily,
                                    })},[_c('span',{domProps:{"innerHTML":_vm._s(np.description)}})]):_vm._e(),_vm._v(" "),_c('table',{attrs:{"cellpadding":"0","cellspacing":"0"}},[_c('tbody',[_c('tr',[_c('ButtonLink',{attrs:{"component-data":{
                                                    value: 'Read more',
                                                    props: {
                                                        block: false,
                                                        href:
                                                            np.published_static_pdf_url ||
                                                            np.draft_static_pdf_url,
                                                        text: '#fff',
                                                        bg_color:
                                                            'brand_secondary',
                                                    },
                                                }}})],1)])])])])]),_vm._v(" "),_c('Spacer')],1)}),0)}):_vm._l((_vm.filteredNewsPosts),function(np,i){return _c('tr',{key:i,style:({
                    width: '100%',
                    borderBottom: '16px solid #fff',
                })},[_c('td',{staticClass:"news-post-column-container",staticStyle:{"width":"300px"},attrs:{"align":"center","valign":"top"}},[_c('Spacer'),_vm._v(" "),_c('table',{staticStyle:{"width":"300px"},attrs:{"border":"0","cellpadding":"0","cellspacing":"0"}},[_c('tr',[_c('td',{attrs:{"align":"center","valign":"center"}},[_c('img',{attrs:{"src":np.metadata.props.thumbnail
                                            ? np.metadata.props.thumbnail
                                                  .url
                                            : 'https://ca-v2.s3-ap-southeast-2.amazonaws.com/placeholder.jpg',"alt":"","width":"300","height":"175"}})])])]),_vm._v(" "),_c('Spacer')],1),_vm._v(" "),_c('td',{staticClass:"news-post-column-container",staticStyle:{"width":"300px"},attrs:{"align":"center","valign":"top"}},[_c('Spacer'),_vm._v(" "),_c('table',{staticStyle:{"width":"300px"},attrs:{"border":"0","cellpadding":"0","cellspacing":"0"}},[_c('tr',[_c('td',[_c('p',{style:({
                                        fontFamily:
                                            _vm.projectConfig.fontFamily,
                                    })},[_vm._v("\n                                    "+_vm._s(np.metadata.props.title)+"\n                                ")]),_vm._v(" "),(
                                        _vm.attrs.show_date &&
                                        np.custom_date &&
                                        np.published_at
                                    )?_c('p',{staticClass:"ca-contents-content-date",style:({
                                        fontFamily:
                                            _vm.projectConfig.fontFamily,
                                    })},[_vm._v("\n                                    "+_vm._s(_vm.$moment(
                                            new Date(np.custom_date) ||
                                                new Date(np.published_at)
                                        ).format('D MMMM, YYYY'))+"\n                                ")]):_vm._e(),_vm._v(" "),(np.description)?_c('p',{staticClass:"description ca-contents-content-excerpt text-truncate-description",style:({
                                        fontFamily:
                                            _vm.projectConfig.fontFamily,
                                    })},[_c('span',{domProps:{"innerHTML":_vm._s(np.description)}})]):_vm._e(),_vm._v(" "),_c('table',{attrs:{"cellpadding":"0","cellspacing":"0"}},[_c('tbody',[_c('tr',[_c('ButtonLink',{attrs:{"component-data":{
                                                    value: 'Read more',
                                                    props: {
                                                        block: false,
                                                        href:
                                                            np.published_static_pdf_url ||
                                                            np.draft_static_pdf_url,
                                                        text: '#fff',
                                                        bg_color:
                                                            'brand_secondary',
                                                    },
                                                }}})],1)])]),_vm._v(" "),_c('Spacer')],1)])])],1)])})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }