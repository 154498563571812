<template>
    <div :id="elementID" :class="classList" class="column" :style="style">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'FooterColumn',
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
        };
    },
    computed: {
        elementID() {
            return this.attrs.element_id || '';
        },
        classList() {
            return [
                'justify-content-' + this.attrs.align || 'left',
                this.attrs.element_class || '',
            ];
        },
        style() {
            return {
                flexGrow: this.attrs.flex_grow,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.column {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    flex-basis: 0;
    position: relative;
    align-items: center;
}
</style>
