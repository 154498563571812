<template>
    <Moveable
        v-if="isBrowser"
        v-bind="moveable"
        class="hs moveable"
        :style="style"
        @drag="drag"
    >
        <MarkerContent :marker="componentData" :uuid="uuid"></MarkerContent>
    </Moveable>
    <div v-else class="hs" :style="style">
        <MarkerContent :marker="componentData" :uuid="uuid"></MarkerContent>
    </div>
</template>

<script>
import Moveable from 'vue-moveable';
import { clamp } from 'lodash-es';
import MarkerContent from '@/components/map/MarkerContent.vue';

export default {
    name: 'Hotspot',
    components: {
        Moveable,
        MarkerContent,
    },
    props: {
        componentData: { type: Object },
        props: { type: Object, default: () => ({}) },
        uuid: { type: String },
    },
    data() {
        return {
            attrs: this.componentData.props || {},
            min: 1,
            max: 95,
            isBrowser: process.browser,
        };
    },
    computed: {
        moveable() {
            return {
                container: this.$el?.parentElement,
                draggable: this.isPreview && !this.previewMode,
                throttleDrag: 1,
                roundable: false,
                resizable: false,
                keepRatio: false,
                scalable: false,
                rotatable: false,
                edge: false,
                pinchable: false, // ["draggable", "resizable", "scalable", "rotatable"]
                origin: false, // Whether or not the origin controlbox will be visible or not (default: true)
            };
        },
        style() {
            const { x, y } = this.props;
            const { min, max } = this;
            const top = `${clamp(y, min, max) ?? 50}%`;
            const left = `${clamp(x, min, max) ?? 50}%`;
            return {
                top,
                left,
            };
        },
    },
    methods: {
        onClick(uuid) {
            this.openEditor(uuid);
        },
        drag({ target, clientY, clientX }) {
            if (this.isPreview && !this.previewMode) {
                const { offsetWidth: width, offsetHeight: height } =
                    target.parentElement;
                const overlay = target.parentElement;
                const { top, bottom, left, right } =
                    overlay.getBoundingClientRect();
                // https://stackoverflow.com/a/42207257
                const x = clamp(
                    (Math.floor(((clientX - left) / (right - left)) * width) /
                        width) *
                        100,
                    this.min,
                    this.max,
                );
                const y = clamp(
                    (Math.floor(((clientY - top) / (bottom - top)) * height) /
                        height) *
                        100,
                    this.min,
                    this.max,
                );

                this.$set(this.props, 'x', x);
                this.$set(this.props, 'y', y);
            }
        },
    },
};
</script>

<style scoped>
.hs {
    position: absolute;
    z-index: 10000;
}
</style>

<style>
.moveable-control-box {
    opacity: 0;
}
</style>
