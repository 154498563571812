<template>
    <!-- Every UI component has a wrapper container,
    so that the it can have a DragHandle and an Adder
    component next to the main element

    TODO probably a better way to achieve this, in a more
    DRY way instead of injecting DragHandle & Adder into
    every UI component. Perhaps handling in RenderComponent-->
    <div class="accordion-container" :style="style">
        <!-- Added to each eligible UI component to drag/drop -->
        <DragHandle />

        <!-- The main component -->
        <div
            :id="elementID"
            class="ca-component ca-accordion ca-parent--pagesection accordion"
            :class="classList"
            role="tablist"
        >
            <!-- If in the editor & in editor mode (not preview mode)
                allow drag/drop of the accordion children/items -->
            <draggable
                v-if="isPreview && !previewMode && componentData"
                :value="componentData.children"
                class="draggable"
                group="accordion"
                @change="refreshPreviewOrder(componentData.uuid)"
            >
                <slot></slot>
            </draggable>

            <!-- Else, if in preview mode, just render the children components -->
            <slot v-else></slot>

            <!-- Show an adder component to quickly add sibling (not children)
            components below the accordion -->
            <Adder
                v-if="componentData"
                :component-data="componentData.parentData"
                :child-u-u-i-d="componentData.uuid"
            />
        </div>
    </div>
</template>

<script>
// At the moment, these components/libraries are not
// added to the global editor mixin because
// not all UI components use them
import { head } from 'lodash-es';
import helpers from '../mixins/helpers';
import Adder from './editor/Adder';
import DragHandle from './editor/DragHandle';
import componentInlineStyle from '~/utils/componentInlineStyle';

export default {
    name: 'Accordion',
    components: {
        Adder,
        DragHandle,
    },
    mixins: [helpers], // For components that need editable paddings/margins
    props: {
        // this prop will be present on every component and contains
        // the component data, ie props, children and parentData
        componentData: Object,
    },
    data() {
        return {
            // All UI components simplify componentData.props to attrs
            // purely for readability/simplicity
            attrs: this.componentData ? this.componentData.props || {} : {},
        };
    },
    computed: {
        style() {
            return {
                ...componentInlineStyle(this.attrs, this.getColor),
            };
        },
        // All UI components have computed classlists & element IDs
        // based on the componentData.props.element_class & element_id
        classList() {
            return [this.attrs.element_class || '', ...this.helperClasses];
        },
        elementID() {
            return this.attrs.element_id || '';
        },
    },
    mounted() {
        const first = head(this.componentData?.children);
        if (first) {
            first.open = true;
        }
    },
};
</script>

<style lang="scss">
.accordion-container {
    display: flex;
    flex-direction: column;
    position: relative;
}

.accordion {
    position: relative;

    & > div.card {
        overflow: visible;
    }
}

.draggable .accordion-container:hover {
    .drag-handle,
    .adder {
        opacity: 1;
        z-index: 100;
    }
}
</style>
