<template>
    <header
        :id="elementID"
        :class="classList"
        class="ca-pageheader page-header shadow"
    >
        <b-navbar
            :id="elementID"
            :class="classList"
            :toggleable="responsiveToggle"
        >
            <slot></slot>
        </b-navbar>
    </header>
</template>

<script>
export default {
    name: 'PageHeader',
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
        };
    },
    computed: {
        classList() {
            return [this.attrs.element_class];
        },
        elementID() {
            return this.attrs.element_id || '';
        },
        responsiveToggle() {
            if (this.isPreview) {
                const previewResponsiveMode = this.$store.state.responsiveMode;
                const toggleBreakpoint = this.attrs.toggle_menu_text;

                if (previewResponsiveMode === 'xs') {
                    return true;
                } else if (previewResponsiveMode === 'sm') {
                    return ['sm', 'md', 'lg', 'xl'].includes(toggleBreakpoint);
                } else if (previewResponsiveMode === 'md') {
                    return ['md', 'lg', 'xl'].includes(toggleBreakpoint);
                } else if (previewResponsiveMode === 'lg') {
                    return ['lg', 'xl'].includes(toggleBreakpoint);
                } else {
                    return ['xl'].includes(toggleBreakpoint);
                }
            } else {
                return this.attrs.toggle_menu_text;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.page-header {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    position: relative;

    nav.navbar {
        flex-direction: column;
        padding: 0;
    }
}
</style>
