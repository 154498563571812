<template>
    <div
        :id="elementID"
        class="column ca-component ca-column"
        :style="style"
        :class="classList"
        @click="doClickAction"
    >
        <draggable
            v-if="isPreview && !previewMode"
            v-model="
                // eslint-disable-next-line vue/no-mutating-props
                componentData.children
            "
            handle=".drag-handle"
            class="draggable"
            group="Column"
            @change="refreshPreviewOrder(componentData.uuid)"
        >
            <slot></slot>
        </draggable>

        <slot v-else></slot>

        <Adder :component-data="componentData" />
    </div>
</template>

<script>
import helpers from '../mixins/helpers';
import Adder from './editor/Adder';

export default {
    name: 'Column',
    components: {
        Adder,
    },
    mixins: [helpers],
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
        };
    },
    computed: {
        style() {
            return {
                flexGrow: this.attrs.flex_grow,
            };
        },
        classList() {
            return [
                'align-items-' + this.attrs.align_content,
                ...this.helperClasses,
                this.attrs.element_class,
            ];
        },
        elementID() {
            return this.attrs.element_id || '';
        },
    },
};
</script>

<style lang="scss" scoped>
.column {
    padding: 0.5em;
    display: flex;
    flex-direction: column;
    position: relative;
    flex-grow: 1;
    flex-shrink: 1;
}
</style>
