/**
 * Parse youtube video url and extract id
 * @param {*} url youtube video url
 * @returns youtube video id
 */
export default function getYoutubeIdFromUrl(url) {
    if (!url) {
        return;
    }
    const youtubeRegexp =
        /https?:\/\/(?:[0-9A-Z-]+\.)?(?:youtu\.be\/|youtube(?:-nocookie)?\.com\S*[^\w\s-])([\w-]{11})(?=[^\w-]|$)(?![?=&+%\w.-]*(?:['"][^<>]*>|<\/a>))[?=&+%\w.-]*/gi;

    let id = url.replace(youtubeRegexp, '$1');

    if (id.includes(';')) {
        const pieces = id.split(';');

        if (pieces[1].includes('%')) {
            const uriComponent = decodeURIComponent(pieces[1]);
            id = ('http://youtube.com' + uriComponent).replace(
                youtubeRegexp,
                '$1',
            );
        } else {
            id = pieces[0];
        }
    } else if (id.includes('#')) {
        id = id.split('#')[0];
    }

    return id;
}
