<template>
    <div :id="elementID" class="nav-info ca-navinfo" :class="classList">
        <draggable
            v-if="isPreview && !previewMode"
            v-model="
                // eslint-disable-next-line vue/no-mutating-props
                componentData.children
            "
            handle=".drag-handle"
            group="navInfo"
            @change="refreshPreviewOrder(componentData.uuid)"
        >
            <slot></slot>
        </draggable>

        <slot v-else></slot>
    </div>
</template>

<script>
import helpers from '../mixins/helpers';
export default {
    name: 'NavInfo',
    mixins: [helpers],
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
        };
    },
    computed: {
        classList() {
            return [this.attrs.element_class, ...this.helperClasses];
        },
        elementID() {
            return this.attrs.element_id;
        },
    },
};
</script>

<style lang="scss" scoped>
.nav-info {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    justify-content: center;
    height: 100%;
}
</style>

<style lang="scss">
div.nav-info {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        margin-bottom: 0 !important;
    }
}
</style>
