<template>
    <span class="modal-container">
        <style>
            {{ css }}
        </style>

        <div
            v-if="isPreview && isModalEditor"
            :id="elementID"
            :style="style"
            :class="classList"
            class="modal-preview"
        >
            <div v-if="attrs.show_close" class="preview-modal-header">
                <div class="close-btn">
                    <font-awesome-icon :icon="['far', 'times']" />
                </div>
            </div>

            <slot />
        </div>

        <b-modal
            v-else
            :id="elementID"
            v-model="show"
            modal-class="custom-modal"
            :size="attrs.size"
            :class="classList"
            hide-footer
            :title="attrs.title"
            :no-close-on-esc="!attrs.close_on_esc"
            :hide-header-close="!attrs.show_close"
            :no-fade="!attrs.fade"
            :centered="attrs.vertically_center"
            static
            @hidden="hide()"
        >
            <template #modal-header="{ close }">
                <b-button size="md" @click="close()">
                    <small>
                        <font-awesome-icon :icon="['fal', 'times']" />&nbsp;
                        CLOSE
                    </small>
                </b-button>
            </template>

            <slot></slot>
        </b-modal>
    </span>
</template>

<script>
export default {
    name: 'Modal',
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
            show: true,
        };
    },
    computed: {
        style() {
            let size = '720px';

            if (this.attrs.size === 'sm') {
                size = '540px';
            } else if (this.attrs.size === 'lg') {
                size = '1280px';
            } else if (this.attrs.size === 'xl') {
                size = '1920px';
            }

            return {
                maxWidth: size,
                alignSelf: this.attrs.vertically_center
                    ? 'center'
                    : 'flex-start',
            };
        },
        classList() {
            return this.attrs.element_class || '';
        },
        elementID() {
            return this.attrs.element_id || '';
        },
        css() {
            return this.attrs.css;
        },
        isModalEditor() {
            return (
                this.$route.path.includes('/modal') ||
                this.$store.getters['modals/editing']
            );
        },
    },
    methods: {
        hide() {
            this.$store.commit('modals/setActive', null);
            this.$root.$emit('modals:setediting', null);
        },
    },
};
</script>

<style lang="scss" scoped>
.modal-container {
    width: 100%;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .card-body > * {
        width: 100%;
    }

    .modal-preview {
        background: $light;
        display: flex;
        flex-direction: column;
        width: 100%;

        div.media-item-container {
            margin-bottom: 0 !important;
        }

        .preview-modal-header {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            background: $secondary;

            .close-btn {
                color: $light;
                padding: 0.125em 0.5em;
            }
        }
    }
}
</style>
