<template>
    <!--
        This DragHandle component is added to certain
        UI components in ../components/...
        to allow drag/drop in the editor preview.
        Must be placed inside a <draggable> component
        with prop handle=".drag-handle"
    -->
    <div
        v-if="!previewMode && isPreview && false"
        class="drag-handle shadow"
        @click.stop
    >
        <font-awesome-icon :icon="['far', 'expand-arrows']" />
    </div>
</template>

<script>
export default {
    name: 'DragHandle',
};
</script>

<style lang="scss" scoped>
div.drag-handle {
    display: block;
    position: absolute;
    right: 0;
    width: 25px;
    height: 25px;
    top: 0;
    opacity: 0;
    transition: 0.3s ease;
    color: $light;
    background: $dark;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    border: none;
    border-radius: 4px;
    padding: 0;
    min-width: unset;
}
</style>
