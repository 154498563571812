const legacyColorKeys = [
    'brand',
    'brand_secondary',
    'primary',
    'tertiary',
    'danger',
    'orange',
    'pink',
    'warning',
    'success',
    'purple',
    'dark',
    'secondary',
    'light',
];

export default legacyColorKeys;
