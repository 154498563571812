import Vue from 'vue';

export const state = () => ({
    active: [], // Active maps
    activeDrawIDs: [], // Active draw feature IDs (currently selected in map)
    activeSidebarID: null,
    activeCategory: 'Default',
});

export const getters = {
    active(state) {
        return state.active;
    },
    activeSidebarID(state) {
        return state.activeSidebarID;
    },
    activeCategory(state) {
        return state.activeCategory;
    },
};

export const mutations = {
    setActiveSidebar(state, sidebarID) {
        state.activeSidebarID = sidebarID;
    },
    setActiveCategory(state, category) {
        state.activeCategory = category;
    },
    setSidebar(state, data) {
        const map = state.active.find((m) => m.id === data.map_id);
        Vue.set(map, 'sidebar', data.sidebar_id);
    },
    setKeys(state, data) {
        const map = state.active.find((m) => m.id === data.map_id);
        Vue.set(map, 'keys', data.keyIDs);
    },
    setLayers(state, data) {
        const map = state.active.find((m) => m.id === data.map_id);
        Vue.set(map, 'layers', data.layerIDs);
    },
    setActive(state, map) {
        const mapState = {
            id: map.id,
            sidebar: map.primarySidebarID || null,
            keys: ['default'],
            layers: [],
        };

        const mapIndex = state.active.findIndex((m) => m.id === map.id);
        if (mapIndex > -1) {
            state.active.splice(mapIndex, 1);
        }
        state.active.push(mapState);
    },
    setActiveDrawIDs: (state, ids) => {
        state.activeDrawIDs = ids;
    },
};
