import Vue from 'vue';
import { MglMap, MglMarker, MglPopup, MglScaleControl } from 'v-mapbox';
import Mapbox from 'mapbox-gl';

Vue.component('MglMap', MglMap);
Vue.component('MglMarker', MglMarker);
Vue.component('MglPopup', MglPopup);
Vue.component('MglScaleControl', MglScaleControl);

Vue.prototype.$mapbox = Mapbox;
