<template>
    <div
        v-if="!dismissed"
        class="message-banner container"
        :class="'justify-content-' + attrs.align"
        :style="style"
    >
        <div
            v-if="isPreview && !previewMode"
            class="message"
            @click="doClickAction"
        >
            <client-only>
                <Wysiwyg v-model="attrs.message" :mini="true" />
            </client-only>
        </div>

        <span v-else class="message" v-html="attrs.message" />

        <font-awesome-icon
            :icon="['fas', 'times']"
            class="text-danger dismiss-icon"
            @click="dismissed = true"
        />
    </div>
</template>

<script>
import Wysiwyg from './editor/Wysiwyg';
export default {
    name: 'HeaderSubnav',
    components: {
        Wysiwyg,
    },
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
            dismissed: false,
        };
    },
    computed: {
        style() {
            return {
                backgroundColor: this.getColor(this.attrs.bg_color),
                color: this.getColor(this.attrs.color),
            };
        },
        classList() {
            return [this.attrs.element_class];
        },
        elementID() {
            return this.attrs.element_id || '';
        },
    },
};
</script>

<style lang="scss">
div.container.message-banner {
    width: 100%;
    min-height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    .message,
    p {
        font-size: 0.875em !important;
        margin-bottom: 0;
        height: unset;
        text-align: center;
    }

    .dismiss-icon {
        position: absolute;
        right: 1em;
        font-size: 0.875em;
        cursor: pointer;
        transition: 0.3s ease;

        &:hover {
            filter: brightness(0.8);
        }
    }
}
</style>
