<template>
    <div class="youtube-container">
        <DragHandle />

        <LazyYoutubeVideo
            v-if="src"
            :src="src"
            :autoplay="false"
            preview-image-size="hqdefault"
        />

        <div v-else class="placeholder-vid">
            <div v-if="isPreview && !previewMode" class="url-input">
                <p>Enter YouTube url:</p>

                <div class="input-container">
                    <client-only>
                        <Wysiwyg v-model="srcInput" :mini="true" />
                    </client-only>

                    <button class="btn btn-primary btn-sm" @click="updateSrc">
                        Go
                    </button>
                </div>
            </div>
        </div>

        <Adder
            :component-data="componentData.parentData"
            :child-u-u-i-d="componentData.uuid"
        />
    </div>
</template>

<script>
import DragHandle from './editor/DragHandle';
import Adder from './editor/Adder';
import Wysiwyg from './editor/Wysiwyg';
import getYoutubeIdFromUrl from '~/utils/getYoutubeIdFromUrl';
export default {
    name: 'Youtube',
    components: {
        Wysiwyg,
        Adder,
        DragHandle,
    },
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
            srcInput: '',
        };
    },
    computed: {
        classList() {
            return this.attrs.element_class || '';
        },
        elementID() {
            return this.attrs.element_id || '';
        },
        src() {
            if (this.attrs.src) {
                const videoID = this.getIdFromURL(this.attrs.src);
                return videoID;
            } else {
                return null;
            }
        },
    },
    methods: {
        updateSrc(e) {
            this.attrs.src = this.srcInput.replace(/(<([^>]+)>)/gi, '');
        },
        getIdFromURL(url) {
            const id = getYoutubeIdFromUrl(url);
            return `https://www.youtube.com/embed/${id}`;
        },
    },
};
</script>

<style lang="scss">
.youtube-container {
    display: flex;
    flex-direction: column;
    position: relative;

    &:hover {
        .drag-handle,
        .adder {
            opacity: 1;
        }
    }

    .placeholder-vid {
        background: $dark;
        width: 100%;
        padding-top: 56.25%;
        position: relative;

        .url-input {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $light;
            flex-direction: column;

            .input-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                height: 32px;
                width: 80%;
                max-width: 500px;

                .btn {
                    height: 32px;
                    margin-left: 0.25em;
                    position: relative;
                }

                .wysiwyg {
                    background: white;
                    border-radius: 2px;
                    height: 32px;

                    &.mini div.ProseMirror {
                        padding: 0.25em 0.5em !important;
                        box-sizing: border-box;
                        border: none;

                        p {
                            color: $dark;
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}
</style>
