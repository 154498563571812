<template>
    <div
        :id="elementID"
        :class="classList"
        class="embed-responsive embed-responsive-16by9"
        @click="doClickAction"
    >
        <iframe
            class="embed-responsive-item"
            :src="componentData.value"
            :autoplay="attrs ? attrs.autoplay : false"
            width="1920"
            height="1080"
            frameborder="0"
            webkitallowfullscreen
            mozallowfullscreen
            allowfullscreen
        ></iframe>
    </div>
</template>

<script>
export default {
    name: 'VideoIframe',
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
        };
    },
    computed: {
        classList() {
            return this.attrs.element_class || '';
        },
        elementID() {
            return this.attrs.element_id || '';
        },
    },
};
</script>
