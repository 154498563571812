<template>
    <div class="card-container" @click="doClickAction">
        <a
            :id="elementID"
            :class="classList"
            v-bind="{ href, target, download }"
            class="card ca-component ca-cards"
            @click="doClickAction"
        >
            <div class="card-content">
                <slot></slot>
            </div>

            <a
                v-if="attrs.footer"
                class="card-footer"
                :style="{ fontFamily: projectConfig.fontFamily }"
                :href="attrs.footer_href"
            >
                {{ attrs.footer_text || 'View more' }}
            </a>
        </a>

        <Adder
            alignment="row"
            :component-data="componentData.parentData"
            :child-u-u-i-d="componentData.uuid"
        />
    </div>
</template>

<script>
import actions from '../mixins/actions';
import helpers from '../mixins/helpers';
import Adder from './editor/Adder';
import onClickActions from '~/mixins/onClickActions';
export default {
    name: 'Card',
    components: {
        Adder,
    },
    mixins: [actions, helpers, onClickActions],
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
        };
    },
    computed: {
        classList() {
            return [this.attrs.element_class || '', ...this.helperClasses];
        },
        elementID() {
            return this.attrs.element_id || '';
        },
    },
};
</script>

<style lang="scss" scoped>
.card-container {
    .card {
        color: inherit;
        position: relative;

        .card-content {
            height: 100%;
            max-width: 100%;
        }

        .card-footer {
            display: flex;
            cursor: pointer;

            color: inherit;
            text-decoration: none;
        }
    }
}
</style>
