<template>
    <div
        :id="elementID"
        class="social-icons"
        :style="style"
        :class="classList"
        style="border-bottom: 8px solid rgba(0, 0, 0, 0)"
        @click="doClickAction"
    >
        <a
            v-if="attrs.facebook"
            target="_blank"
            style="
                border-left: 8px solid rgba(0, 0, 0, 0);
                border-right: 8px solid rgba(0, 0, 0, 0);
                text-align: center;
                text-decoration: none;
            "
            :href="attrs.facebook"
        >
            <img
                src="https://editor.caapp.com.au/icons/facebook.png"
                height="20px"
                width="20px"
            />
        </a>

        <a
            v-if="attrs.instagram"
            target="_blank"
            style="
                border-left: 8px solid rgba(0, 0, 0, 0);
                border-right: 8px solid rgba(0, 0, 0, 0);
                text-align: center;
                text-decoration: none;
            "
            :href="attrs.instagram"
        >
            <img
                src="https://editor.caapp.com.au/icons/instagram.png"
                height="20px"
                width="20px"
            />
        </a>

        <a
            v-if="attrs.twitter"
            target="_blank"
            style="
                border-left: 8px solid rgba(0, 0, 0, 0);
                border-right: 8px solid rgba(0, 0, 0, 0);
                text-align: center;
                text-decoration: none;
            "
            :href="attrs.twitter"
        >
            <img
                src="https://editor.caapp.com.au/icons/twitter.png"
                height="20px"
                width="20px"
            />
        </a>

        <a
            v-if="attrs.linkedin"
            target="_blank"
            style="
                border-left: 8px solid rgba(0, 0, 0, 0);
                border-right: 8px solid rgba(0, 0, 0, 0);
                text-align: center;
                text-decoration: none;
            "
            :href="attrs.linkedin"
        >
            <img
                src="https://editor.caapp.com.au/icons/linkedin.png"
                height="20px"
                width="20px"
            />
        </a>

        <a
            v-if="attrs.youtube"
            target="_blank"
            style="
                border-left: 8px solid rgba(0, 0, 0, 0);
                border-right: 8px solid rgba(0, 0, 0, 0);
                text-align: center;
                text-decoration: none;
            "
            :href="attrs.youtube"
        >
            <img
                src="https://editor.caapp.com.au/icons/youtube.png"
                height="20px"
                width="20px"
            />
        </a>
    </div>
</template>

<script>
import componentInlineStyle from '~/utils/componentInlineStyle';
export default {
    name: 'SocialIcons',
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
        };
    },
    computed: {
        style() {
            return {
                color: this.getColor(this.attrs.text_color),
                ...componentInlineStyle(this.attrs, this.getColor),
            };
        },
        classList() {
            if (!this.attrs) return [];
            return [this.attrs.element_class || ''];
        },
        elementID() {
            if (!this.attrs) return;
            return this.attrs.element_id || '';
        },
    },
};
</script>

<style lang="scss">
div.social-icons {
    a {
        color: inherit;
    }
}
</style>
