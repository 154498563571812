<template>
    <div class="button-link-container" :class="attrs.block ? 'block' : ''">
        <a
            :id="elementID"
            :href="href"
            :target="attrs.open_new_tab ? '_blank' : null"
            :download="attrs.on_click === 'download'"
            :style="style"
            class="button-link btn scroll ca-component ca-buttonlink"
            :class="classList"
            @click="doClickAction"
        >
            <template v-if="attrs.show_icon && attrs.icon_pos === 'left'">
                <font-awesome-icon :icon="['fas', attrs.icon]" />
            </template>

            <span class="mx-2">
                {{ componentData.value }}
            </span>

            <template v-if="attrs.show_icon && attrs.icon_pos !== 'left'">
                <font-awesome-icon :icon="['fas', attrs.icon]" />
            </template>
        </a>

        <Adder
            v-if="isPreview && !previewMode"
            alignment="row"
            :component-data="componentData.parentData"
            :child-u-u-i-d="componentData.uuid"
        />
    </div>
</template>

<script>
import actions from '../mixins/actions';
import helpers from '../mixins/helpers';
import Adder from './editor/Adder';
import onClickActions from '~/mixins/onClickActions';
export default {
    name: 'ButtonLink',
    components: {
        Adder,
    },
    mixins: [actions, helpers, onClickActions],
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
        };
    },
    computed: {
        classList() {
            return [
                `btn-${this.attrs.size || 'md'}`,
                `text-${this.attrs.text || 'dark'}`,
                this.attrs.element_class || '',
                ...this.helperClasses,
            ];
        },
        style() {
            return {
                backgroundColor: this.getColor(this.attrs.bg_color),
                color: this.getColor(this.attrs.text),
                fontFamily: this.projectConfig.fontFamily,
            };
        },
        elementID() {
            return this.attrs.element_id || '';
        },
    },
};
</script>

<style lang="scss" scoped>
.button-link-container {
    margin: 0 0.5rem 0.5rem 0;

    &.block {
        width: 100%;

        a.button-link {
            width: 100%;
        }
    }

    &:hover {
        .drag-handle,
        .adder {
            opacity: 1;
        }
    }
    a.button-link {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: relative;
    }
}
</style>
