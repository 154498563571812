<template>
    <div class="footer-links-container" :class="classList">
        <draggable
            v-if="isPreview && !previewMode"
            v-model="
                // eslint-disable-next-line vue/no-mutating-props
                componentData.children
            "
            class="footer-links"
            group="footerLinks"
            @change="refreshPreviewOrder(componentData.uuid)"
        >
            <slot></slot>
        </draggable>

        <div v-else class="footer-links">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'FooterLinks',
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
        };
    },
    computed: {
        classList() {
            const alignment = this.attrs.align;
            return [
                this.attrs ? this.attrs.element_class : '',
                'align-' + alignment,
            ];
        },
        elementID() {
            return this.attrs ? this.attrs.element_id : '';
        },
    },
};
</script>

<style lang="scss" scoped>
.footer-links-container {
    display: inline-block;

    &.align-row {
        .footer-links {
            flex-wrap: wrap;
        }
    }

    &.align-stacked {
        .footer-links {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    .footer-links {
        margin: 0 0.5em 0.25em 0.5em;
        display: flex;
        position: relative;
        width: 100%;
        flex-wrap: wrap;
    }
}
</style>
