<template>
    <td
        :style="{
            borderSpacing: '16px',
            verticalAlign: 'top',
            border: '1px solid #f3f6f8',
        }"
    >
        <a
            :id="elementID"
            :class="classList"
            :href="href"
            :target="attrs.open_new_tab ? '_blank' : null"
            :download="attrs.on_click === 'download'"
            class="card"
        >
            <div class="card-content">
                <slot></slot>
            </div>

            <a
                v-if="attrs.footer"
                class="card-footer"
                :style="{ fontFamily: projectConfig.fontFamily }"
                :href="attrs.footer_href"
            >
                <p>{{ attrs.footer_text || 'View more' }}</p>
            </a>
        </a>
    </td>
</template>

<script>
import onClickActions from '~/mixins/onClickActions';
export default {
    name: 'Card',
    mixins: [onClickActions],
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
        };
    },
    computed: {
        classList() {
            return [this.attrs.element_class || '', ...this.helperClasses];
        },
        elementID() {
            return this.attrs.element_id || '';
        },
    },
};
</script>
