export const state = () => ({
    context: null,
    modal: null,
    genericModal: null,
    editorPage: null,
    accordion: null,
    isPreview: false,
    previewMode: false,
    responsiveMode: 'xl',
});

export const getters = {
    modal(state) {
        return state.modal;
    },
    genericModal(state) {
        return state.modal;
    },
    isPreview(state) {
        return state.isPreview;
    },
    previewMode(state) {
        return state.previewMode;
    },
};

export const mutations = {
    setModal: (state, val) => {
        console.log('setting modal to: ', val);
        state.modal = val;
    },
    setGenericModal: (state, data) => {
        state.genericModal = data;
    },
    setEditorPage: (state, slug) => {
        state.editorPage = '/' + slug;
    },
    setAccordion: (state, id) => {
        state.accordion = id;
    },
    setPreview: (state, val) => {
        state.isPreview = val;
    },
    setPreviewMode: (state, val) => {
        state.previewMode = val;
    },
    setResponsiveMode: (state, val) => {
        state.responsiveMode = val;
    },
    setContext: (state, val) => {
        state.context = val;
    },
};
