<template>
    <div class="work-activities-container">
        <div :id="elementID" :class="classList" class="work-activities">
            <div
                v-for="(wa, i) in filteredWorkActivities"
                :key="i"
                class="work-activity-container shadow"
            >
                <div
                    v-if="wa.published_status === 'draft'"
                    class="draft-banner"
                >
                    <font-awesome-icon
                        :icon="['fas', 'exclamation-triangle']"
                    />
                    <span
                        >Note this draft Work Activity will not appear in a
                        published or emailed News Post. Please publish this Work
                        Activity to include it in your News Post.</span
                    >
                </div>

                <div class="work-activity">
                    <div
                        class="map-container"
                        :style="{
                            backgroundImage: `url(${
                                wa.published_map_url || wa.draft_map_url
                            })`,
                            border: '5px solid #D3E5F2',
                            boxSizing: 'border-box',
                        }"
                    ></div>

                    <div class="content">
                        <div class="info">
                            <h5>{{ wa.name }}</h5>
                            <p>
                                <font-awesome-icon
                                    class="mr-2"
                                    :icon="['fal', 'map-marker']"
                                />
                                {{ wa.metadata.props.location }}
                            </p>
                            <p>
                                <font-awesome-icon
                                    class="mr-2"
                                    :icon="['fal', 'clock']"
                                />
                                {{
                                    $moment(
                                        new Date(wa.metadata.props.date_start),
                                    ).format($config.WORK_ACTIVITY_DATE_FORMAT)
                                }}
                                <template
                                    v-if="
                                        !wa.metadata.props.date_end ||
                                        wa.metadata.props.date_end !==
                                            wa.metadata.props.date_start
                                    "
                                >
                                    -
                                    {{
                                        wa.metadata.props.date_end
                                            ? $moment(
                                                  new Date(
                                                      wa.metadata.props.date_end,
                                                  ),
                                              ).format(
                                                  $config.WORK_ACTIVITY_DATE_FORMAT,
                                              )
                                            : 'ongoing'
                                    }}
                                </template>
                            </p>
                            <p v-if="wa.metadata.props.description">
                                <font-awesome-icon
                                    class="mr-2"
                                    :icon="['fal', 'info']"
                                />

                                <em>
                                    {{ wa.metadata.props.description }}
                                </em>
                            </p>
                        </div>
                        <div class="buttons">
                            <a
                                :href="wa.metadata.props.preview_url"
                                target="_blank"
                                class="btn btn-sm btn-block btn-primary"
                            >
                                <span>View more</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Adder
            :component-data="componentData.parentData"
            :child-u-u-i-d="componentData.uuid"
        />
    </div>
</template>

<script>
// import MapDisplay from '../components/map/MapDisplay';
import Adder from './editor/Adder';
import Project from '@/models/Project';
import isUrlValid from '~/utils/isUrlValid';
export default {
    name: 'WorkActivities',
    components: {
        // MapDisplay,
        Adder,
    },
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
            workActivities: [],
        };
    },
    computed: {
        elementID() {
            return this.attrs.element_id || '';
        },
        classList() {
            return [this.attrs.element_class || '', this.attrs.layout];
        },
        filteredWorkActivities() {
            const today = this.$moment().format('YYYY-MM-DD');
            const filter = this.attrs.content_status;
            const showDrafts = this.attrs.filter_by_status === 'All';
            const allPublishedIDs = this.workActivities
                .filter((w) => w.published_status === 'published')
                .map((w) => w.metadata.uuid);

            return this.workActivities
                .filter((wa) => {
                    if (!wa?.metadata?.props) {
                        return false;
                    }
                    if (wa.published_status.toLowerCase() !== 'published') {
                        if (
                            !showDrafts ||
                            allPublishedIDs.includes(wa.metadata.uuid)
                        ) {
                            return false;
                        }
                    }

                    if (filter === 'Custom') {
                        return (
                            this.attrs.work_activity_ids &&
                            this.attrs.work_activity_ids.includes(
                                wa.metadata.uuid,
                            )
                        );
                    }

                    const waStart = this.$moment(wa.metadata.props.date_start);
                    const waEnd = this.$moment(wa.metadata.props.date_end);

                    if (filter === 'Upcoming and active') {
                        return (
                            !wa.metadata.props.date_end || waEnd.isAfter(today)
                        );
                    } else if (filter === 'Upcoming') {
                        return waStart.isAfter(today);
                    } else if (filter === 'Active') {
                        return (
                            waStart.isBefore(today) &&
                            (!wa.metadata.props.date_end ||
                                waEnd.isAfter(today))
                        );
                    } else if (filter === 'Previous') {
                        return (
                            wa.metadata.props.date_end && waEnd.isBefore(today)
                        );
                    } else if (filter === 'Date range') {
                        return (
                            waStart.isBefore(this.attrs.active_to) &&
                            waStart.isAfter(this.attrs.active_from)
                        );
                    }

                    return null;
                })
                .sort(
                    (a, b) =>
                        this.$moment(b.metadata.props.date_start) -
                        this.$moment(a.metadata.props.date_start),
                );
        },
    },
    mounted() {
        if (!this.attrs.filter_by_status) {
            this.$set(this.attrs, 'filter_by_status', 'All');
        }

        if (!this.attrs.content_status) {
            this.$set(this.attrs, 'content_status', 'Active');
        }

        this.getWorkActivities();
    },
    methods: {
        async getWorkActivities() {
            const vm = this;
            const filter = this.attrs.content_status;
            if (filter === 'Custom') {
                const unfilterted = await this.fetchWorkActivities(
                    this.attrs.work_activity_ids,
                );
                const published = unfilterted.data.map(
                    // eslint-disable-next-line camelcase
                    ({ published_copy, id }) => published_copy ?? id,
                );
                const res = await this.fetchWorkActivities(published);
                vm.workActivities = res.data;
            } else {
                if (vm.$store.state.api.workActivities) {
                    vm.workActivities = vm.$store.state.api.workActivities;
                    return;
                }
                const res = await this.$store.dispatch(
                    'api/getAllContentBasic',
                    {
                        contentType: 'work_activity',
                        includeChildProjects: this.attrs.include_child_projects,
                    },
                );
                vm.workActivities = res;
            }
        },
        async fetchWorkActivities(ids) {
            const projectIdFilter = this.attrs.include_child_projects
                ? 'filter[project_id_with_children]'
                : 'filter[project_id]';
            return await this.$axios.$get('/contents', {
                params: {
                    'filter[type]': 'work_activity',
                    [projectIdFilter]: this.$store.state.api.projectID,
                    limit: '-1',
                    'filter[id]': ids,
                },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.work-activities {
    list-style-type: none;
    display: flex;
    color: $dark;

    .work-activity-container {
        margin-bottom: 1em;
    }

    .draft-banner {
        background: #dae6ec;
        padding: 1.5em;
        font-size: 14px;
        font-weight: 600;
        font-family: Arial, Helvetica, sans-serif;
        display: flex;
        flex-direction: row;
        align-items: center;

        .fa-exclamation-triangle {
            margin-right: 1em;
        }
    }

    &.list {
        flex-direction: column;
        flex-wrap: nowrap;

        .work-activity {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            .map-container {
                border-radius: 4px 0 0 4px;
                width: 100%;
                padding-top: 40%;
                min-height: 250px;
            }

            .content {
                flex-basis: 60%;
                flex-grow: 1;
            }

            .map-container {
                flex-basis: 40%;
                flex-grow: 1;
                min-width: 250px;
            }
        }
    }

    &.grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        grid-gap: 1rem;

        .work-activity {
            display: flex;
            flex-direction: column;

            .map-container {
                min-height: 250px;
                width: 100%;
                border-radius: 4px 0 0 4px;
            }

            .content {
                height: 100%;
            }
        }
    }

    .work-activity {
        background: #fff;

        .map-container {
            background-size: cover;
            background-position: center center;
        }

        .content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            height: auto;
            padding: 1em;
            background-color: #f3f6f8;

            .buttons {
                padding-top: 0.5em;
                color: $light;
            }

            h5,
            p {
                margin-bottom: 0.25em !important;
            }

            h5 {
                font-weight: 700;
            }

            svg {
                width: 30px;
                text-align: center;
            }
        }
    }
}
</style>
