<template>
    <b-nav-item-dropdown :text="componentData.value" @click="doClickAction">
        <div :style="style">
            <slot />
        </div>
    </b-nav-item-dropdown>
</template>

<script>
export default {
    name: 'NavDropdown',
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
        };
    },
    computed: {
        style() {
            return {
                fontWeight: this.attrs.font_weight,
                color: this.getColor(this.attrs.color),
            };
        },
    },
};
</script>
