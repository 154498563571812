<template>
    <div
        v-if="activeCategory === attrs.category"
        :id="elementID"
        class="map-sidebar"
        :class="classList"
    >
        <div
            class="heading mb-3"
            :style="{ backgroundColor: getColor(attrs.bg_color || 'primary') }"
        >
            <div
                v-if="attrs.category !== 'Default'"
                class="go-back-icon-container"
                @click="goBackToDefaultSidebar"
            >
                <font-awesome-icon
                    :icon="['far', 'arrow-circle-left']"
                    size="lg"
                    class="mr-2 text-light"
                />
            </div>

            <h4>{{ attrs.title }}</h4>
        </div>

        <div class="container">
            <slot></slot>
        </div>
    </div>
</template>

<script>
// import Accordion from '../components/Accordion';
// import AccordionItem from '../components/AccordionItem';
import actions from '../mixins/actions';

export default {
    name: 'MapSidebar',
    components: {
        // Accordion,
        // AccordionItem,
    },
    mixins: [actions],
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
        };
    },
    computed: {
        activeCategory() {
            const activeCategory = this.$store.getters['maps/activeCategory'];
            return activeCategory;
        },
        classList() {
            return [...(this.helperClasses || []), this.attrs.element_class];
        },
        elementID() {
            return this.attrs.element_id || '';
        },
    },
    methods: {
        goBackToDefaultSidebar() {
            this.$store.commit('maps/setActiveCategory', 'Default');
        },
    },
};
</script>

<style lang="scss" scoped>
.map-sidebar {
    background: $light;
    padding: 0;
    height: 100%;
    overflow: scroll;
    flex-grow: 0;
    flex-shrink: 0;

    .loading {
        padding: 1rem;
        text-align: center;
    }

    .heading {
        background: $primary;
        padding: 1rem;
        display: flex;
        align-items: center;

        h4 {
            color: #fff;
            margin-bottom: 0;
            font-size: 21px;
        }

        .go-back-icon-container {
            cursor: pointer;
            pointer-events: all;
        }
    }
}
</style>
