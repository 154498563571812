var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pages-container ca-component ca-pages",on:{"click":_vm.doClickAction}},[(_vm.loading)?_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"mb-3"},[_vm._v("Pages Loading...")]),_vm._v(" "),_c('b-spinner',{staticStyle:{"width":"150px","height":"150px"},attrs:{"variant":"primary","label":"Spinner"}})],1):(_vm.error)?_c('div',{staticClass:"text-center"},[_vm._v("Error loading Pages")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"pages",class:_vm.classList,attrs:{"id":_vm.elementID}},_vm._l((_vm.filteredPages),function(p,i){return _c('div',{key:i,staticClass:"page-container shadow",attrs:{"click.stop":"doClick(p)"}},[(p.published_status === 'draft')?_c('div',{staticClass:"draft-banner"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'exclamation-triangle']}}),_vm._v(" "),_c('span',[_vm._v("\n                    Note this draft Page will not appear in published\n                    content. Please publish this Page to include it in your\n                    content.\n                ")])],1):_vm._e(),_vm._v(" "),_c('b-card',{staticClass:"page-item ca-contents-content ca-card card",attrs:{"header-class":"position-relative p-0","no-body":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"thumbnail-container ca-thumbnail ca-contents-content-thumbnail"},[_c('b-img-lazy',{staticClass:"img-fluid",attrs:{"src":p.metadata.props.thumbnail
                                    ? p.metadata.props.thumbnail.url
                                    : 'https://ca-v2.s3-ap-southeast-2.amazonaws.com/placeholder.jpg',"alt":"","width":"640","height":"360","loading":"lazy"}})],1)]},proxy:true}],null,true)},[_vm._v(" "),_c('b-card-body',{staticClass:"content ca-contents-content-cardbody ca-cardbody card-body"},[_c('div',{staticClass:"info"},[_c('h5',{staticClass:"ca-heading ca-contents-content-title",style:({
                                fontFamily: _vm.projectConfig.fontFamily,
                            })},[_vm._v("\n                            "+_vm._s(p.metadata.props.title)+"\n                        ")]),_vm._v(" "),(_vm.attrs.show_date && p.published_at)?_c('p',{staticClass:"ca-contents-content-date",style:({
                                fontFamily: _vm.projectConfig.fontFamily,
                            })},[_vm._v("\n                            "+_vm._s(_vm.$moment(new Date(p.published_at)).format(
                                    'D MMMM, YYYY'
                                ))+"\n                        ")]):_vm._e(),_vm._v(" "),(_vm.attrs.show_excerpt && p.description)?_c('p',{staticClass:"description ca-contents-content-excerpt text-truncate-description",staticStyle:{"overflow":"hidden","display":"-webkit-box","-webkit-line-clamp":"3","-webkit-box-orient":"vertical"},style:({
                                fontFamily: _vm.projectConfig.fontFamily,
                            })},[_c('span',{domProps:{"innerHTML":_vm._s(p.description)}})]):_vm._e()])])],1),_vm._v(" "),_c('b-modal',{attrs:{"id":'modal-' + p.id,"hide-footer":"","modal-class":"custom-modal","size":"md","centered":""},scopedSlots:_vm._u([{key:"modal-header",fn:function(ref){
                            var close = ref.close;
return [_c('b-button',{attrs:{"size":"md"},on:{"click":function($event){return close()}}},[_c('small',{style:({
                                fontFamily: _vm.projectConfig.fontFamily,
                            })},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'times']}}),_vm._v("  CLOSE\n                        ")],1)])]}}],null,true)},[_vm._v(" "),_c('div',{staticClass:"-item-content"},[_c('div',{staticClass:"heading"},[_c('h3',{style:({
                                fontFamily: _vm.projectConfig.fontFamily,
                            })},[_vm._v("\n                            "+_vm._s(p.metadata.props.title)+"\n                        ")])]),_vm._v(" "),_c('RenderComponent',{attrs:{"component-data":p.metadata}})],1)])],1)}),0),_vm._v(" "),_c('DragHandle'),_vm._v(" "),_c('Adder',{attrs:{"component-data":_vm.componentData.parentData,"child-u-u-i-d":_vm.componentData.uuid}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }