<template>
    <div
        :id="elementID"
        class="secondary-nav container-fluid"
        :style="style"
        @click="doClickAction"
    >
        <div class="container" :class="classList">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HeaderSubnav',
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
        };
    },
    computed: {
        style() {
            return {
                backgroundColor: this.getColor(this.attrs.bg_color),
            };
        },
        classList() {
            return [
                'justify-content-' + this.attrs.align,
                this.attrs.element_class,
            ];
        },
        elementID() {
            return this.attrs.element_id || '';
        },
    },
};
</script>

<style lang="scss">
div.container-fluid.secondary-nav {
    width: 100%;
    min-height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 5px;
    padding-bottom: 5px;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    .paragraph,
    .heading {
        margin-bottom: 0;
        height: unset;
        font-size: 0.875em;
    }
}
</style>
