<template>
    <table
        v-if="galleryRows.length > 0"
        cellpadding="0"
        cellspacing="0"
        width="100%"
        class="gallery"
        style="borderspacing: 16px; margin: 16px 0; border-collapse: collapse"
    >
        <tbody>
            <tr
                v-for="(row, i) in galleryRows"
                :key="i"
                style="display: flex; width: 100%"
            >
                <GalleryItem v-for="(item, j) in row" :key="j" :item="item" />
            </tr>
        </tbody>
    </table>
</template>

<script>
import GalleryItem from './GalleryItem';

export default {
    name: 'Gallery',
    components: {
        GalleryItem,
    },
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
            galleryRows: [],
        };
    },
    created() {
        const galleryItems = this.componentData.children.filter(
            (gi) => gi.props.media && gi.show !== false,
        );
        this.galleryRows = this.chunkArray(galleryItems, 2);
    },
    methods: {
        chunkArray(arr, chunkSize) {
            let index = 0;
            const arrayLength = arr.length;
            const tempArray = [];

            for (index = 0; index < arrayLength; index += chunkSize) {
                const chunk = arr.slice(index, index + chunkSize);

                tempArray.push(chunk);
            }

            return tempArray;
        },
    },
};
</script>
