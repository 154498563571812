<template>
    <b-tab
        :id="elementID"
        :title="componentData.value"
        :class="classList"
        :style="{ fontFamily: projectConfig.fontFamily }"
        @click="doClickAction"
    >
        <slot></slot>
    </b-tab>
</template>

<script>
// import Adder from './editor/Adder';sz
export default {
    name: 'Tab',
    components: {
        // Adder,
    },
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
        };
    },
    computed: {
        classList() {
            if (!this.attrs) return [];
            return [this.attrs.element_class || ''];
        },
        elementID() {
            if (!this.attrs) return;
            return this.attrs.element_id || '';
        },
    },
};
</script>
