var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{staticClass:"social-icons",style:({
        padding: '0 8px',
        'Margin-left': 'auto',
        'Margin-right': 'auto',
        'border-spacing': '0 !important',
        'mso-table-lspace': '0pt',
        'mso-table-rspace': '0pt',
        'border-collapse': 'separate!important',
    })},[_c('tbody',[_c('tr',_vm._l((_vm.icons),function(icon){return _c('td',{key:icon.name,style:({ padding: '0 8px' })},[_c('a',{style:({
                        'text-align': 'center',
                        'text-decoration': 'none',
                    }),attrs:{"target":"_blank","href":_vm.attrs[icon.slug]}},[_c('img',{attrs:{"src":("https://editor.caapp.com.au/icons/" + (icon.slug) + ".png"),"height":"20","width":"20","alt":icon.name}})])])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }