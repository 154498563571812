<template>
    <div v-if="isPreview || attrs.media" class="media-item-container">
        <Component
            :is="href ? 'a' : 'div'"
            :id="elementID"
            class="media-item"
            :class="classList"
            :href="href"
            :target="attrs.open_new_tab ? '_blank' : undefined"
            :download="attrs.on_click === 'download'"
            @click="doClick"
        >
            <DragHandle />

            <MediaLoader
                v-if="showMedia"
                v-bind="{
                    height,
                    width,
                    alt,
                    quality,
                    media,
                    thumb,
                    lazy,
                    videoDisplay: attrs.video_display,
                }"
            />

            <PlaceholderImage v-else></PlaceholderImage>

            <slot></slot>
        </Component>

        <Adder
            :component-data="componentData.parentData"
            :child-u-u-i-d="componentData.uuid"
        />
    </div>
</template>

<script>
import helpers from '../mixins/helpers';
import MediaLoader from './MediaLoader';
import DragHandle from './editor/DragHandle';
import Adder from './editor/Adder';
import PlaceholderImage from './editor/PlaceholderImage';
import transformMedia from '~/utils/transformMedia';
import onClickActions from '~/mixins/onClickActions';
export default {
    name: 'Media',
    components: {
        MediaLoader,
        DragHandle,
        Adder,
        PlaceholderImage,
    },
    mixins: [helpers, onClickActions],
    props: {
        componentData: Object,
        thumb: { type: Boolean, default: false },
        lazy: { type: Boolean, default: false },
    },
    data() {
        return {
            attrs: this.componentData.props || {},
            media: this.componentData?.props?.media,
        };
    },
    computed: {
        width() {
            return Number(this.attrs.width ?? 720);
        },
        height() {
            return Number(this.attrs.height ?? 480);
        },
        alt() {
            return this.attrs.alt ?? this.attrs.media?.title;
        },
        quality() {
            return Number(this.attrs.quality ?? 70);
        },
        showMedia() {
            return this.attrs.media && this.attrs.media.id;
        },
        classList() {
            const classes = [
                this.attrs.element_class || '',
                ...this.helperClasses,
            ];
            if (this.$store.state.content.selectingMedia) {
                classes.push('is-loading');
            }
            return classes;
        },
        elementID() {
            return this.attrs.element_id || '';
        },
    },
    watch: {
        async 'attrs.media'() {
            this.media =
                (await this.getMedia(this.attrs.media)) ?? this.attrs.media;
        },
    },
    async mounted() {
        this.media =
            (await this.getMedia(this.attrs.media)) ?? this.attrs.media;
    },
    methods: {
        async getMedia(media) {
            try {
                if (media?.id) {
                    const { data: mediaObj } = await this.$store.dispatch(
                        'api/getMedia',
                        media.id,
                    );
                    return await transformMedia(mediaObj, this.$axios);
                }
            } catch (e) {
                console.error(e);
            }
        },
        doClick(event) {
            const vm = this;
            if (!vm.attrs.media || (vm.isPreview && !vm.previewMode)) {
                vm.doClickAction(event);
                setTimeout(() => {
                    if (!vm.showMedia) {
                        vm.$root.$emit(
                            'modals:setmedia',
                            vm.componentData.uuid,
                        );
                    }
                }, 500);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.media-item-container {
    display: flex;
    flex-direction: column;
}
.media-item {
    display: block;
    position: relative;

    &:hover {
        .drag-handle,
        .adder {
            opacity: 1;
        }
    }

    img {
        max-width: 100%;
    }

    &.is-loading:after {
        content: '';
        display: block;
        position: absolute;
        background-color: #fff;
        opacity: 0.4;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
    }
}
</style>
