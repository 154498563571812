<template>
    <div class="modals-container">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'Modals',
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
        };
    },
};
</script>
