<template>
    <div
        :id="elementID"
        class="main-nav container-fluid ca-component ca-headernav"
        :style="style"
        @click="doClickAction"
    >
        <div class="container" :class="classList">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HeaderNav',
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
        };
    },
    computed: {
        style() {
            return {
                backgroundColor: this.getColor(this.attrs.bg_color),
            };
        },
        classList() {
            return [this.attrs.element_class];
        },
        elementID() {
            return this.attrs.element_id || '';
        },
    },
};
</script>

<style lang="scss" scoped>
// nav.navbar {

.main-nav.container-fluid {
    min-height: 60px;
    padding: 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: stretch;

    & > div {
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }
}
// }
</style>
