<template>
    <div :id="elementID" class="carousel" :class="classList">
        <b-carousel
            v-if="attrs"
            :id="componentData.uuid"
            v-model="slideNumber"
            :interval="attrs.auto_slide ? attrs.slide_timer : 0"
            :controls="attrs.pagination"
            :indicators="attrs.pagination"
            :fade="attrs.slide_effect === 'fade'"
            :no-animation="attrs.slide_effect === 'none'"
            background="#ababab"
            style="text-shadow: 1px 1px 2px #333"
        >
            <slot></slot>
        </b-carousel>

        <Adder
            :component-data="componentData.parentData"
            :child-u-u-i-d="componentData.uuid"
        />
        <DragHandle></DragHandle>
    </div>
</template>

<script>
import helpers from '../mixins/helpers';
import DragHandle from './editor/DragHandle';
import Adder from './editor/Adder';
export default {
    name: 'Carousel',
    components: {
        DragHandle,
        Adder,
    },
    mixins: [helpers],
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props,
            showCarousel: false,
            resetting: false,
            slideNumber: 0,
        };
    },
    computed: {
        classList() {
            return [this.attrs.element_class, ...this.helperClasses];
        },
        elementID() {
            return this.attrs.element_id;
        },
    },
    mounted() {
        this.$root.$on('openSlide', (slideID) => {
            const slideIndex = this.componentData.children.findIndex(
                (s) => s.uuid === slideID,
            );
            this.slideNumber = slideIndex || 0;
        });
    },
    methods: {
        onSlideClick(slide, i) {
            this.openEditor(slide.uuid);
        },
    },
};
</script>

<style lang="scss">
.carousel {
    position: relative;
    max-width: 100%;
    box-sizing: content-box;

    &:hover {
        .drag-handle,
        .adder {
            opacity: 1;
        }
    }

    img {
        max-width: 100%;
    }
}
</style>
