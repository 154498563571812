export const state = () => ({
    drawMode: 'features',
    featureMode: 'all',
    featureGroup: 'all',
    featureSearch: '',
    features: [],
});

export const getters = {
    drawMode(state) {
        return state.drawMode;
    },
    featureMode(state) {
        return state.featureMode;
    },
    featureGroup(state) {
        return state.featureGroup;
    },
    featureSearch(state) {
        return state.featureSearch;
    },
    features(state) {
        return state.features;
    },
};

export const mutations = {
    setDrawMode(state, val) {
        state.drawMode = val;
    },
    setFeatureMode(state, val) {
        if (val === 'hotspots') {
            state.featureMode = 'Point';
        } else if (val === 'polygons') {
            state.featureMode = 'Polygon';
        } else if (val === 'lines') {
            state.featureMode = 'LineString';
        } else {
            state.featureMode = val;
        }
    },
    setFeatureGroup(state, val) {
        state.featureGroup = val;
    },
    setFeatureSearch(state, val) {
        state.featureSearch = val;
    },
    setFeatures(state, val) {
        state.features = val;
    },
};
