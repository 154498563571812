<template>
    <div style="pointer: cursor; display: flex" @click="doClickAction">
        <div class="image" style="display: none">
            <!-- used for rendering svg to png -->
            <img ref="image" alt="" />
            <canvas ref="canvas"></canvas>
        </div>
        <figure class="point" style="max-width: 100%; margin: auto">
            <svg
                ref="svg"
                viewbox="0 0 100 100"
                v-bind="{ width, height }"
                preserveAspectRatio="xMidYMid meet"
            >
                <circle
                    cx="50%"
                    cy="50%"
                    :r="`${radius}%`"
                    fill="none"
                    :stroke-dasharray="`${draw} 999`"
                    :stroke="lineBackgroundColor"
                    :stroke-width="`${lineWidth}%`"
                    stroke-linecap="round"
                />
                <circle
                    cx="50%"
                    cy="50%"
                    :r="`${radius}%`"
                    fill="none"
                    :stroke-dasharray="`${draw} 999`"
                    :stroke="lineColor"
                    :stroke-width="`${lineWidth}%`"
                    stroke-linecap="round"
                />
                <text
                    v-if="showNumber"
                    x="50%"
                    y="50%"
                    dominant-baseline="middle"
                    text-anchor="middle"
                    font-family="monospace"
                    font-size="16px"
                    :fill="textColor"
                    :stroke="textColor"
                    stroke-width="1"
                >
                    {{ percent }}%
                </text>
            </svg>
            <p v-if="text">{{ text }}</p>
        </figure>
    </div>
</template>

<script>
export default {
    name: 'Percent',
    props: {
        componentData: { type: Object, default: () => ({}) },
    },
    data() {
        return {
            radius: 40,
            lineColor: '#53b5fe',
            lineBackgroundColor: '#e4e4e4',
            textColor: '#53b5fe',
            lineWidth: 8,
        };
    },
    computed: {
        text() {
            const { supporting_text: text } = this.componentData.props ?? {};
            return text;
        },
        showNumber() {
            const { show_number: show } = this.componentData.props ?? {};
            return show;
        },
        size() {
            const sizes = {
                sm: 100,
                md: 200,
                lg: 300,
            };
            const { size } = this.componentData.props ?? {};
            return sizes[size];
        },
        percent() {
            const { percent } = this.componentData.props ?? {};
            return percent;
        },
        circumference() {
            return 2 * this.radius * Math.PI * (this.size / 100);
        },
        draw() {
            return this.calculateDraw(this.percent);
        },
        width() {
            return this.size;
        },
        height() {
            return this.size;
        },
        src() {
            return this.svgToPng();
        },
    },
    methods: {
        calculateDraw(percent, circumference = this.circumference) {
            return (percent * circumference) / 100;
        },
        async dataUrlToFile(dataUrl, fileName) {
            // https://stackoverflow.com/questions/35940290/how-to-convert-base64-string-to-javascript-file-object-like-as-from-file-input-f
            const res = await fetch(dataUrl);
            const blob = await res.blob();
            return new File([blob], fileName, {
                type: 'image/png',
            });
        },
        svgToPng() {
            // https://codepen.io/square0225/pen/QdvLQg
            // https://gist.github.com/gustavohenke/9073132
            const svg = this.$refs.svg;
            const svgData = new XMLSerializer().serializeToString(svg);
            const canvas = this.$refs.canvas;
            const ctx = canvas.getContext('2d');
            const img = this.$refs.image;
            const src = 'data:image/svg+xml;base64,' + btoa(svgData);
            img.setAttribute(src);

            img.onload = function () {
                ctx.drawImage(img, 0, 0);
                console.log(canvas.toDataURL('image/png'));
            };
            return src;
        },
    },
};
</script>
