<template>
    <div
        :id="elementID"
        class="card-thumb ca-component ca-thumbnail card-thumbnail"
        :class="classList"
        @click="doClickAction"
    >
        <span
            v-if="attrs.show_icon && attrs.icon && attrs.media"
            class="overlay-icon"
        >
            <font-awesome-icon :icon="['far', attrs.icon]" size="1x" />
        </span>

        <Media
            class="d-block img-fluid w-100 card-img-top ca-media-wrapper ca-media-image-wrapper"
            :component-data="componentData"
            thumb
            @click="doClickAction"
        />
    </div>
</template>

<script>
import helpers from '../mixins/helpers';
import Media from './Media';
export default {
    name: 'Thumbnail',
    components: {
        Media,
    },
    mixins: [helpers],
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
        };
    },
    computed: {
        classList() {
            if (!this.attrs) return;
            return [this.attrs.element_class || '', ...this.helperClasses];
        },
        elementID() {
            if (!this.attrs) return;
            return this.attrs.element_id || '';
        },
    },
};
</script>

<style lang="scss" scoped>
.card-thumb {
    overflow: hidden;
    position: relative;
    transition: 0.3s ease;

    &:hover {
        img,
        .card-img-top {
            filter: brightness(1.2);
            transform: scale(1.05);
        }
    }

    .overlay-icon {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        font-size: 75px;
        color: $light;
        justify-content: center;
        align-items: center;
        z-index: 3;
        background: rgba(0, 0, 0, 0.1);
        pointer-events: none;
    }

    img,
    .card-img-top {
        transition: 0.5s ease;
    }
}
</style>
