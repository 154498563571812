<template>
    <div
        :id="attrs.element_id"
        class="news-post"
        :class="classList"
        style="max-width: 720px"
    >
        <div
            v-if="attrs.thumb_as_hero && heroStyle"
            class="hero-wrapper"
            :style="heroStyle"
        ></div>

        <div v-if="isPreview && !previewMode" class="p-3">
            <span class="news-post-title">
                <h2 :style="{ color: getColor(attrs.title_color) }">
                    <client-only>
                        <Wysiwyg
                            v-model="title"
                            :mini="true"
                            @input="updateTitle"
                        />
                    </client-only>
                </h2>
            </span>

            <Adder :component-data="componentData" :first-index="true" />

            <draggable
                v-model="
                    // eslint-disable-next-line vue/no-mutating-props
                    componentData.children
                "
                handle=".drag-handle"
                @change="refreshPreviewOrder(componentData.uuid)"
            >
                <slot></slot>
            </draggable>
        </div>

        <div v-else class="p-3">
            <h2>
                <strong :style="{ fontFamily: projectConfig.fontFamily }">
                    {{ attrs.title }}
                </strong>
            </h2>

            <slot></slot>
        </div>
    </div>
</template>

<script>
// import MediaLoader from './MediaLoader';
import Adder from './editor/Adder';
import Wysiwyg from './editor/Wysiwyg';

export default {
    name: 'NewsPost',
    components: {
        // MediaLoader,
        Adder,
        Wysiwyg,
    },
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
            title: this.componentData.props.title,
        };
    },
    computed: {
        classList() {
            return [this.attrs.element_class || ''];
        },
        heroStyle() {
            if (!this.attrs.thumb_as_hero) {
                return null;
            }

            const url =
                this.attrs.thumb_as_hero === 'custom'
                    ? this.attrs.hero
                        ? this.attrs.hero.url
                        : null
                    : this.attrs.thumbnail
                    ? this.attrs.thumbnail.url
                    : null;

            if (!url) {
                return null;
            }

            return {
                backgroundImage: 'url(' + url + ')',
            };
        },
    },
    methods: {
        updateTitle(e) {
            this.attrs.title = e.replace(/(<([^>]+)>)/gi, '');
        },
    },
};
</script>

<style lang="scss">
.news-post {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    // align-items:center;
    // max-width: 720px;
    width: 100%;
    margin: 0 auto;

    .hero-wrapper {
        height: 0;
        padding-bottom: 56.25%;
        background-position: center center;
        background-size: cover;
    }

    .news-post-title {
        h2 {
            font-weight: 700;
        }
        .wysiwyg div.ProseMirror p {
            margin-bottom: 0;
        }
    }
}
</style>
