export default function removeSpecialChars(str) {
    if (!str || typeof str !== 'string') {
        return str;
    }
    return str
        .replaceAll('&lt;', '<')
        .replaceAll('&amp;', '&')
        .replaceAll('&gt;', '>')
        .replaceAll('&quot;', '"')
        .replaceAll('&apos;', "'");
}
