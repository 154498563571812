<template>
    <div class="copyright-text" :style="style" @click="doClickAction">
        &copy; {{ $moment().format('YYYY') }} {{ clientName }}
        <span :style="{ fontFamily: projectConfig.fontFamily }"
            >Powered by CA</span
        >
    </div>
</template>

<script>
import componentInlineStyle from '~/utils/componentInlineStyle';
export default {
    name: 'CopyrightText',
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
        };
    },
    computed: {
        style() {
            return {
                textAlign: this.attrs.align,
                color: this.getColor(this.attrs.text_color),
                ...componentInlineStyle(this.attrs, this.getColor),
            };
        },
        clientName() {
            return this.$store.getters['api/clientName'];
        },
    },
};
</script>

<style lang="scss">
.copyright-text {
    margin: 0;
    padding: 0;
    font-size: 0.75em;

    span {
        filter: brightness(0.8);
    }
}
</style>
