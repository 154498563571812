<template>
    <div>
        <h3
            :style="{ fontFamily: projectConfig.fontFamily }"
            v-html="attrs.title | stripHTML"
        ></h3>

        <slot />
    </div>
</template>

<script>
export default {
    name: 'AccordionItem',
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
        };
    },
};
</script>
