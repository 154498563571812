<template>
    <table
        class="work-activities"
        cellpadding="0"
        cellspacing="0"
        width="100%"
        :style="{
            width: '100%',
        }"
        valign="middle"
    >
        <tbody
            :style="{
                width: '100%',
                backgroundColor: '#fff',
            }"
        >
            <tr
                v-for="(wa, i) in workActivities"
                :key="i"
                :style="{
                    width: '100%',
                    borderBottom: '16px solid #fff',
                }"
            >
                <td align="center" valign="center">
                    <Spacer></Spacer>
                    <table
                        cellpadding="0"
                        cellspacing="0"
                        border="0"
                        role="presentation"
                        style="width: 100%"
                    >
                        <tr>
                            <td
                                valign="top"
                                align="center"
                                style="width: 300px"
                                class="work-activity-column-container"
                            >
                                <div
                                    :style="{
                                        display: 'inline-block',
                                        'vertical-align': 'middle',
                                    }"
                                >
                                    <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        border="0"
                                        role="presentation"
                                        style="width: 300px"
                                        class="work-activity-img-column"
                                    >
                                        <tr>
                                            <td
                                                :style="{
                                                    verticalAlign: 'top',
                                                    border: '1px solid #f3f6f8',
                                                }"
                                                align="center"
                                            >
                                                <a
                                                    :href="
                                                        wa.metadata.props
                                                            .preview_url
                                                    "
                                                >
                                                    <img
                                                        :src="
                                                            wa.published_map_url ||
                                                            wa.draft_map_url
                                                        "
                                                        width="300"
                                                        height="300"
                                                        :style="{
                                                            width: '300px',
                                                            'max-width':
                                                                '300px',
                                                            display: 'block',
                                                            border: '5px solid #d3e5f2',
                                                            'box-sizing':
                                                                'border-box',
                                                        }"
                                                        border="0"
                                                    />
                                                </a>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </td>
                            <td
                                valign="middle"
                                align="center"
                                style="width: 300px"
                                :style="{ backgroundColor: '#f3f6f8' }"
                                class="work-activity-column-container"
                            >
                                <div
                                    :style="{
                                        display: 'inline-block',
                                        'vertical-align': 'middle',
                                    }"
                                >
                                    <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        border="0"
                                        role="presentation"
                                        style="width: 300px"
                                        align="center"
                                        :style="{
                                            'Margin-left': 'auto',
                                            'Margin-right': 'auto',
                                            'border-spacing': '0 !important',
                                            'mso-table-lspace': '0pt',
                                            'mso-table-rspace': '0pt',
                                            'border-collapse':
                                                'separate!important',
                                        }"
                                    >
                                        <tr>
                                            <td
                                                :style="{
                                                    backgroundColor: '#f3f6f8',
                                                    paddingTop: '8px',
                                                    paddingBottom: '16px',
                                                    color: '#000000',
                                                    paddingLeft: '16px',
                                                    paddingRight: '16px',
                                                }"
                                                align="center"
                                                valign="middle"
                                            >
                                                <h3
                                                    :style="{
                                                        fontFamily:
                                                            projectConfig.fontFamily,
                                                        margin: '8px 0',
                                                        color: getColor('dark'),
                                                    }"
                                                >
                                                    {{ wa.name }}
                                                </h3>

                                                <p
                                                    :style="{
                                                        fontFamily:
                                                            projectConfig.fontFamily,
                                                        margin: '0 0 8px 0',
                                                    }"
                                                >
                                                    &bull;
                                                    {{
                                                        wa.metadata.props
                                                            .location
                                                    }}
                                                </p>

                                                <p
                                                    :style="{
                                                        fontFamily:
                                                            projectConfig.fontFamily,
                                                        margin: '0 0 8px 0',
                                                    }"
                                                >
                                                    &bull;
                                                    {{
                                                        $moment(
                                                            new Date(
                                                                wa.metadata.props.date_start,
                                                            ),
                                                        ).format(
                                                            $config.WORK_ACTIVITY_DATE_FORMAT,
                                                        )
                                                    }}
                                                    <template
                                                        v-if="
                                                            !wa.metadata.props
                                                                .date_end ||
                                                            wa.metadata.props
                                                                .date_end !==
                                                                wa.metadata
                                                                    .props
                                                                    .date_start
                                                        "
                                                    >
                                                        -
                                                        {{
                                                            wa.metadata.props
                                                                .date_end
                                                                ? $moment(
                                                                      new Date(
                                                                          wa.metadata.props.date_end,
                                                                      ),
                                                                  ).format(
                                                                      $config.WORK_ACTIVITY_DATE_FORMAT,
                                                                  )
                                                                : 'ongoing'
                                                        }}
                                                    </template>
                                                </p>

                                                <p
                                                    v-if="
                                                        wa.metadata.props
                                                            .description
                                                    "
                                                    :style="{
                                                        margin: '0 0 8px 0',
                                                        fontFamily:
                                                            projectConfig.fontFamily,
                                                    }"
                                                >
                                                    {{
                                                        wa.metadata.props
                                                            .description
                                                    }}
                                                </p>

                                                <table
                                                    cellpadding="0"
                                                    cellspacing="0"
                                                >
                                                    <tbody>
                                                        <tr>
                                                            <ButtonLink
                                                                :component-data="{
                                                                    value: 'View on portal map',
                                                                    props: {
                                                                        block: false,
                                                                        href: wa
                                                                            .metadata
                                                                            .props
                                                                            .preview_url,
                                                                        text: '#fff',
                                                                        bg_color:
                                                                            'brand_secondary',
                                                                    },
                                                                }"
                                                            ></ButtonLink>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </td>
                        </tr>
                    </table>
                    <Spacer></Spacer>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import ButtonLink from './ButtonLink.vue';
import Spacer from './Spacer.vue';
export default {
    name: 'WorkActivities',
    components: {
        ButtonLink,
        Spacer,
    },
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
            workActivities: [],
        };
    },
    async fetch() {
        await this.getWorkActivities();
    },
    methods: {
        filterWorkActivities(workActivities) {
            const filter = this.attrs.content_status;
            const today = this.$moment().format('YYYY-MM-DD');

            return workActivities
                .filter((wa) => {
                    if (!wa?.metadata?.props) {
                        return false;
                    }
                    if (wa.published_status !== 'published') {
                        return false;
                    }

                    if (filter === 'Custom') {
                        return (
                            this.attrs.work_activity_ids &&
                            this.attrs.work_activity_ids.includes(
                                wa.metadata.uuid,
                            )
                        );
                    }

                    const waStart = this.$moment(wa.metadata.props.date_start);
                    const waEnd = this.$moment(wa.metadata.props.date_end);

                    if (filter === 'Upcoming and active') {
                        return (
                            !wa.metadata.props.date_end || waEnd.isAfter(today)
                        );
                    } else if (filter === 'Upcoming') {
                        return waStart.isAfter(today);
                    } else if (filter === 'Active') {
                        return (
                            waStart.isBefore(today) &&
                            (!wa.metadata.props.date_end ||
                                waEnd.isAfter(today))
                        );
                    } else if (filter === 'Previous') {
                        return (
                            wa.metadata.props.date_end && waEnd.isBefore(today)
                        );
                    } else if (filter === 'Date range') {
                        return (
                            waStart.isBefore(this.attrs.active_to) &&
                            waStart.isAfter(this.attrs.active_from)
                        );
                    }

                    return false;
                })
                .sort(
                    (a, b) =>
                        this.$moment(b.metadata.props.date_start) -
                        this.$moment(a.metadata.props.date_start),
                );
        },
        async fetchWorkActivities(ids) {
            return await this.$axios.$get('/contents', {
                params: {
                    'filter[type]': 'work_activity',
                    'filter[project_id]': this.$store.state.api.projectID,
                    limit: '-1',
                    'filter[id]': ids,
                },
            });
        },
        async getWorkActivities() {
            const vm = this;
            const filter = this.attrs.content_status;
            if (filter === 'Custom') {
                const unfilterted = await this.fetchWorkActivities(
                    this.attrs.work_activity_ids,
                );
                const published = unfilterted.data.map(
                    // eslint-disable-next-line camelcase
                    ({ published_copy, id }) => published_copy ?? id,
                );
                const res = await this.fetchWorkActivities(published);
                vm.workActivities = vm.filterWorkActivities(res.data);
            } else {
                if (vm.$store.state.api.workActivities) {
                    vm.workActivities = vm.filterWorkActivities(
                        vm.$store.state.api.workActivities,
                    );
                    return;
                }
                const res = await this.$store.dispatch(
                    'api/getAllContentBasic',
                    { contentType: 'work_activity' },
                );
                console.log('GET /content (work_activity) complete');
                vm.workActivities = vm.filterWorkActivities(res);
                console.log(
                    'workActivities length: ',
                    vm.workActivities.length,
                );
            }
        },
    },
    fetchOnServer: true,
};
</script>
