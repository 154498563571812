var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hero-container"},[_c('div',{staticClass:"jumbotron jumbotron-fluid ca-hero",class:_vm.classList,style:(_vm.style),attrs:{"id":_vm.elementID},on:{"click":_vm.doClickAction}},[(
                _vm.attrs.bg_type === 'media' &&
                _vm.attrs.bg_media &&
                _vm.attrs.bg_media.type === 'video'
            )?_c('MediaLoader',_vm._b({staticClass:"video-bg",attrs:{"media":_vm.attrs.bg_media,"controls":false,"autoplay":true,"spinner":false}},'MediaLoader',{ videoDisplay: _vm.attrs.video_display },false)):_vm._e(),_vm._v(" "),_c('div',{staticClass:"container py-1",class:'align-items-' + _vm.attrs.justify_content},[(_vm.isPreview && !_vm.previewMode)?_c('draggable',{staticClass:"draggable",attrs:{"group":"hero","handle":".drag-handle"},on:{"change":function($event){return _vm.refreshPreviewOrder(_vm.componentData.uuid)}},model:{value:(
                    // eslint-disable-next-line vue/no-mutating-props
                    _vm.componentData.children
                ),callback:function ($$v) {_vm.$set(// eslint-disable-next-line vue/no-mutating-props
                    _vm.componentData, "children", $$v)},expression:"\n                    // eslint-disable-next-line vue/no-mutating-props\n                    componentData.children\n                "}},[_vm._t("default")],2):_vm._t("default")],2)],1),_vm._v(" "),_c('Adder',{attrs:{"component-data":_vm.componentData.parentData,"child-u-u-i-d":_vm.componentData.uuid}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }