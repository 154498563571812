var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"campaign-footer p-3",class:_vm.classList,style:({
        backgroundColor: _vm.getColor(_vm.attrs.bg_color),
        color: _vm.getColor(_vm.attrs.text_color),
        borderColor: _vm.getColor(_vm.attrs.text_color),
        textAlign: 'center',
    }),attrs:{"id":_vm.elementID}},[_vm._t("default"),_vm._v(" "),_c('table',{style:({
            border: 'none',
            width: '100%',
            margin: '0',
            borderSpacing: '0',
            backgroundColor: _vm.getColor(
                _vm.campaignFooter ? _vm.campaignFooter.props.bg_color : 'dark'
            ),
            borderCollapse: 'separate',
        }),attrs:{"cellpadding":"8px","cellspacing":"8px"}},[_c('tr',{style:({
                backgroundColor: _vm.getColor(
                    _vm.campaignFooter ? _vm.campaignFooter.props.bg_color : 'dark'
                ),
            })},[_c('td',{staticClass:"footer",style:({
                    backgroundColor: _vm.getColor(
                        _vm.campaignFooter
                            ? _vm.campaignFooter.props.bg_color
                            : 'dark'
                    ),
                    color: _vm.getColor(
                        _vm.campaignFooter
                            ? _vm.campaignFooter.props.text_color
                            : 'light'
                    ),
                    fontSize: '12px',
                })},[(_vm.campaignFooter)?_c('EmailCampaignFooter',{attrs:{"component-data":_vm.campaignFooter}}):_vm._e(),_vm._v(" "),_c('p',{style:({
                        margin: '0 0 4px 0',
                        fontSize: '12px',
                        color: _vm.getColor(
                            _vm.campaignFooter
                                ? _vm.campaignFooter.props.text_color
                                : 'light'
                        ),
                    })},[_vm._v("\n                    "+_vm._s(_vm.client.name)+" - Information accurate as of date\n                    published:\n                    "+_vm._s(_vm.$moment(_vm.content.custom_date || new Date())
                            .tz('Australia/Sydney')
                            .format('D MMM YYYY'))+"\n                ")]),_vm._v(" "),_c('p',{style:({
                        margin: '0 0 4px 0',
                        fontSize: '12px',
                        color: _vm.getColor(
                            _vm.campaignFooter
                                ? _vm.campaignFooter.props.text_color
                                : 'light'
                        ),
                    })},[_vm._v("\n                    Date first published:\n                    "+_vm._s(_vm.$moment(_vm.content.first_published_at || new Date())
                            .tz('Australia/Sydney')
                            .format('D MMM YYYY'))+"\n                ")]),_vm._v(" "),_c('p',{staticClass:"unsubscribe",style:({
                        margin: '0 0 4px 0',
                        fontSize: '12px',
                        color: _vm.getColor(
                            _vm.campaignFooter
                                ? _vm.campaignFooter.props.text_color
                                : 'light'
                        ),
                    })},[_c('a',{style:({
                            color: _vm.getColor(
                                _vm.campaignFooter
                                    ? _vm.campaignFooter.props.text_color
                                    : 'light'
                            ),
                            'font-size': '12px',
                            'text-decoration': 'underline',
                        }),attrs:{"href":"::unsubscribeUrl::"}},[_vm._v("\n                        Unsubscribe")]),_vm._v("\n                    from "+_vm._s(_vm.project.name)+" -\n                    "),_c('a',{style:({
                            color: _vm.getColor(
                                _vm.campaignFooter
                                    ? _vm.campaignFooter.props.text_color
                                    : 'light'
                            ),
                            'font-size': '12px',
                            'text-decoration': 'underline',
                        }),attrs:{"href":"https://communityanalytics.com.au/"}},[_vm._v("\n                        Powered by CA\n                    ")])])],1)])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }