<template>
    <div :style="style" class="nav-links" @click="doClickAction">
        <b-navbar-toggle :target="'collapse-' + componentData.uuid">
            <template #default="{ expanded }">
                <font-awesome-icon
                    v-if="!expanded"
                    size="lg"
                    :icon="['far', 'bars']"
                />
            </template>
        </b-navbar-toggle>

        <b-collapse
            :id="'collapse-' + componentData.uuid"
            class="header-collapse"
            is-nav
        >
            <b-navbar-nav :align="attrs.align" class="nav-links">
                <slot></slot>
            </b-navbar-nav>
        </b-collapse>
    </div>
</template>

<script>
export default {
    name: 'NavLinks',
    props: {
        componentData: Object,
    },
    computed: {
        attrs() {
            return this.componentData.props || {};
        },
        style() {
            return {
                fontWeight: this.attrs.font_weight,
                color: this.getColor(this.attrs.color),
            };
        },
    },
};
</script>

<style lang="scss">
div.header-collapse {
    height: 100%;
}
</style>
