<template>
    <b-form-group :label="label">
        <b-form-select>
            <b-form-select-option
                v-for="(option, i) in attrs.options"
                :key="i"
                v-model="value"
                :value="option.value"
            >
                {{ option.label }}
            </b-form-select-option>
        </b-form-select>
    </b-form-group>
</template>

<script>
export default {
    name: 'FormSelect',
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
            value: '',
        };
    },
    computed: {
        label() {
            return this.attrs.label + (this.attrs.required ? ' *' : '');
        },
    },
    mounted() {
        if (!this.attrs.id) {
            this.attrs.id = 'input_' + this.genUUID();
        }
    },
};
</script>

<style lang="scss">
.form-input {
    .red {
        color: red;
    }
}
</style>
