var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{attrs:{"border":"0","width":"100%","cellpadding":"0","cellspacing":"0"}},[_c('tbody',[_c('tr',[_c('td',[_c('table',{style:({
                        'Margin-left': 'auto',
                        'Margin-right': 'auto',
                        'border-spacing': '0 !important',
                        'mso-table-lspace': '0pt',
                        'mso-table-rspace': '0pt',
                        'border-collapse': 'separate!important',
                        width:
                            _vm.attrs.justify_content === 'stretch'
                                ? '100%'
                                : undefined,
                    }),attrs:{"cellpadding":"0","cellspacing":"0","align":_vm.algin}},[_c('tbody',[(
                                _vm.$slots &&
                                _vm.$slots.default &&
                                _vm.$slots.default[0]
                            )?[(_vm.attrs.align === 'stacked')?_vm._l((_vm.$slots.default),function(child,index){return _c('SlotRenderWrap',{key:index,attrs:{"tag":"tr","value":child}})}):_vm._l((_vm.$slots.default),function(child,index){return _c(child.componentOptions.propsData
                                            .componentData &&
                                        child.componentOptions.propsData
                                            .componentData.props &&
                                        child.componentOptions.propsData
                                            .componentData.props.block
                                            ? 'SlotRenderWrap'
                                            : child.componentOptions.tag,_vm._b({key:index,tag:"component",attrs:{"value":child,"tag":"tr"}},'component',
                                        child.componentOptions.propsData
                                    ,false))})]:_c('tr',[_vm._t("default")],2)],2)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }