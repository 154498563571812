<template>
    <b-navbar-brand
        :id="elementID"
        href="#"
        :class="classList"
        class="ca-navbar-brand"
        @click="doClickAction"
    >
        <img v-if="attrs.logo" class="logo-img" :src="attrs.logo.url" />
    </b-navbar-brand>
</template>

<script>
import helpers from '../mixins/helpers';
export default {
    name: 'NavLink',
    mixins: [helpers],
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
        };
    },
    computed: {
        classList() {
            return [this.attrs.element_class, ...this.helperClasses];
        },
        elementID() {
            return this.attrs.element_id;
        },
    },
    methods: {
        doClickAction(e) {
            e.preventDefault();
            this.openEditor(this.componentData.uuid);
        },
    },
};
</script>

<style lang="scss" scoped>
img.logo-img {
    max-height: 60px;
    width: auto;

    @media screen and (max-width: 768px) {
        max-height: 40px;
        font-size: 0.875em;
    }
}
</style>

<style lang="scss">
a.navbar-brand {
    position: relative;
    display: inline-block;
}
</style>
