import { parse } from 'node-html-parser';
import * as cheerio from 'cheerio';

export default function stringToHTML(str) {
    const $ = cheerio.load(
        str,
        {
            xml: {
                normalizeWhitespace: true,
            },
        },
        false,
    );
    return $;
}
