<template>
    <div
        v-if="activeScene && activeScene.props.media.type !== '360'"
        class="scene-container"
    >
        <MediaLoader
            :ref="activeScene.props.media.id"
            :autoplay="true"
            :controls="false"
            :media="activeScene.props.media"
            :style="{ cursor: addingHotspot ? 'crosshair' : 'default' }"
        />

        <div
            v-for="(hotspot, i) in activeScene.props.hotspots"
            :key="i"
            class="hs"
            :style="{
                top: hotspot.y,
                left: hotspot.x,
            }"
        >
            <MarkerContent :marker="hotspot" />
        </div>
    </div>

    <div v-else ref="pannellum" class="panorama"></div>
</template>

<script>
import blueprints from '../json/blueprints/sceneBlueprints';
import MediaLoader from './MediaLoader';
import MarkerContent from './map/MarkerContent';

export default {
    name: 'Scene',
    components: {
        MediaLoader,
        MarkerContent,
    },
    props: {
        sceneID: String,
    },
    data() {
        return {
            blueprints,
            activeScene: null,
            viewer: null,
        };
    },
    mounted() {
        const vm = this;

        this.$store.dispatch('api/getScene', vm.sceneID).then((res) => {
            vm.activeScene = res;

            if (res.props.media.type === '360') {
                res.props.pan_hotspots.forEach((hs) => vm.addHotspot(hs));
            }
        });
    },
    methods: {
        initPan() {
            setTimeout(() => {
                // eslint-disable-next-line no-undef
                this.viewer = pannellum.viewer(this.$refs.pannellum, {
                    panorama: this.activeScene.props.media.url,
                    type: 'equirectangular',
                    autoLoad: true,
                    showZoom: true,
                    showFullscreen: true,
                    hfov: 75,
                    minHfov: 30,
                    maxHfov: 120,
                    hotspotDebug: true,
                    hotspots: this.activeScene.props.pan_hotspots,
                });
            }, 500);
        },
        addPanHotspot(hs) {
            // eslint-disable-next-line no-undef
            this.viewer.addHotSpot(hotspot);
        },
        createTooltip(hotSpotDiv, args) {
            const vm = this;

            hotSpotDiv.style.backgroundColor = this.getColor(args.color);
            hotSpotDiv.classList.add('pan-tooltip');

            const icon = document.createElement('i');
            const p = document.createElement('p');

            switch (args.icon_type) {
                case 'icon':
                    icon.classList.add('fa', 'far', 'fa-' + args.icon_value);
                    hotSpotDiv.appendChild(icon);
                    break;

                case 'text':
                    p.innerHTML = args.icon_text || '';
                    hotSpotDiv.appendChild(p);
                    break;

                case 'image':
                    if (args.icon_img) {
                        const img = document.createElement('img');
                        img.src = args.icon_img.url;
                        img.height = '16';
                        img.width = '16';
                        hotSpotDiv.appendChild(img);
                    }

                    break;

                default:
                    break;
            }

            if (args.pulse) {
                hotSpotDiv.classList.add('pulse');
            }

            const labelSpan = document.createElement('span');
            labelSpan.innerHTML = `<div>
                <h6 style="font-family:${this.projectConfig.fontFamily};">${args.title}</h6>
                <p style="font-family:${this.projectConfig.fontFamily};">${args.description}</p>
            </div>`;
            labelSpan.classList.add(args.label_pos, args.label_display);
            hotSpotDiv.appendChild(labelSpan);

            hotSpotDiv.addEventListener('mousedown', (e) => {
                this.movingHotspot = args.id;
            });

            hotSpotDiv.addEventListener('mouseup', (e) => {
                vm.setActive(args.id);
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~/assets/scss/animations.scss';

.scene-container {
    width: 100%;
    height: auto;
    position: relative;

    .hs {
        position: absolute;
        margin-left: -15px;
        margin-top: -15px;
        z-index: 999;
    }

    .panorama {
        height: calc(100vh - 2rem);
        width: calc(100vw - 320px - 2rem);
        cursor: unset !important;
    }
}
</style>

<style lang="scss">
.scene-container.preview {
    min-width: 640px;
    min-height: 360px;
    margin-bottom: 0 !important;

    .media-container .vimeo {
        height: calc(100vh - 2rem);
        width: calc(100vw - 320px - 2rem);
        cursor: unset !important;
        background: $dark;
    }

    .vimeo,
    img {
        pointer-events: none;
    }

    .marker {
        cursor: move !important;
    }

    .pan-hotspot {
        height: 30px;
        width: 30px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $light;
        border: 2px solid $light;
        cursor: move !important;

        &.pulse:after {
            content: '';
            position: absolute;
            border-radius: 50%;
            color: transparent;
            border: 3px solid #fff;
            animation: pulse 2s infinite;
            margin-top: -3px;
            margin-left: -3px;
        }
    }
    div.pan-tooltip span {
        opacity: 0;
        position: absolute;
        width: 200px;
        cursor: default;
        text-align: center;
        transition: 0.3s ease;

        &:after {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            border-width: 10px;
            border-style: solid;
            border-color: $cms-primary transparent transparent transparent;
            bottom: -20px;
            left: -10px;
            margin: 0 50%;
        }

        &.top {
            bottom: 40px;

            &:after {
                bottom: -20px;
                left: -10px;
            }
        }

        &.bottom {
            top: 40px;

            &:after {
                top: -20px;
                left: -10px;
                transform: rotate(180deg);
            }
        }

        &.right {
            left: 40px;
            text-align: left;
            &:after {
                top: calc(50% - 10px);
                left: calc(-50% - 20px);
                transform: rotate(90deg);
            }
        }

        &.left {
            right: 40px;
            text-align: right;
            &:after {
                top: calc(50% - 10px);
                left: calc(50% - 0px);
                transform: rotate(270deg);
            }
        }

        & > div {
            display: inline-block;
            border-radius: 3px;
            text-align: center;
            background: $cms-primary;
            padding: 5px 10px;

            p {
                color: $light;
                font-size: 12px;
                margin: 0;
            }

            h6 {
                margin-bottom: 0;
            }
        }
    }

    div.pan-tooltip:hover span.hover,
    div.pan-tooltip span.always {
        opacity: 1;
    }

    .panorama.grabbing > div.pnlm-ui.pnlm-grab {
        cursor: move !important;
    }

    .panorama.adding > div.pnlm-ui.pnlm-grab {
        cursor: crosshair !important;
    }
}
</style>
