<template>
    <b-dropdown
        :text="componentData.value"
        :block="attrs.block"
        :toggle-class="classList"
        :toggle-attrs="{ style }"
        class="ca-dropdown"
        @click="doClickAction"
    >
        <slot></slot>
    </b-dropdown>
</template>

<script>
export default {
    name: 'Dropdown',
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
        };
    },
    computed: {
        classList() {
            return [
                `btn-${this.attrs.size || 'md'}`,
                `text-${this.attrs.text || 'dark'}`,
                this.attrs.element_class || '',
                // ...this.helperClasses,
            ];
        },
        style() {
            return `
                background-color: ${this.getColor(this.attrs.bg_color)};
                color: ${this.getColor(this.attrs.text)};
                font-family: ${this.projectConfig.fontFamily};
            `;
        },
    },
};
</script>

<style>
.links-container .ca-dropdown {
    margin: 0 0.5rem 0.5rem 0;
}
</style>
