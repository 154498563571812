<template>
    <div class="hotspots noselect" @click="doClickAction">
        <Hotspot
            v-for="hotspot in componentData.children"
            :key="hotspot.uuid"
            :component-data="hotspot"
            :props="hotspot.props"
            :uuid="hotspot.uuid"
        >
        </Hotspot>
    </div>
</template>

<script>
import Hotspot from '~/components/Hotspot.vue';

export default {
    name: 'Hotspots',
    components: {
        Hotspot,
    },
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
        };
    },
};
</script>

<style scoped>
.hotspots {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50.1%, -50%);
    width: 100%;
    height: 100%;
}

/* https://stackoverflow.com/a/9314458 */
.noselect {
    user-select: none;
}
</style>
