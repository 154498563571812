const helpers = {
    computed: {
        helperClasses() {
            if (!this.attrs) return [];

            if (this.$config.DISABLE_HELPER_CLASSES) return [];

            const helperClasses = this.attrs.helper_classes;
            if (!helperClasses) return [];

            const sizes = ['xs', 'sm', 'md', 'lg'];
            const properties = ['p', 'm'];
            const directions = ['t', 'r', 'b', 'l'];
            const classes = [];

            properties.forEach((property) => {
                directions.forEach((direction) => {
                    const generalClass =
                        helperClasses.all[property + direction];
                    if (generalClass !== undefined && generalClass !== null) {
                        classes.push(property + direction + '-' + generalClass);
                    }
                    sizes.forEach((size) => {
                        const responsiveClass =
                            helperClasses?.[size]?.[property + direction];
                        if (
                            responsiveClass !== undefined &&
                            responsiveClass !== null
                        ) {
                            classes.push(
                                property +
                                    direction +
                                    '-' +
                                    size +
                                    '-' +
                                    responsiveClass,
                            );
                        }
                    });
                });
            });

            return classes;
        },
    },
};

export default helpers;
