<template>
    <table
        class="social-icons"
        :style="{
            padding: '0 8px',
            'Margin-left': 'auto',
            'Margin-right': 'auto',
            'border-spacing': '0 !important',
            'mso-table-lspace': '0pt',
            'mso-table-rspace': '0pt',
            'border-collapse': 'separate!important',
        }"
    >
        <tbody>
            <tr>
                <td
                    v-for="icon of icons"
                    :key="icon.name"
                    :style="{ padding: '0 8px' }"
                >
                    <a
                        target="_blank"
                        :style="{
                            'text-align': 'center',
                            'text-decoration': 'none',
                        }"
                        :href="attrs[icon.slug]"
                    >
                        <img
                            :src="`https://editor.caapp.com.au/icons/${icon.slug}.png`"
                            height="20"
                            width="20"
                            :alt="icon.name"
                        />
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    name: 'SocialIcons',
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
        };
    },
    computed: {
        icons() {
            const vm = this;
            if (!this.attrs) return [];
            return [
                { name: 'Facebook' },
                { name: 'Instagram' },
                { name: 'Twitter' },
                { name: 'Linkedin' },
                { name: 'Youtube' },
            ]
                .map((icon) => ({
                    ...icon,
                    slug: icon.name.toLocaleLowerCase(),
                }))
                .filter(function (icon) {
                    return vm.attrs[icon.slug];
                });
        },
        style() {
            return {
                color: this.getColor(this.attrs.text_color),
            };
        },
        classList() {
            if (!this.attrs) return [];
            return [this.attrs.element_class || ''];
        },
        elementID() {
            if (!this.attrs) return;
            return this.attrs.element_id || '';
        },
    },
};
</script>

<style lang="scss">
div.social-icons {
    a {
        color: inherit;
    }
}
</style>
