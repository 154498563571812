<template>
    <table
        v-if="attrs.media && attrs.media.type"
        class="media-item-container"
        border="0"
        style="width: 100%"
        cellspacing="0"
        cellpadding="0"
    >
        <tbody>
            <tr>
                <td align="center">
                    <Component
                        :is="href ? 'a' : 'div'"
                        v-if="
                            ['image', 'before_after', 'video', '360'].includes(
                                attrs.media.type,
                            )
                        "
                        :href="href"
                        style="width: 100%; display: block; border: 0px"
                        :style="{
                            'max-width': `${width}px`,
                        }"
                        :target="
                            attrs.open_new_tab ||
                            attrs.on_click === 'larger_new_tab'
                                ? '_blank'
                                : undefined
                        "
                        :download="attrs.on_click === 'download'"
                        :class="{
                            'media-item-full-width': width === full_width,
                        }"
                    >
                        <img
                            v-if="attrs.media.ref"
                            :src="imgURL"
                            :style="`
                    width: 100%;
                    max-width: ${width}px;
                    display: block;
                    border: 0px;
                `"
                            border="0"
                            height="auto"
                            :width="width"
                            :alt="alt"
                            :class="{
                                'media-item-img-full-width':
                                    width === full_width,
                            }"
                        />
                    </Component>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import onClickActions from '~/mixins/onClickActions';

export default {
    name: 'Media',
    mixins: [onClickActions],
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
            full_width: 700,
            half_width: 300,
        };
    },
    computed: {
        imgURL() {
            let url = `https://${this.$config.API_MEDIA_SERVICE_DOMAIN}/${this.attrs.media.ref}?w=${this.width}`;
            if (this.attrs.media.type === 'video') {
                url = `${url}&p=thumb&mark=play-circle.png&markpos=center&markh=100`;
            }
            return url;
        },
        width() {
            const column = ['NewsPostColumn', 'GalleryItem'].includes(
                this.componentData?.parentData?.name,
            );
            return column ? this.half_width : this.full_width;
        },
        height() {
            return this.attrs.height ?? 480;
        },
        alt() {
            return this.attrs.alt ?? this.attrs.media?.title;
        },
        quality() {
            return this.attrs.quality ?? 70;
        },
    },
};
</script>
