<template>
    <div class="form-container container" :style="style">
        The form “{{ form.name }}” will be rendered here.

        <a v-if="editUrl" :href="editUrl" target="_blank" @click="openForm"
            >Edit form</a
        >

        <DragHandle />
        <Adder
            :component-data="componentData.parentData"
            :child-u-u-i-d="componentData.uuid"
        />
    </div>
</template>

<script>
import DragHandle from '~/components/editor/DragHandle';
import Adder from '~/components/editor/Adder';
import componentInlineStyle from '~/utils/componentInlineStyle';

export default {
    name: 'Form',
    components: {
        Adder,
        DragHandle,
    },
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props ?? {},
            form: {},
        };
    },
    computed: {
        style() {
            return {
                ...componentInlineStyle(this.attrs, this.getColor),
            };
        },
        editUrl() {
            const base = this.$config.BASE_CMS_URL;
            const clientId = this.$store.state.api.client?.base_url;
            const projectId = this.$store.state.api.project?.slug;
            const formId = this.attrs.form_id;
            if (projectId && clientId && base && formId) {
                return `${base}/clients/${clientId}/projects/${projectId}/forms/${formId}/edit`;
            }
            return null;
        },
    },
    watch: {
        async 'attrs.form_id'() {
            await this.getForm();
        },
    },
    async mounted() {
        await this.getForm();
    },
    methods: {
        openForm(event) {
            event.preventDefault();
            window.open(this.editUrl, '_blank');
        },
        async getForm() {
            if (this.attrs.form_id) {
                try {
                    this.form = (
                        await this.$axios.$get(`/forms/${this.attrs.form_id}`)
                    )?.data;
                } catch (e) {
                    console.error(e);
                }
            }
        },
    },
};
</script>

<style lang="scss">
.form-container {
    &:hover {
        .drag-handle,
        .adder {
            opacity: 1;
        }
    }
}
</style>
