var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{attrs:{"cellpadding":"0","cellspacing":"0","width":"100%","border":"0"}},[_c('tbody',[_c('tr',{style:({
                marginBottom: '16px',
            })},[_c('td',{style:({
                    width: '50%',
                    backgroundColor: '#f3f6f8',
                })},[_c('div',{style:({
                        margin: '12px',
                        textAlign: 'center',
                    })},[_c('span',{style:({ fontFamily: _vm.projectConfig.fontFamily }),domProps:{"innerHTML":_vm._s(_vm.attrs.before_img.label)}})]),_vm._v(" "),_c('div',{style:({
                        width: '100%',
                        paddingTop: '56.25%',
                        backgroundPosition: 'center center',
                        backgroundSize: 'cover',
                        backgroundImage: ("url(" + (_vm.attrs.before_img.url) + ")"),
                    })})]),_vm._v(" "),_c('td',{style:({
                    width: '50%',
                    backgroundColor: '#f3f6f8',
                })},[_c('div',{style:({
                        margin: '12px',
                        textAlign: 'center',
                    })},[_c('span',{style:({ fontFamily: _vm.projectConfig.fontFamily }),domProps:{"innerHTML":_vm._s(_vm.attrs.after_img.label)}})]),_vm._v(" "),_c('div',{style:({
                        width: '100%',
                        paddingTop: '56.25%',
                        backgroundPosition: 'center center',
                        backgroundSize: 'cover',
                        backgroundImage: ("url(" + (_vm.attrs.after_img.url) + ")"),
                    })})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }