<template>
    <table
        cellpadding="0"
        cellspacing="0"
        border="0"
        width="100%"
        style="width: 100%"
    >
        <tbody width="100%" style="width: 100%">
            <tr>
                <td align="center">
                    <img
                        :src="thumbnail"
                        border="0"
                        height="auto"
                        width="720"
                        alt="Title here"
                        class="media-item-img-full-width"
                        style="
                            width: 100%;
                            max-width: 720px;
                            display: block;
                            border: 0px;
                        "
                    />
                </td>
            </tr>
            <tr width="100%" style="width: 100%">
                <td
                    width="100%"
                    :style="{
                        backgroundColor: getColor('brand_secondary'),
                        height: '32px',
                        textAlign: 'center',
                        width: '100%',
                    }"
                >
                    <a
                        :href="attrs.src"
                        target="_blank"
                        rel="noopener"
                        :style="{
                            color: '#fff',
                            textAlign: 'center',
                            textDecoration: 'none',
                            verticalAlign: 'middle',
                            lineHeight: '32px',
                            fontFamily: projectConfig.fontFamily,
                            width: '100%',
                        }"
                    >
                        Play Video
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import getYoutubeIdFromUrl from '~/utils/getYoutubeIdFromUrl';
export default {
    name: 'Youtube',
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
        };
    },
    computed: {
        thumbnail() {
            const id = getYoutubeIdFromUrl(this.attrs.src);
            return `https://i.ytimg.com/vi/${id}/hqdefault.jpg`;
        },
        width() {
            const column = ['NewsPostColumn', 'GalleryItem'].includes(
                this.componentData?.parentData?.name,
            );
            return column ? 300 : 720;
        },
    },
};
</script>
