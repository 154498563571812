var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.attrs.media && _vm.attrs.media.type)?_c('table',{staticClass:"media-item-container",staticStyle:{"width":"100%"},attrs:{"border":"0","cellspacing":"0","cellpadding":"0"}},[_c('tbody',[_c('tr',[_c('td',{attrs:{"align":"center"}},[(
                        ['image', 'before_after', 'video', '360'].includes(
                            _vm.attrs.media.type
                        )
                    )?_c(_vm.href ? 'a' : 'div',{tag:"Component",class:{
                        'media-item-full-width': _vm.width === _vm.full_width,
                    },staticStyle:{"width":"100%","display":"block","border":"0px"},style:({
                        'max-width': (_vm.width + "px"),
                    }),attrs:{"href":_vm.href,"target":_vm.attrs.open_new_tab ||
                        _vm.attrs.on_click === 'larger_new_tab'
                            ? '_blank'
                            : undefined,"download":_vm.attrs.on_click === 'download'}},[(_vm.attrs.media.ref)?_c('img',{class:{
                            'media-item-img-full-width':
                                _vm.width === _vm.full_width,
                        },style:(("\n                width: 100%;\n                max-width: " + _vm.width + "px;\n                display: block;\n                border: 0px;\n            ")),attrs:{"src":_vm.imgURL,"border":"0","height":"auto","width":_vm.width,"alt":_vm.alt}}):_vm._e()]):_vm._e()],1)])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }