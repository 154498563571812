<template>
    <div
        :id="elementID"
        :class="classList"
        class="compare-wrapper ca-media-wrapper ca-media-before_after-wrapper positon-relative"
    >
        <div class="ca-aspect-16-9">
            <iframe :src="attrs.url"></iframe>
        </div>
    </div>
</template>

<script>
import helpers from '../mixins/helpers';
export default {
    name: 'CompareSlider',
    mixins: [helpers],
    props: {
        componentData: Object,
    },
    computed: {
        attrs() {
            return this.componentData.props || {};
        },
        classList() {
            return [this.attrs.element_class, ...this.helperClasses];
        },
        elementID() {
            return this.attrs.element_id;
        },
    },
};
</script>
