<template>
    <div
        :id="elementID"
        :class="classList"
        :style="style"
        class="card-title"
        @click="doClickAction"
    >
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'CardTitle',
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
        };
    },
    computed: {
        style() {
            return {
                backgroundColor: this.getColor(this.attrs.bg_color),
                fontFamily: this.projectConfig.fontFamily,
                color: this.getColor(this.attrs.color),
            };
        },
        classList() {
            return [
                this.attrs ? this.attrs.element_class : '',
                // this.helperClasses
            ];
        },
        elementID() {
            return this.attrs ? this.attrs.element_id : '';
        },
    },
};
</script>

<style lang="scss" scoped>
.card-title {
    padding: 1rem;
    cursor: auto;
    margin-bottom: unset;
}
</style>

<style lang="scss">
div.card-title {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        margin-bottom: 0 !important;
    }
}
</style>
