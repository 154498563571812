<template>
    <div class="content-components">
        <RenderComponent v-if="contentData" :component-data="contentData" />
    </div>
</template>

<script>
export default {
    name: 'RenderContent',
    props: {
        contentID: String,
    },
    data() {
        return {
            contentData: null,
        };
    },
    async fetch() {
        this.contentData = await this.$store
            .dispatch('api/getContent', this.contentID)
            .then((res) => res.metadata);
    },
    async mounted() {
        await this.fetch();
    },
    methods: {
        async fetch() {
            this.contentData = await this.$store
                .dispatch('api/getContent', this.contentID)
                .then((res) => res.metadata);
        },
    },
    fetchOnServer: true,
};
</script>

<style lang="scss">
.content-components {
    .adder,
    .drag-handle {
        display: none !important;
    }
    .heading,
    .paragraph {
        pointer-events: none;
        cursor: default;
    }
}
</style>
