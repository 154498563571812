<template>
    <div
        class="page-container ca-body positon-relative"
        :class="classList"
        :style="{ fontFamily: projectConfig.fontFamily }"
    >
        <div class="header-and-content positon-relative">
            <style>
                {{ style }}
            </style>
            <style>
                {{ clientStyle }}
            </style>
            <style>
                {{ projectStyle }}
            </style>

            <RenderContent v-if="headerID" :content-i-d="headerID" />

            <!-- If an external page file, i.e. v2 page -->
            <div
                v-if="componentData.props.content_source === 'External'"
                class="external-content p-3"
            >
                <h5>
                    The content for this page is rendered by
                    {{ attrs.external_file }} and must be edited by the Spatial
                    Media team. Please contact your Spatial Media project
                    manager to arrange changes to this component.
                </h5>
            </div>

            <!-- Else, if an editor page -->
            <template v-else>
                <draggable
                    v-if="isPreview && !previewMode"
                    v-model="
                        // eslint-disable-next-line vue/no-mutating-props
                        componentData.children
                    "
                    group="page"
                    @change="refresh"
                >
                    <slot></slot>
                </draggable>

                <slot v-else />
            </template>

            <Adder :component-data="componentData" />

            <!-- <script type="text/javascript">
                {
                    {
                        script;
                    }
                }
            </script> -->
        </div>

        <div class="footer-wrapper">
            <RenderContent v-if="footerID" :content-i-d="footerID" />
        </div>
    </div>
</template>

<script>
import postcss from 'postcss';
import Adder from './editor/Adder';
import RenderContent from './RenderContent';
export default {
    name: 'Page',
    components: {
        Adder,
        RenderContent,
    },
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props,
            resetting: false,
            footerID: this.componentData.props.footer,
            headerID: this.componentData.props.header,
            style: null,
            clientStyle: null,
            projectStyle: null,
        };
    },
    computed: {
        footer() {
            return this.attrs.footer;
        },
        header() {
            return this.attrs.header;
        },
        classList() {
            return [];
        },
        css() {
            return this.attrs.css;
        },
        script() {
            return this.attrs.js;
        },
    },
    watch: {
        footer(next, prev) {
            this.footerID = null;
            this.$nextTick(() => (this.footerID = next));
        },
        header(next, prev) {
            this.headerID = null;
            this.$nextTick(() => (this.headerID = next));
        },
        async css(next) {
            this.style = await this.processCss(next);
        },
    },
    async created() {
        this.style = await this.processCss(this.componentData.props.css);
        await this.processRemoteCss();
    },
    methods: {
        refresh() {
            this.$root.$emit('editor:refreshorder', this.componentData.uuid);
        },
        async processRemoteCss() {
            const clientId = this.$store.state.api.client?.id;
            const projectId = this.$store.state.api.projectID;
            if (clientId) {
                try {
                    const response = await this.$axios.$get(
                        `${this.$config.BASE_API_URL}/clients/${clientId}.css`,
                    );
                    const style = await postcss([
                        require('postcss-nested'),
                    ]).process(`.page-container { ${response} }`);
                    this.clientStyle = style.css;
                } catch (e) {
                    console.error(e);
                }
            }
            if (projectId) {
                try {
                    const response = await this.$axios.$get(
                        `${this.$config.BASE_API_URL}/projects/${projectId}.css`,
                    );
                    const style = await postcss([
                        require('postcss-nested'),
                    ]).process(`.page-container { ${response} }`);
                    this.projectStyle = style.css;
                } catch (e) {
                    console.error(e);
                }
            }
        },
        async processCss(css) {
            if (!css) {
                return null;
            }
            const strippedCss = css
                .replace('.page-container', '')
                .replace('.preview-container', '');
            try {
                const style = await postcss([
                    require('postcss-nested'),
                ]).process(`.page-container { ${strippedCss} }`);
                return style.css;
            } catch (e) {
                console.error(e);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
div.page-container {
    position: relative;
    max-width: 100%;
    // min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: space-between;

    .header-and-content {
        & > .adder {
            margin-top: 10px;
        }
    }

    .external-file {
        display: flex;
        justify-content: center;
        align-items: center;

        h5 {
            margin: 0;
            padding: 0;
            text-align: center;
        }
    }
}
</style>
