<template>
    <tr v-if="layout === 'Pdf list'" class="docRow">
        <td class="monthTitle">
            <strong v-if="index === 0 && include_media_collection_title">{{
                collection_title
            }}</strong>
        </td>
        <td><span class="file-icon"></span></td>
        <td>
            <span v-if="include_media_title">{{ item.title }}</span>
            <p v-if="include_media_description">{{ item.description }}</p>
        </td>
        <td v-if="include_media_file_size" class="file-size">
            {{ item.size_human }}
        </td>
    </tr>
    <b-carousel-slide v-else-if="layout === 'Carousel'">
        <template #img>
            <MediaLoader
                v-if="item"
                class="d-block img-fluid w-100"
                :media="item"
            />
        </template>
        <div
            class="position-absolute text-white text-shadow font-weight-bold p-2"
            style="
                bottom: 40px;
                left: 25px;
                background-color: rgba(0, 0, 0, 0.5);
            "
        >
            <div
                v-if="include_media_title"
                class="ca-heading ca-mediacollectionitem-title"
            >
                <h4>
                    {{ item.title }}
                    <span
                        v-if="include_media_file_size"
                        class="ca-mediacollectionitem-filesize"
                    >
                        ({{ item.size_human }})
                    </span>
                </h4>
            </div>
            <div
                v-if="include_media_description"
                class="ca-mediacollectionitem-excerpt text-truncate-description"
            >
                {{ item.description }}
            </div>
        </div>
    </b-carousel-slide>
    <div
        v-else-if="layout === 'News Post'"
        :id="`media-${uuid}`"
        class="card card-long card-news-post"
    >
        <a :href="item.embed_url" class="card-link" target="_blank">
            <div class="card-body d-flex flex-row">
                <div class="card-icon mr-3 roz-blue-light">
                    <img
                        v-if="item.title.toUpperCase().includes('NOTIFICATION')"
                        src="https://ca-v2.s3.ap-southeast-2.amazonaws.com/ca/icons/document-icons/workNotifications.svg"
                        alt=""
                    />
                    <img
                        v-else-if="item.title.toUpperCase().includes('UPDATE')"
                        src="https://ca-v2.s3.ap-southeast-2.amazonaws.com/ca/icons/document-icons/MonitoringData.svg"
                        alt=""
                    />
                    <img
                        v-else-if="
                            ['MANAGING', 'MANAGE', 'PLAN', 'REPORT'].some(
                                (term) =>
                                    item.title.toUpperCase().includes(term),
                            )
                        "
                        src="https://ca-v2.s3.ap-southeast-2.amazonaws.com/ca/icons/document-icons/ManagementPlansandReports.svg"
                        alt=""
                    />
                    <i
                        v-else
                        class="fa fa-newspaper fa-2x"
                        aria-hidden="true"
                    ></i>
                </div>
                <div class="card-content">
                    <h5
                        v-if="include_media_title"
                        class="card-title-heavy gallery-item-title"
                    >
                        {{ item.title }}
                    </h5>
                    <h5
                        v-if="include_media_file_size"
                        class="ca-mediacollectionitem-filesize card-title"
                    >
                        {{ item.size_human }}
                    </h5>
                </div>
            </div>
        </a>
    </div>
    <b-card
        v-else
        class="ca-mediacollectionitem ca-card card"
        :class="{
            'flex-row': ['List', 'Accordion'].includes(layout),
            'd-flex': ['List', 'Accordion'].includes(layout),
        }"
        style="align-items: center"
        no-body
    >
        <b-card-header
            class="position-relative p-0 ca-thumbnail ca-mediacollectionitem-thumbnail"
        >
            <span v-if="icon" class="overlay-icon ca-thumbnail-icon">
                <font-awesome-icon :icon="['fas', icon]" size="1x" class="" />
            </span>
            <MediaLoader
                v-if="include_thumbnails"
                :media="item"
                thumb
            ></MediaLoader>
        </b-card-header>
        <b-card-body class="d-block">
            <div
                v-if="include_media_title"
                class="ca-heading ca-mediacollectionitem-title"
            >
                <h4>
                    {{ item.title }}
                    <span
                        v-if="include_media_file_size"
                        class="ca-mediacollectionitem-filesize"
                    >
                        ({{ item.size_human }})
                    </span>
                </h4>
            </div>
            <div
                v-if="include_media_description"
                class="ca-mediacollectionitem-excerpt text-truncate-description"
            >
                {{ item.description }}
            </div>
        </b-card-body>
    </b-card>
</template>

<script>
import MediaLoader from './MediaLoader';
export default {
    components: {
        MediaLoader,
    },
    props: {
        index: { type: Number },
        // eslint-disable-next-line vue/prop-name-casing
        collection_title: { type: String },
        // eslint-disable-next-line vue/prop-name-casing
        include_media_collection_title: { type: Boolean },
        item: { type: Object },
        layout: { type: String, default: 'Grid' },
        // eslint-disable-next-line vue/prop-name-casing
        include_thumbnails: { type: Boolean, default: true },
        // eslint-disable-next-line vue/prop-name-casing
        include_media_title: { type: Boolean, default: true },
        // eslint-disable-next-line vue/prop-name-casing
        include_media_description: { type: Boolean, default: true },
        // eslint-disable-next-line vue/prop-name-casing
        include_media_file_size: { type: Boolean, default: true },
    },
    computed: {
        icon() {
            return {
                document: 'download',
                video: 'play',
            }[this.item?.type];
        },
    },
};
</script>

<style lang="scss" scoped>
.overlay-icon {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    font-size: 75px;
    color: $light;
    justify-content: center;
    align-items: center;
    z-index: 3;
    background: rgba(0, 0, 0, 0.1);
    pointer-events: none;
}
</style>
