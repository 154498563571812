<template>
    <div class="media-container">
        <div v-if="!media || !media.type" class="text-center">
            <font-awesome-icon v-if="spinner" :icon="['far', 'spinner']" />
        </div>

        <template v-else>
            <template
                v-if="
                    ['image', 'document'].includes(media.type) ||
                    (['before_after', 'video', '360'].includes(media.type) &&
                        thumb)
                "
            >
                <b-img-lazy
                    :src="imgURL"
                    :width="_width"
                    :height="_height"
                    :alt="alt"
                    style="width: 100%; height: auto"
                    fluid
                    center
                    blank-color="#eeeeee"
                    loading="lazy"
                    decoding="async"
                    fetchpriority="low"
                />
            </template>

            <div
                v-else-if="media.type === '360'"
                :ref="media.id"
                class="panorama"
            ></div>

            <div v-else-if="media.type === 'video'" class="vimeo">
                <template v-if="videoDisplay === 'thumbnail'">
                    <div style="position: relative">
                        <b-img
                            :src="videoThumbUrl"
                            width="1200"
                            height="500"
                            :alt="alt"
                            style="
                                background: #eeeeee;
                                width: 100%;
                                height: auto;
                            "
                            fluid
                            center
                            blank-color="#eeeeee"
                            title="Click to enlarge"
                        />
                        <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fad"
                            data-icon="play-circle"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="svg-inline--fa fa-play-circle fa-w-16 fa-9x"
                            style="
                                position: absolute;
                                width: 25%;
                                height: 25%;
                                top: 37%;
                                left: 37%;
                            "
                        >
                            <g class="fa-group">
                                <path
                                    fill="white"
                                    d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm115.7 272l-176 101c-15.8 8.8-35.7-2.5-35.7-21V152c0-18.4 19.8-29.8 35.7-21l176 107c16.4 9.2 16.4 32.9 0 42z"
                                ></path>
                                <path
                                    fill="grey"
                                    d="M371.7 280l-176 101c-15.8 8.8-35.7-2.5-35.7-21V152c0-18.4 19.8-29.8 35.7-21l176 107c16.4 9.2 16.4 32.9 0 42z"
                                ></path>
                            </g>
                        </svg>
                    </div>
                </template>
                <vimeo-player
                    v-else
                    ref="player"
                    player-height="500"
                    player-width="1200"
                    :options="{
                        responsive: true,
                        muted: _muted,
                        background: _background,
                    }"
                    :muted="true"
                    :loop="true"
                    :autoplay="_autoplay"
                    :controls="_controls"
                    :video-id="media.url"
                />
            </div>

            <CompareSlider
                v-else-if="media.type === 'before_after'"
                :component-data="compare"
            />
        </template>
    </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import CompareSlider from './CompareSlider';
export default {
    name: 'MediaLoader',
    components: {
        CompareSlider,
    },
    props: {
        media: { type: [Object, null] },
        width: { type: Number, default: 1080, required: false },
        height: { type: Number, default: 720, required: false },
        alt: { type: String, default: undefined, required: false },
        quality: { type: Number, default: 70, required: false },
        thumb: { type: Boolean, default: false },
        autoplay: { type: Boolean, default: false },
        controls: { type: Boolean, default: true },
        spinner: { type: Boolean, default: true },
        // eslint-disable-next-line vue/prop-name-casing
        pan_hotspots: { type: Array, default: () => [] },
        videoDisplay: { type: String, default: null },
        lazy: { type: Boolean, default: false },
    },
    data() {
        return {
            viewer: null,
            uuid: uuidv4(),
        };
    },
    computed: {
        baseURL() {
            return `https://${this.$config.API_MEDIA_SERVICE_DOMAIN}/`;
        },
        videoThumbUrl() {
            return this.$img(this.media.ref, {
                p: 'thumb',
                t: this._updated_at,
                q: this.quality,
                w: 1200,
                h: 500,
            });
        },
        imgURL() {
            return this.$img(this.media.ref, {
                p: this.thumb ? 'thumb' : undefined,
                t: this._updated_at,
                q: this.quality,
            });
        },
        _controls() {
            if (this.videoDisplay === 'background') {
                return false;
            }
            if (this.videoDisplay === 'embed') {
                return true;
            }
            return this.controls;
        },
        _autoplay() {
            if (this.videoDisplay === 'embed') {
                return false;
            }
            return this.autoplay;
        },
        _muted() {
            if (this.videoDisplay === 'background') {
                return true;
            }
            return false;
        },
        _background() {
            if (this.videoDisplay === 'background') {
                return true;
            }
            return false;
        },
        _width() {
            return this.media?.width ?? this.width ?? undefined;
        },
        _height() {
            return this.media?.height ?? this.height ?? undefined;
        },
        _updated_at() {
            if (this.media?.updated_at) {
                return new Date(this.media?.updated_at).getTime();
            }
            return Date.now();
        },
        compare() {
            if (this.media.before && this.media.after) {
                return {
                    props: {
                        url: `${this.baseURL}embed/${this.media.ref}`,
                    },
                };
            }
            return null;
        },
    },
    mounted() {
        if (this.media?.type === '360' && !this.thumb) {
            // eslint-disable-next-line no-undef
            this.viewer = pannellum.viewer(this.$refs[this.media.id], {
                panorama: this.imgURL,
                type: 'equirectangular',
                autoLoad: true,
                showZoom: true,
                showFullscreen: true,
                hfov: 75,
                minHfov: 30,
                maxHfov: 120,
                hotspotDebug: true,
                hotspots: this.pan_hotspots,
            });
        }
    },
};
</script>

<style lang="scss" scoped>
.media-container {
    .vimeo {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-basis: 100%;
        position: relative;

        & > * {
            flex-basis: 100%;
            align-self: center;
        }
    }

    .panorama {
        min-height: 360px;
        min-width: 640px;
        height: auto;
        width: 100%;
    }
}
</style>

<style lang="scss">
iframe {
    height: auto;
    max-width: 100% !important;

    div#player {
        max-width: unset !important;
        width: 100% !important;
        height: unset !important;
        height: auto !important;
    }
}
</style>
