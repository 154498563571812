import { connectToParent } from 'penpal';

export default async function ({ $config, $axios, route }) {
    let authToken;

    /*
        Try to connect to the parent window and set the token, 
        if it fails we're not in an iframe, so set the token
        from the query string.
    */
    try {
        const connection = connectToParent({
            targetOrigin: $config.BASE_CMS_URL,
        });
        const parentMethods = await connection?.promise;
        const editorToken = await parentMethods?.editorGetToken();
        authToken = editorToken?.authToken;
    } catch (err) {
        const base64UrlToken = route.query.caApiAuthToken;
        // decode the base64 url encoded token
        authToken = base64UrlDecode(base64UrlToken);
    }

    /*
        If we have a token, set it in the axios client,
        otherwise, redirect to login.
    */
    if (authToken) {
        $axios.setToken(authToken, 'Bearer');
    } else {
        console.log('[auth plugin] No token set');
        // Redirect only if we're in the browser and the current path is not excluded from redirect
        const excludedPaths = ['/unsubscribe'];
        if (process.client && !excludedPaths.includes(route.path)) {
            window.parent.location.href = `${$config.BASE_CMS_URL}/login`;
        }
    }
}

function base64UrlDecode(base64Url) {
    let decodedString;

    if (!base64Url?.length) {
        console.log('[auth plugin] No token provided');
        return decodedString;
    }

    // Replace URL-safe characters with standard Base64 characters
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

    // Add padding if necessary
    while (base64.length % 4 !== 0) {
        base64 += '=';
    }

    // Decode the Base64 string
    if (process.client) {
        // Client-side decoding
        decodedString = window.atob(base64);
    } else {
        // Server-side decoding
        decodedString = Buffer.from(base64, 'base64').toString('utf-8');
    }

    return decodedString;
}
