<template>
    <div class="topics-container" @click="doClickAction">
        <div class="topics">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'Topics',
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
        };
    },
};
</script>

<style lang="scss" scoped>
.topics-container {
    .topics {
        padding: 0.5rem;
    }
}
</style>
