import Vue from 'vue';
import VueCodemirror from 'vue-codemirror';
import 'codemirror/mode/css/css.js';
import 'codemirror/mode/javascript/javascript.js';
import 'codemirror/mode/htmlmixed/htmlmixed.js';
import 'jsonlint';
import 'codemirror/addon/lint/lint';
import 'codemirror/addon/lint/json-lint';

Vue.use(VueCodemirror);
