export const state = () => ({
    parentMethods: null,
    echo: {
        // TODO: check arrow function to get global this
        // host: this.$config.BASE_API_URL,
        // port: this.$config.ECHO_PORT,
    },
});

export const mutations = {
    setParentMethods: (state, data) => {
        state.parentMethods = data;
    },
    setEcho: (state, data) => {
        state.echo = data;
    },
};

export const getters = {
    echo(state) {
        return state.echo;
    },
};

export const actions = {
    getMedia: ({ state }, type = '360') => {
        return new Promise((resolve, reject) => {
            if (!state.parentMethods) {
                switch (type) {
                    case 'image':
                        resolve('569e028d-00de-4f99-b118-84ced8f6cc4e'); // image
                        break;

                    case 'video':
                        resolve('943a9302-3ec5-4b6c-8c20-73af69d51fd6'); // video
                        break;

                    case '360':
                        resolve('129fd19b-3008-411a-be44-ae5e9efaa2db'); // 360
                        break;

                    case '360video':
                        resolve('46f074a0-6e13-11e9-bf68-0fabf85d1ed8'); // 360 video
                        break;

                    case 'before_after':
                        resolve('dbd88a87-6463-40d0-bcff-77bcc0783002');
                        break;
                }
            } else {
                state.parentMethods
                    ?.editorGetMedia()
                    .then((res) => {
                        if (Array.isArray(res)) {
                            resolve(res[0]);
                        } else {
                            resolve(res);
                        }
                    })
                    .catch((err) => reject(err))
                    .finally(() => resolve());
            }
        });
    },
};
