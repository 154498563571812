<template>
    <div class="hr-container">
        <div :id="elementID" class="hr">
            <DragHandle />
            <hr
                :id="elementID"
                :class="classList"
                :style="style"
                @click="doClickAction"
            />
        </div>

        <Adder
            :component-data="componentData.parentData"
            :child-u-u-i-d="componentData.uuid"
        />
    </div>
</template>

<script>
import helpers from '../mixins/helpers';
import DragHandle from './editor/DragHandle';
import Adder from './editor/Adder';
export default {
    name: 'Hr',
    components: {
        DragHandle,
        Adder,
    },
    mixins: [helpers],
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
        };
    },
    computed: {
        style() {
            return {
                borderTopColor: this.getColor(this.attrs.color),
                borderTopWidth: this.attrs.thickness + 'px',
            };
        },
        classList() {
            return [
                this.attrs ? this.attrs.element_class : '',
                ...this.helperClasses,
            ];
        },
        elementID() {
            return this.attrs ? this.attrs.element_id : '';
        },
    },
};
</script>

<style lang="scss" scoped>
.hr-container {
    display: flex;
    flex-direction: column;

    &:hover {
        .drag-handle,
        .adder {
            opacity: 1;
        }
    }
}
hr {
    width: 100%;
}
.hr {
    position: relative;
}
</style>
