<template>
    <div :id="elementID" :class="classList">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'CampaignPreFooter',
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props ?? {},
        };
    },
    computed: {
        classList() {
            return [this.attrs.element_class];
        },
        elementID() {
            return this.attrs.element_id ?? '';
        },
    },
};
</script>
