import Vue from 'vue';
import removeSpecialChars from '~/utils/removeSpecialChars';
import getColor from '~/utils/getColor';

const globalMethods = {
    methods: {
        toCapitalizedWords(str) {
            if (typeof str !== 'string') return '';
            const words = str.match(/[A-Za-z][a-z]*/g) || [];

            return words.map(this.capitalize).join(' ');
        },
        capitalize(str) {
            if (typeof str !== 'string') return '';
            return str.charAt(0).toUpperCase() + str.substring(1);
        },
        removeSpecialChars,
        openEditor(uuid) {
            if (uuid) {
                this.$root.$emit('editor:setactive', uuid);
            }
        },
        genUUID() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
                /[xy]/g,
                function (c) {
                    const r = (Math.random() * 16) | 0;
                    const v = c === 'x' ? r : (r & 0x3) | 0x8;
                    return v.toString(16);
                },
            );
        },
        getColor(color) {
            return getColor(color, this.$store.getters['api/colors']);
        },
        refreshPreviewOrder(uuid = null) {
            this.$root.$emit('editor:refreshorder', uuid);
        },
        doClickAction(e) {
            // https://css-tricks.com/dangers-stopping-event-propagation/
            if (e.defaultPrevented) return false;
            e.preventDefault();
            const uuid = this.uuid ?? this.componentData?.uuid;
            if (uuid) {
                this.openEditor(uuid);
            }
            return false;
        },
    },
    filters: {
        stripHTML: (str) => {
            return str.replace(/(<([^>]+)>)/gi, '');
        },
        removeSpecialChars,
    },
    computed: {
        projectConfig() {
            return this.$store.getters['api/projectConfig'];
        },
        isPreview() {
            return this.$store.getters.isPreview;
        },
        apiClient() {
            return this.$store.getters['api/apiClient'];
        },
        previewMode: {
            get() {
                return this.$store.getters.previewMode;
            },
            set(val) {
                this.$store.commit('setPreviewMode', val);
            },
        },
    },
};

export default globalMethods;

Vue.mixin(globalMethods);
