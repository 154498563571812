<template>
    <div
        v-if="editor"
        :id="id"
        v-click-outside="away"
        class="wysiwyg"
        :class="{ mini, 'is-editor-empty': isEmpty }"
        tabindex="-1"
        @focus="focusEditor"
    >
        <div
            v-if="editor && !mini"
            class="wysiwyg-btns"
            :class="{
                focused,
                flip,
            }"
        >
            <div class="action-btns">
                <!-- SHOW BOLD/ITALIC/UNDERLINE IF NOT HEADING -->
                <template v-if="!linkMenuIsActive">
                    <template v-if="!showHeadings">
                        <b-button
                            size="sm"
                            :pressed="editor.isActive('bold')"
                            @click.stop="
                                editor.chain().toggleBold().focus().run()
                            "
                        >
                            <font-awesome-icon
                                :icon="['fal', 'bold']"
                                fixed-width
                            />
                        </b-button>
                        <b-button
                            size="sm"
                            :pressed="editor.isActive('italic')"
                            @click.prevent="
                                editor.chain().toggleItalic().focus().run()
                            "
                        >
                            <font-awesome-icon
                                :icon="['fal', 'italic']"
                                fixed-width
                            />
                        </b-button>
                        <b-button
                            size="sm"
                            :pressed="editor.isActive('underline')"
                            @click.prevent="
                                editor.chain().toggleUnderline().focus().run()
                            "
                        >
                            <font-awesome-icon
                                :icon="['fal', 'underline']"
                                fixed-width
                            />
                        </b-button>
                    </template>

                    <b-button
                        size="sm"
                        :pressed="editor.isActive('image')"
                        @click.prevent="
                            showImageMenu(editor.getAttributes('image'))
                        "
                    >
                        <font-awesome-icon
                            :icon="['fal', 'image']"
                            fixed-width
                        />
                    </b-button>

                    <b-button
                        size="sm"
                        :pressed="editor.isActive('bulletList')"
                        @click.prevent="
                            editor.chain().toggleBulletList().focus().run()
                        "
                    >
                        <font-awesome-icon
                            :icon="['fal', 'list']"
                            fixed-width
                        />
                    </b-button>
                    <b-button
                        class="align-btn"
                        size="sm"
                        :pressed="align === 'left'"
                        @click.prevent="setAlign('left')"
                    >
                        <font-awesome-icon
                            :icon="['fal', 'align-left']"
                            fixed-width
                        />
                    </b-button>
                    <b-button
                        class="align-btn"
                        size="sm"
                        :pressed="align === 'center'"
                        @click.prevent="setAlign('center')"
                    >
                        <font-awesome-icon
                            :icon="['fal', 'align-center']"
                            fixed-width
                        />
                    </b-button>
                    <b-button
                        class="align-btn"
                        size="sm"
                        :pressed="align === 'right'"
                        @click.prevent="setAlign('right')"
                    >
                        <font-awesome-icon
                            :icon="['fal', 'align-right']"
                            fixed-width
                        />
                    </b-button>

                    <tippy
                        :flip="true"
                        :lazy="false"
                        boundary="window"
                        trigger="manual"
                        :flip-on-update="true"
                        :hide-on-click="false"
                        :visible="colorpickerOpen"
                        :interactive="true"
                        append-to="parent"
                        placement="bottom"
                        @onHide="closeColorPicker"
                    >
                        <template #trigger>
                            <b-button
                                size="sm"
                                @click.prevent="
                                    colorpickerOpen = !colorpickerOpen
                                "
                            >
                                <font-awesome-icon
                                    :icon="['fal', 'eye-dropper']"
                                    fixed-width
                                />
                            </b-button>
                        </template>
                        <ColorPicker
                            :style="{ width: '218px' }"
                            theme="light"
                            :color="getPickerColor()"
                            :colors-default="defaultColors"
                            :colors-history-string="null"
                            @changeColor="changeColor"
                        />
                    </tippy>

                    <b-dropdown v-if="showHeadings && focused" size="sm">
                        <template #button-content>
                            <font-awesome-icon :icon="['fal', 'text-size']" />
                        </template>
                        <b-dropdown-item-button
                            v-for="level in 6"
                            :key="level"
                            :active="editor.isActive('heading', { level })"
                            @click.prevent="setHeadingLevel(level)"
                            >H{{ level }}
                        </b-dropdown-item-button>
                    </b-dropdown>
                    <b-button
                        size="sm"
                        :pressed="editor.isActive('link')"
                        @click.prevent="showLinkMenu()"
                    >
                        <font-awesome-icon
                            :icon="['fal', 'link']"
                            fixed-width
                        ></font-awesome-icon>
                    </b-button>
                    <b-button
                        v-if="editor.isActive('link')"
                        size="sm"
                        @click.prevent="
                            editor.chain().focus().unsetLink().run()
                        "
                    >
                        <font-awesome-icon
                            :icon="['fal', 'unlink']"
                            fixed-width
                        ></font-awesome-icon>
                    </b-button>
                </template>

                <template v-else>
                    <b-input
                        ref="linkInput"
                        v-model="linkUrl"
                        placeholder="Enter url"
                        @keydown.esc="hideLinkMenu"
                    />
                    <b-button size="sm" @click.prevent="setLinkUrl(linkUrl)">
                        <font-awesome-icon
                            :icon="['fal', 'check']"
                            fixed-width
                        ></font-awesome-icon>
                    </b-button>
                    <b-button size="sm" @click.prevent="hideLinkMenu">
                        <font-awesome-icon
                            :icon="['fal', 'times']"
                            fixed-width
                        ></font-awesome-icon>
                    </b-button>
                    <div style="width: 100%; color: black">
                        <b-form-checkbox
                            v-model="linkTarget"
                            @click.native="setLinkTarget"
                            >Open in new window</b-form-checkbox
                        >
                    </div>
                </template>

                <template v-if="imageMenuIsActive">
                    <b-input
                        ref="imageInput"
                        v-model="imageUrl"
                        placeholder="Enter url"
                        @keydown.esc="hideImageMenu"
                    />
                    <b-button size="sm" @click.prevent="setImageUrl(imageUrl)">
                        <font-awesome-icon
                            :icon="['fal', 'check']"
                            fixed-width
                        ></font-awesome-icon>
                    </b-button>
                    <b-button size="sm" @click.prevent="hideImageMenu">
                        <font-awesome-icon
                            :icon="['fal', 'times']"
                            fixed-width
                        ></font-awesome-icon>
                    </b-button>
                </template>
                <b-button
                    size="sm"
                    @click.prevent="
                        editor
                            .chain()
                            .focus()
                            .insertTable({
                                rows: 3,
                                cols: 3,
                                withHeaderRow: true,
                            })
                            .run()
                    "
                    ><font-awesome-icon
                        :icon="['fal', 'table']"
                        fixed-width
                    ></font-awesome-icon
                ></b-button>
                <div v-if="editor.isActive('table')">
                    <b-button
                        title="Delete Table"
                        size="sm"
                        @click.prevent="
                            editor.chain().focus().deleteTable().run()
                        "
                    >
                        <svg
                            class="svg-inline--fa fa-fw"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                        >
                            <path
                                fill="currentColor"
                                d="M12.35 20H10v-3h2.09c.12-.72.37-1.39.72-2H10v-3h4v1.54c.58-.54 1.25-.93 2-1.19V12h4v.35c.75.26 1.42.65 2 1.19V5c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v15c0 1.1.9 2 2 2h9.54c-.54-.58-.93-1.25-1.19-2M16 7h4v3h-4V7m-6 0h4v3h-4V7M8 20H4v-3h4v3m0-5H4v-3h4v3m0-5H4V7h4v3m6.46 5.88l1.42-1.42L18 16.59l2.12-2.13l1.42 1.42L19.41 18l2.13 2.12l-1.42 1.42L18 19.41l-2.12 2.13l-1.42-1.42L16.59 18l-2.13-2.12"
                            />
                        </svg>
                    </b-button>
                    <b-button
                        title="Add row Before"
                        size="sm"
                        @click.prevent="
                            editor.chain().focus().addRowBefore().run()
                        "
                    >
                        <svg
                            class="svg-inline--fa fa-fw"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                        >
                            <path
                                fill="currentColor"
                                d="M22 14a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v7h2v-2h4v2h2v-2h4v2h2v-2h4v2h2v-7M4 14h4v3H4v-3m6 0h4v3h-4v-3m10 0v3h-4v-3h4m-9-4h2V7h3V5h-3V2h-2v3H8v2h3v3Z"
                            />
                        </svg>
                    </b-button>
                    <b-button
                        title="Add row After"
                        size="sm"
                        @click.prevent="
                            editor.chain().focus().addRowAfter().run()
                        "
                    >
                        <svg
                            class="svg-inline--fa fa-fw"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                        >
                            <path
                                fill="currentColor"
                                d="M22 10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V3h2v2h4V3h2v2h4V3h2v2h4V3h2v7M4 10h4V7H4v3m6 0h4V7h-4v3m10 0V7h-4v3h4m-9 4h2v3h3v2h-3v3h-2v-3H8v-2h3v-3Z"
                            />
                        </svg>
                    </b-button>
                    <b-button
                        title="Delete Row"
                        size="sm"
                        @click.prevent="
                            editor.chain().focus().deleteRow().run()
                        "
                    >
                        <svg
                            class="svg-inline--fa fa-fw"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                        >
                            <path
                                fill="currentColor"
                                d="M9.41 13L12 15.59L14.59 13L16 14.41L13.41 17L16 19.59L14.59 21L12 18.41L9.41 21L8 19.59L10.59 17L8 14.41L9.41 13M22 9a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v3M4 9h4V6H4v3m6 0h4V6h-4v3m6 0h4V6h-4v3Z"
                            />
                        </svg>
                    </b-button>
                    <b-button
                        title="Add column before"
                        size="sm"
                        @click.prevent="
                            editor.chain().focus().addColumnBefore().run()
                        "
                    >
                        <svg
                            class="svg-inline--fa fa-fw"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                        >
                            <path
                                fill="currentColor"
                                d="M13 2a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h9V2h-9m7 8v4h-7v-4h7m0 6v4h-7v-4h7m0-12v4h-7V4h7M9 11H6V8H4v3H1v2h3v3h2v-3h3v-2Z"
                            />
                        </svg>
                    </b-button>
                    <b-button
                        title="Add column after"
                        size="sm"
                        @click.prevent="
                            editor.chain().focus().addColumnAfter().run()
                        "
                    >
                        <svg
                            class="svg-inline--fa fa-fw"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                        >
                            <path
                                fill="currentColor"
                                d="M11 2a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H2V2h9m-7 8v4h7v-4H4m0 6v4h7v-4H4M4 4v4h7V4H4m11 7h3V8h2v3h3v2h-3v3h-2v-3h-3v-2Z"
                            />
                        </svg>
                    </b-button>
                    <b-button
                        title="Delete column"
                        size="sm"
                        @click.prevent="
                            editor.chain().focus().deleteColumn().run()
                        "
                    >
                        <svg
                            class="svg-inline--fa fa-fw"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                        >
                            <path
                                fill="currentColor"
                                d="M4 2h7a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2m0 8v4h7v-4H4m0 6v4h7v-4H4M4 4v4h7V4H4m13.59 8L15 9.41L16.41 8L19 10.59L21.59 8L23 9.41L20.41 12L23 14.59L21.59 16L19 13.41L16.41 16L15 14.59L17.59 12Z"
                            />
                        </svg>
                    </b-button>
                    <b-button
                        title="Cell Merges"
                        size="sm"
                        @click.prevent="
                            editor.chain().focus().mergeCells().run()
                        "
                    >
                        <svg
                            class="svg-inline--fa fa-fw"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                        >
                            <path
                                fill="currentColor"
                                d="M5 10H3V4h8v2H5v4m14 8h-6v2h8v-6h-2v4M5 18v-4H3v6h8v-2H5M21 4h-8v2h6v4h2V4M8 13v2l3-3l-3-3v2H3v2h5m8-2V9l-3 3l3 3v-2h5v-2h-5Z"
                            />
                        </svg>
                    </b-button>
                    <b-button
                        title="Split Cell"
                        size="sm"
                        @click.prevent="
                            editor.chain().focus().splitCell().run()
                        "
                    >
                        <svg
                            class="svg-inline--fa fa-fw"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                        >
                            <path
                                fill="currentColor"
                                d="M19 14h2v6H3v-6h2v4h14v-4M3 4v6h2V6h14v4h2V4H3m8 7v2H8v2l-3-3l3-3v2h3m5 0V9l3 3l-3 3v-2h-3v-2h3Z"
                            />
                        </svg>
                    </b-button>
                    <b-button
                        title="Toggle Header Row"
                        size="sm"
                        @click.prevent="
                            editor.chain().focus().toggleHeaderRow().run()
                        "
                    >
                        <svg
                            class="svg-inline--fa fa-fw"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 2048 2048"
                        >
                            <path
                                fill="currentColor"
                                d="M0 128h2048v1664H0V128zm768 1024h512V768H768v384zm512 128H768v384h512v-384zM640 768H128v384h512V768zm768 0v384h512V768h-512zM128 1280v384h512v-384H128zm1280 384h512v-384h-512v384z"
                            />
                        </svg>
                    </b-button>
                    <!-- <b-button
                        size="sm"
                        @click.prevent="
                            editor.chain().focus().toggleHeaderCell().run()
                        "
                    >
                        Toggle Header Cell
                    </b-button> -->
                    <!-- <b-button
                        size="sm"
                        @click.prevent="
                            editor.chain().focus().mergeOrSplit().run()
                        "
                    >
                        Merge or split
                    </b-button> -->
                </div>
            </div>
        </div>
        <EditorContent
            v-bind="{ editor }"
            @click.prevent="focusEditor"
        ></EditorContent>
    </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';
import { Underline } from '@tiptap/extension-underline';
import { Table } from '@tiptap/extension-table';
import { TableCell } from '@tiptap/extension-table-cell';
import { TableHeader } from '@tiptap/extension-table-header';
import { TableRow } from '@tiptap/extension-table-row';
import { Link } from '@tiptap/extension-link';
import Focus from '@tiptap/extension-focus';
import { Image } from '@tiptap/extension-image';
import { Placeholder } from '@tiptap/extension-placeholder';
import { TextAlign } from '@tiptap/extension-text-align';
import { uniq } from 'lodash-es';
export default {
    name: 'Wysiwyg',
    components: {
        EditorContent,
    },
    props: {
        value: String,
        showHeadings: {
            type: Boolean,
            default: false,
        },
        initialContent: String,
        mini: {
            type: Boolean,
            default: false,
        },
        color: { type: String, default: null },
    },
    data() {
        return {
            editor: null,
            inputVal: this.value,
            align: 'left',
            linkUrl: null,
            linkTarget: null,
            linkMenuIsActive: false,
            imageUrl: null,
            imageMenuIsActive: false,
            headingLevel: '3',
            colorpickerOpen: false,
            flip: false,
            focus: false,
            id: this.genUUID(),
        };
    },
    computed: {
        defaultColors() {
            return uniq(Object.values(this.$store.getters['api/colors']));
        },
        focused() {
            // const active = this.editor.isActive();
            return this.focus;
        },
        isEmpty() {
            return (
                this.editor.isEmpty ||
                this.editor.state.doc.textContent?.length === 0
            );
        },
    },
    beforeDestroy() {
        if (this.editor) {
            // Always destroy your editor instance when it's no longer needed
            this.editor.destroy();
        }
    },
    created() {
        const vm = this;
        this.$root.$on('editor:focus', ({ id }) => {
            if (this.id !== id) {
                vm.away();
            }
        });
        vm.editor = new Editor({
            // disablePasteRules: true,
            // disableInputRules: true,
            enablePasteRules: false,
            autofocus: false,
            content: vm.inputVal,
            parseOptions: {
                preserveWhitespace: 'full',
            },
            isTextBlock: vm.mini,
            onUpdate: ({ editor }) => {
                vm.$emit('input', vm.removeSpecialChars(editor.getHTML()));
            },
            onCreate: ({ editor }) => {
                if (!vm.inputVal || vm.inputVal === '') {
                    this.setContent();
                }
                vm.$nextTick(() => {
                    // TODO: only if new
                    // if (vm.isEmpty) {
                    //     setTimeout(() => {
                    //         vm.focusEditor();
                    //     }, 500);
                    // }
                });
            },
            onFocus() {
                vm.focusEditor();
            },
            onBlur(e) {
                if (
                    !e.event.relatedTarget ||
                    e.event.relatedTarget?.classList?.contains('ProseMirror') ||
                    !e.event.relatedTarget?.closest('.action-btns')
                ) {
                    vm.away();
                }
            },
            // tell ProseMirror to ignore drop event
            editorProps: {
                handleDOMEvents: {
                    drop: (view, e) => {
                        e.preventDefault();
                    },
                },
            },
            extensions: [
                TextAlign.configure({
                    types: ['paragraph'],
                }),
                Placeholder.configure({
                    showOnlyCurrent: false,
                    showOnlyWhenEditable: false,
                }),
                Focus.configure({
                    className: 'focused',
                    mode: 'shallowest',
                }),
                Image.configure({ inline: true }),
                Link.configure({
                    openOnClick: false,
                    HTMLAttributes: {
                        target: null,
                        rel: 'noopener noreferrer nofollow',
                        class: null,
                    },
                }),
                Table.configure({
                    resizable: true,
                }),
                TableRow,
                TableHeader,
                TableCell,
                Underline,
                // https://www.tiptap.dev/api/extensions/starter-kit/
                StarterKit.configure({
                    code: false,
                    codeBlock: false,
                    heading: {
                        levels: [1, 2, 3, 4, 5, 6],
                    },
                    dropcursor: { width: 0, color: 'transparent' },
                }),
            ],
        });
    },
    methods: {
        away() {
            this.closeColorPicker();
            this.focus = false;
            if (this.editor && !this.editor.isDestroyed) {
                this.editor.chain().blur().run();
            }
            if (this.isEmpty) {
                return;
            }
            this.$emit('setEditing', false);
        },
        focusEditor() {
            this.$nextTick(() => {
                this.focus = true;
                this.editor.chain().focus().run();
                this.$emit('setEditing', true);
                this.$root.$emit('editor:focus', { id: this.id });
            });
        },
        closeColorPicker() {
            this.colorpickerOpen = false;
        },
        getPickerColor() {
            const customKey = this.$store.getters['api/colors'][this.color];
            return customKey ?? this.color;
        },
        changeColor(color) {
            const rgbaColor = `rgba(${color.rgba.r}, ${color.rgba.g}, ${color.rgba.b}, ${color.rgba.a})`;

            const keys = Object.keys(this.$store.getters['api/colors']);
            const key = keys.find((k) => {
                return [color.hex, rgbaColor].includes(
                    this.$store.getters['api/colors'][k],
                );
            });

            if (key) {
                this.closeColorPicker();
            }

            this.setColor(key || rgbaColor);
        },
        setHeadingLevel(level) {
            this.headingLevel = level;
            this.editor.chain().focus().setHeading({ level }).run();
            this.focusEditor();
        },
        showLinkMenu() {
            this.editor.chain().extendMarkRange('link').run();
            const attrs = this.editor.getAttributes('link');
            this.linkUrl = attrs.href;
            this.linkTarget = !!attrs.target;
            this.linkMenuIsActive = true;
            this.$nextTick(() => {
                this.$refs.linkInput.focus();
            });
        },
        hideLinkMenu() {
            this.linkUrl = null;
            this.linkTarget = null;
            this.linkMenuIsActive = false;
        },
        setLinkUrl(href) {
            const target = this.linkTarget ? '_blank' : null;
            this.editor.chain().focus().setLink({ href, target }).run();
            this.hideLinkMenu();
            this.focusEditor();
        },
        setLinkTarget(event) {
            event.preventDefault();
            this.linkTarget = !this.linkTarget;
        },
        showImageMenu(attrs) {
            this.imageUrl = attrs.src;
            this.imageMenuIsActive = true;
            this.$nextTick(() => {
                this.$refs.imageInput.focus();
            });
        },
        hideImageMenu() {
            this.imageUrl = null;
            this.imageMenuIsActive = false;
        },
        setImageUrl(url) {
            this.editor.chain().focus().setImage({ src: url }).run();
            this.hideImageMenu();
            this.editor.chain().focus().run();
        },
        setContent() {
            setTimeout(() => {
                if (this.editor && !this.editor.isDestroyed) {
                    this.editor.setContent(this.initialContent, true);
                }
            }, 500);
        },
        setAlign(alignment) {
            this.$emit('setAlign', alignment);
            this.align = alignment;
            this.focusEditor();
        },
        setColor(color) {
            this.$nextTick(() => {
                this.$emit('setColor', color);
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.wysiwyg {
    background: rgba(0, 0, 0, 0);
    color: inherit;
    width: 100%;
    max-width: 100%;

    .wysiwyg-btns {
        display: none;
        flex-wrap: nowrap;
        background: rgba(0, 0, 0, 0);
        width: unset;
        justify-content: center;
        top: 18px;
        position: fixed;
        background: #fff;
        padding: 9px;
        border-radius: 4px;
        box-shadow: 0 4px 8px #00000082;
        left: calc(50% + 150px);
        transform: translateX(-50%);
        z-index: 1050;

        &.focused {
            display: block;
            // z-index: 9;

            &.flip {
                margin-top: unset;
                top: 0;
            }
        }

        .action-btns {
            display: flex;
            justify-content: center;
            align-items: stretch;
            width: 100%;
            position: relative;
            // top: -35px;
            // position: absolute;
            z-index: 9999;
            flex-wrap: wrap;

            input {
                min-width: 200px;
                max-width: 300px;
                height: 32px;
            }

            .btn,
            .b-dropdown {
                flex-grow: 0;
                min-width: 35px;
                color: $dark;
                background: #fff;
                z-index: 10;
                position: relative;
                border: none;
                transition: 0.3s ease;
                height: 35px;

                &:not(:first-child) {
                    margin-left: 1px;
                }

                &.btn {
                    flex-basis: 35px;

                    &:hover {
                        filter: brightness(0.8);
                    }

                    &.active {
                        color: #356ae2;
                        border-color: #356ae2;
                    }
                }
            }
        }
    }
}

::v-deep {
    /* Basic editor styles */
    // .ProseMirror {
    //     > * + * {
    //         margin-top: 0.75em;
    //     }
    // }

    // .ProseMirror {
    //     min-height: 50px;
    // }

    /* Placeholder (at the top) */
    .ProseMirror .is-empty:first-child {
        &::before {
            content: attr(data-placeholder);
            float: left;
            color: #ced4da;
            pointer-events: none;
            height: 0;
        }
    }

    .ProseMirror {
        table {
            border-collapse: collapse;
            table-layout: fixed;
            width: 100%;
            margin: 0;
            overflow: hidden;

            td,
            th {
                min-width: 1em;
                border: 2px solid #ced4da;
                padding: 3px 5px;
                vertical-align: top;
                box-sizing: border-box;
                // position: relative;

                > * {
                    margin-bottom: 0;
                }
            }

            th {
                font-weight: bold;
                text-align: left;
                background-color: #e1e3e6;
            }

            .selectedCell:after {
                z-index: 2;
                position: absolute;
                content: '';
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                background: rgba(200, 200, 255, 0.4);
                pointer-events: none;
            }

            .column-resize-handle {
                position: absolute;
                right: -2px;
                top: 0;
                bottom: -2px;
                width: 4px;
                background-color: #adf;
                pointer-events: none;
            }

            p {
                margin: 0;
            }
        }
    }

    .tableWrapper {
        padding: 1rem 0;
        overflow-x: auto;
    }

    .resize-cursor {
        cursor: ew-resize;
        cursor: col-resize;
    }
}
</style>

<style lang="scss">
.card-body {
    .colorpicker-btn {
        display: none;
    }
}

.wysiwyg {
    $w: &;
    .wysiwyg-btns {
        .action-btns {
            .b-dropdown {
                flex-basis: 50px;
                position: relative;
                z-index: 9999;

                ul.dropdown-menu {
                    position: relative;
                    z-index: 1000;

                    li,
                    button {
                        z-index: 1000;
                    }
                }
            }
        }
    }

    input.form-control {
        height: 29px;
        font-size: 0.75em;
        border-radius: 0;
    }

    &.mini {
        div.ProseMirror {
            padding-right: 1.25rem;
        }
    }

    div.ProseMirror {
        $p: &;
        width: 100%;
        border-radius: 4px;
        box-sizing: content-box;
        outline: none;
        position: relative;
        background: rgba(0, 0, 0, 0);

        @at-root #{$w}.is-editor-empty div.ProseMirror,
            &.ProseMirror-focused {
            margin: -1px;
            border: 1px solid #dadcde;
        }

        p {
            color: inherit;
            font-size: inherit;
            line-height: inherit;
        }
    }
}

.tippy-popper {
    .tippy-tooltip {
        padding: 0;

        .tippy-content,
        .tippy-backdrop {
            padding: 0;

            & > div {
                padding: 0;
            }
        }

        .colorClick {
            z-index: 9999;
        }

        div.hu-color-picker {
            background: #fff;
            z-index: 9999;
            position: relative;
            top: 0;

            .color-show {
                display: flex !important;

                canvas {
                    height: 30px;
                    width: 100%;
                }
            }

            .colors:not(.history) {
                display: block;
            }
        }
    }
}
</style>
