<template>
    <b-carousel-slide @click.stop="onClick">
        <template #img>
            <Media
                class="d-block img-fluid w-100"
                :component-data="componentData"
            />
            <slot></slot>
        </template>
    </b-carousel-slide>
</template>

<script>
import Media from './Media';

export default {
    name: 'Slide',
    components: {
        Media,
    },
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
        };
    },
    methods: {
        onClick() {
            this.openEditor(this.componentData.uuid);
        },
    },
};
</script>
