import Client from './Client';
import Model from './Model';
import Project from './Project';

export default class Content extends Model {
    resource() {
        return 'contents';
    }

    // relations() {
    //     return {
    //         project: Project,
    //         client: Client,
    //     };
    // }

    get publishedPDF() {
        return this.published_static_pdf_url ?? this.draft_static_pdf_url;
    }

    get publishedMap() {
        return this.published_map_url ?? this.draft_map_url;
    }

    get publishedMapPrint() {
        return this.print_map_url;
    }
}
