var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"work-activities-container"},[_c('div',{staticClass:"work-activities",class:_vm.classList,attrs:{"id":_vm.elementID}},_vm._l((_vm.filteredWorkActivities),function(wa,i){return _c('div',{key:i,staticClass:"work-activity-container shadow"},[(wa.published_status === 'draft')?_c('div',{staticClass:"draft-banner"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'exclamation-triangle']}}),_vm._v(" "),_c('span',[_vm._v("Note this draft Work Activity will not appear in a\n                    published or emailed News Post. Please publish this Work\n                    Activity to include it in your News Post.")])],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"work-activity"},[_c('div',{staticClass:"map-container",style:({
                        backgroundImage: ("url(" + (wa.published_map_url || wa.draft_map_url) + ")"),
                        border: '5px solid #D3E5F2',
                        boxSizing: 'border-box',
                    })}),_vm._v(" "),_c('div',{staticClass:"content"},[_c('div',{staticClass:"info"},[_c('h5',[_vm._v(_vm._s(wa.name))]),_vm._v(" "),_c('p',[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":['fal', 'map-marker']}}),_vm._v("\n                            "+_vm._s(wa.metadata.props.location)+"\n                        ")],1),_vm._v(" "),_c('p',[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":['fal', 'clock']}}),_vm._v("\n                            "+_vm._s(_vm.$moment(
                                    new Date(wa.metadata.props.date_start)
                                ).format(_vm.$config.WORK_ACTIVITY_DATE_FORMAT))+"\n                            "),(
                                    !wa.metadata.props.date_end ||
                                    wa.metadata.props.date_end !==
                                        wa.metadata.props.date_start
                                )?[_vm._v("\n                                -\n                                "+_vm._s(wa.metadata.props.date_end
                                        ? _vm.$moment(
                                              new Date(
                                                  wa.metadata.props.date_end
                                              )
                                          ).format(
                                              _vm.$config.WORK_ACTIVITY_DATE_FORMAT
                                          )
                                        : 'ongoing')+"\n                            ")]:_vm._e()],2),_vm._v(" "),(wa.metadata.props.description)?_c('p',[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":['fal', 'info']}}),_vm._v(" "),_c('em',[_vm._v("\n                                "+_vm._s(wa.metadata.props.description)+"\n                            ")])],1):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"buttons"},[_c('a',{staticClass:"btn btn-sm btn-block btn-primary",attrs:{"href":wa.metadata.props.preview_url,"target":"_blank"}},[_c('span',[_vm._v("View more")])])])])])])}),0),_vm._v(" "),_c('Adder',{attrs:{"component-data":_vm.componentData.parentData,"child-u-u-i-d":_vm.componentData.uuid}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }