<template>
    <table :style="style" :class="classList" border="0">
        <tbody>
            <tr>
                <td
                    :style="{
                        height: `${attrs.height || 1}px `,
                        width: attrs.width + '%',
                        margin: 0,
                        'mso-line-height-rule': 'exactly',
                        'line-height': `${attrs.height || 1}px `,
                        'font-size': `${attrs.height || 1}px `,
                    }"
                >
                    &nbsp;
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    name: 'Spacer',
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData?.props ?? {
                padding: 4,
                width: 100,
                height: 2,
            },
        };
    },
    computed: {
        style() {
            return {
                padding: this.attrs.padding
                    ? this.attrs.padding + 'px'
                    : undefined,
                width: '100%',
            };
        },
        classList() {
            if (!this.attrs) return [];
            return [this.attrs.element_class || ''];
        },
    },
};
</script>
