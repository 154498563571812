<template>
    <div class="gallery-container">
        <DragHandle />

        <draggable
            v-if="isPreview && !previewMode"
            v-model="
                // eslint-disable-next-line vue/no-mutating-props
                componentData.children
            "
            class="draggable gallery"
            group="gallery"
            :class="classList"
            @change="refreshPreviewOrder(componentData.uuid)"
        >
            <slot></slot>
        </draggable>

        <div v-else :id="elementID" class="gallery" :class="classList">
            <slot />
        </div>

        <Adder
            :component-data="componentData.parentData"
            :child-u-u-i-d="componentData.uuid"
        />
    </div>
</template>

<script>
import helpers from '../mixins/helpers';
import Adder from './editor/Adder';
import DragHandle from './editor/DragHandle';
export default {
    name: 'Gallery',
    components: {
        Adder,
        DragHandle,
    },
    mixins: [helpers],
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
        };
    },
    computed: {
        classList() {
            return [
                `cards-${this.attrs.card_size || 'md'}`,
                'justify-content-' + this.attrs.justify_content,
                ...this.helperClasses,
                this.attrs.element_class,
            ];
        },
        elementID() {
            return this.attrs.element_id || '';
        },
    },
};
</script>

<style lang="scss">
.gallery-container {
    display: flex;
    flex-direction: column;
    position: relative;

    &:hover {
        & > .drag-handle,
        .adder {
            opacity: 1;
        }
    }
}

.gallery {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    position: relative;
    width: calc(100% + 0.5rem);

    &:hover {
        .drag-handle,
        .adder {
            opacity: 1;
        }
    }

    & > * {
        flex-grow: 0;
        margin: 0.125rem 0.5rem 0 0;
    }

    &.justify-content-stretch {
        & > * {
            flex-grow: 1;
        }

        .card {
            flex-grow: 1;
            flex-basis: 100%;
            width: 100%;
        }
    }

    &.cards-sm > * {
        min-width: 150px;
        flex-basis: calc(20% - 1rem);
    }

    &.cards-md > * {
        min-width: 200px;
        flex-basis: calc(25% - 1rem);
    }

    &.cards-lg > * {
        min-width: 300px;
        flex-basis: calc(33% - 1rem);
    }
}
</style>
