<template>
    <table cellpadding="0" cellspacing="0" width="100%" border="0">
        <tbody>
            <tr
                :style="{
                    marginBottom: '16px',
                }"
            >
                <td
                    :style="{
                        width: '50%',
                        backgroundColor: '#f3f6f8',
                    }"
                >
                    <div
                        :style="{
                            margin: '12px',
                            textAlign: 'center',
                        }"
                    >
                        <span
                            :style="{ fontFamily: projectConfig.fontFamily }"
                            v-html="attrs.before_img.label"
                        />
                    </div>

                    <div
                        :style="{
                            width: '100%',
                            paddingTop: '56.25%',
                            backgroundPosition: 'center center',
                            backgroundSize: 'cover',
                            backgroundImage: `url(${attrs.before_img.url})`,
                        }"
                    />
                </td>

                <td
                    :style="{
                        width: '50%',
                        backgroundColor: '#f3f6f8',
                    }"
                >
                    <div
                        :style="{
                            margin: '12px',
                            textAlign: 'center',
                        }"
                    >
                        <span
                            :style="{ fontFamily: projectConfig.fontFamily }"
                            v-html="attrs.after_img.label"
                        />
                    </div>

                    <div
                        :style="{
                            width: '100%',
                            paddingTop: '56.25%',
                            backgroundPosition: 'center center',
                            backgroundSize: 'cover',
                            backgroundImage: `url(${attrs.after_img.url})`,
                        }"
                    />
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    name: 'CompareSlider',
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props,
        };
    },
};
</script>
