<template>
    <div v-if="attrs.layout === 'carousel'" class="carousel-item">
        <div class="ca-contents-content ca-card card" style="width: 100%">
            <div
                class="thumbnail-container ca-thumbnail ca-contents-content-thumbnail"
            >
                <b-img-lazy
                    :src="
                        np.metadata.props.thumbnail
                            ? np.metadata.props.thumbnail.url
                            : 'https://ca-v2.s3-ap-southeast-2.amazonaws.com/placeholder.jpg'
                    "
                    alt=""
                    class="img-fluid"
                    width="640"
                    height="360"
                    loading="lazy"
                />
            </div>
            <div class="ca-contents-content-cardbody ca-cardbody card-body">
                <div
                    v-if="np.published_status === 'draft'"
                    class="draft-banner"
                >
                    <font-awesome-icon
                        :icon="['fas', 'exclamation-triangle']"
                    />
                    <span
                        >Note this draft News Post will not appear in published
                        content. Please publish this News Post to include it in
                        your content.</span
                    >
                </div>
                <h5
                    :style="{
                        fontFamily: projectConfig.fontFamily,
                    }"
                    class="ca-heading ca-contents-content-title"
                >
                    {{ np.metadata.props.title }}
                </h5>

                <p
                    v-if="attrs.show_date && np.custom_date && np.published_at"
                    :style="{
                        fontFamily: projectConfig.fontFamily,
                    }"
                    class="ca-contents-content-date"
                >
                    {{
                        $moment(
                            new Date(np.custom_date) ||
                                new Date(np.published_at),
                        ).format('D MMMM, YYYY')
                    }}
                </p>
                <p
                    v-if="description"
                    :style="{
                        fontFamily: projectConfig.fontFamily,
                    }"
                    class="description ca-contents-content-excerpt text-truncate-description"
                    style="
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                    "
                >
                    <span v-html="description"></span>
                </p>
                <div
                    v-if="description"
                    class="ca-contents-content-excerpt ca-contents-content-excerpt-read-more"
                >
                    <span class="ca-excerpt-read-more"
                        >Read more <i class="fa fa-long-arrow-right"></i
                    ></span>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="news-post ca-contents-content ca-card card">
        <a class="ca-content-content-link" style="width: 100%">
            <div
                class="thumbnail-container ca-thumbnail ca-contents-content-thumbnail"
            >
                <b-img-lazy
                    :src="thumbnail"
                    alt=""
                    class="img-fluid"
                    width="640"
                    height="360"
                    loading="lazy"
                />
            </div>

            <div
                class="content ca-contents-content-cardbody ca-cardbody card-body"
            >
                <div
                    v-if="np.published_status === 'draft'"
                    class="draft-banner"
                >
                    <font-awesome-icon
                        :icon="['fas', 'exclamation-triangle']"
                    />
                    <span
                        >Note this draft News Post will not appear in published
                        content. Please publish this News Post to include it in
                        your content.</span
                    >
                </div>
                <h5
                    :style="{
                        fontFamily: projectConfig.fontFamily,
                    }"
                    class="ca-heading ca-contents-content-title"
                >
                    {{ np.metadata.props.title }}
                </h5>

                <p
                    v-if="attrs.show_date && np.custom_date && np.published_at"
                    :style="{
                        fontFamily: projectConfig.fontFamily,
                    }"
                    class="ca-contents-content-date"
                >
                    {{
                        $moment(
                            new Date(np.custom_date) ||
                                new Date(np.published_at),
                        ).format('D MMMM, YYYY')
                    }}
                </p>
                <p
                    v-if="description"
                    :style="{
                        fontFamily: projectConfig.fontFamily,
                    }"
                    class="description ca-contents-content-excerpt text-truncate-description"
                    style="
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                    "
                >
                    <span v-html="description"></span>
                </p>
                <div
                    class="ca-contents-content-excerpt ca-contents-content-excerpt-read-more"
                >
                    <span class="ca-excerpt-read-more"
                        >Read more <i class="fa fa-long-arrow-right"></i
                    ></span>
                </div>
            </div>
        </a>
    </div>
</template>

<script>
import getYoutubeIdFromUrl from '~/utils/getYoutubeIdFromUrl';
export default {
    props: {
        np: { type: Object },
        attrs: { type: Object },
    },
    computed: {
        description() {
            if (this.attrs.show_excerpt) {
                if (this.np.description) {
                    return this.np.description;
                }
                if (this.np.search_text) {
                    return this.np.search_text;
                }
            }
            return undefined;
        },
        thumbnail() {
            if (this.np.metadata.props.thumbnail?.ref) {
                return this.mediaUrl(this.np.metadata.props.thumbnail.ref);
            }
            for (const child of this.np.metadata.children) {
                if (child.name === 'Youtube' && child.props.src) {
                    const youtubeId = getYoutubeIdFromUrl(child.props.src);
                    if (youtubeId) {
                        return `http://i3.ytimg.com/vi/${youtubeId}/hqdefault.jpg`;
                    }
                } else if (child.name === 'Media' && child.props.media?.ref) {
                    return this.mediaUrl(child.props.media.ref);
                }
            }

            return 'https://ca-v2.s3-ap-southeast-2.amazonaws.com/media-placeholder-thumb.jpg';
        },
    },
    methods: {
        mediaUrl(ref) {
            const CA_MEDIA_URL = `https://${this.$config.API_MEDIA_SERVICE_DOMAIN}/`;
            return `${CA_MEDIA_URL}${ref}?p=thumb&fm=jpg&q=60${
                this.attrs.layout === 'carousel' ? '&w=1920&h=1080' : ''
            }`;
        },
    },
};
</script>

<style lang="scss" scoped>
.draft-banner {
    background: #dae6ec;
    padding: 1.5em;
    font-size: 14px;
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
    display: flex;
    flex-direction: row;
    align-items: center;

    .fa-exclamation-triangle {
        margin-right: 1em;
    }
}
</style>
