<template>
    <table border="0" width="100%" cellpadding="0" cellspacing="0">
        <tbody>
            <tr>
                <td>
                    <table
                        cellpadding="0"
                        cellspacing="0"
                        :align="algin"
                        :style="{
                            'Margin-left': 'auto',
                            'Margin-right': 'auto',
                            'border-spacing': '0 !important',
                            'mso-table-lspace': '0pt',
                            'mso-table-rspace': '0pt',
                            'border-collapse': 'separate!important',
                            width:
                                attrs.justify_content === 'stretch'
                                    ? '100%'
                                    : undefined,
                        }"
                    >
                        <tbody>
                            <template
                                v-if="
                                    $slots &&
                                    $slots.default &&
                                    $slots.default[0]
                                "
                            >
                                <template v-if="attrs.align === 'stacked'">
                                    <SlotRenderWrap
                                        v-for="(child, index) of $slots.default"
                                        :key="index"
                                        tag="tr"
                                        :value="child"
                                    ></SlotRenderWrap>
                                </template>
                                <template v-else>
                                    <component
                                        v-bind="
                                            child.componentOptions.propsData
                                        "
                                        :is="
                                            child.componentOptions.propsData
                                                .componentData &&
                                            child.componentOptions.propsData
                                                .componentData.props &&
                                            child.componentOptions.propsData
                                                .componentData.props.block
                                                ? 'SlotRenderWrap'
                                                : child.componentOptions.tag
                                        "
                                        v-for="(child, index) of $slots.default"
                                        :key="index"
                                        :value="child"
                                        tag="tr"
                                    >
                                    </component>
                                </template>
                            </template>
                            <tr v-else>
                                <slot></slot>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
/* TODO: Consider max number of buttons per line or some other
 flex wrap like solution for preventing too many buttons on one line.
*/
import SlotRenderWrap from '~/components/SlotRenderWrap.vue';
export default {
    name: 'ButtonLinks',
    components: {
        SlotRenderWrap,
    },
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
        };
    },
    computed: {
        algin() {
            const justifyMap = {
                start: 'left',
                end: 'right',
            };
            return (
                justifyMap[this.attrs.justify_content] ??
                this.attrs.justify_content
            );
        },
    },
    mounted() {
        console.log('button slot', this.$slots.default);
    },
};
</script>
